import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { headers } from "src/app/headerUtils";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AutocompleteService {
  constructor(private http: HttpClient) {}

  placeautocomplete(place) {
    return this.http.get<any>(
      `${environment.referentiel}search/placeautocomplete/${place}`,
      headers.jwt()
    );
  }

  termautocomplete(term) {
    return this.http.get<any>(
      `${environment.referentiel}search/autocomplete/${term}`,
      headers.jwt()
    );
  }
}
