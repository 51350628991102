<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

      <div class="collapse navbar-collapse" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" routerLink="/"
                id="topnav-components" role="button" (click)="setActive('accueil')">
                <span [ngClass]="{'accent': onAccueil}">{{'MENUITEMS.PUBLIC.HOME' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" routerLink="/professionnels"
                id="topnav-components" role="button" (click)="setActive('professionnels')">
                <span [ngClass]="{'accent': onProfessionals}">{{'MENUITEMS.PUBLIC.PROFESSIONALS' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" routerLink="/structures"
                id="topnav-components" role="button" (click)="setActive('structures')">
                <span [ngClass]="{'accent': onStructures}">{{'MENUITEMS.PUBLIC.FACILITIES' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" routerLink="/pharmacies"
                id="topnav-components" role="button" (click)="setActive('pharmacies')">
                <span [ngClass]="{'accent': onPharmacies}">{{'MENUITEMS.PUBLIC.PHARMACIES' | translate}}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>