import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-doctors',
  templateUrl: './search-doctors.component.html',
  styleUrls: ['./search-doctors.component.scss']
})
export class SearchDoctorsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  doctors = {
    current_page: 1,
    data: [
      {
        id: 15,
        latitude: null,
        longitude: null,
        coordonate_id: 17,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T04:58:59.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: null,
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "15",
        eyoneexternalid: "15",
        firstname: "AMADOU GALLO",
        lastname: "DIOP",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Fatou Mbathie",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Neurologue",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-25",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 0.9527517403083395,
        doctorspecializations: [
          {
            doctor_specialization_id: 25,
            name: "Rumatologue",
            pivot: {
              doctor_id: 15,
              doctor_specialization_id: 25,
            },
          },
        ],
        coordonate: {
          id: 17,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: null,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-25T04:58:59.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338240404",
          globaladressfield:
            "FANN RESIDENCE 33 RUE Angle SN, Rue FN 20, Dakar 12500",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.692554918194459,
          longitude: -17.47246885908264,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [
          {
            service_activable_id: 1,
            name: "PRISE DE RENDEZ-VOUS",
            pivot: {
              doctor_id: 15,
              service_activable_id: 1,
            },
          },
          {
            service_activable_id: 2,
            name: "DEVIS EN LIGNE",
            pivot: {
              doctor_id: 15,
              service_activable_id: 2,
            },
          },
          {
            service_activable_id: 3,
            name: "TELETRANSMISSION",
            pivot: {
              doctor_id: 15,
              service_activable_id: 3,
            },
          },
        ],
        languages: [
          {
            language_id: 1,
            label: "Wolof",
            pivot: {
              doctor_id: 15,
              language_id: 1,
            },
          },
          {
            language_id: 8,
            label: "Espagnol",
            pivot: {
              doctor_id: 15,
              language_id: 8,
            },
          },
          {
            language_id: 2,
            label: "Français",
            pivot: {
              doctor_id: 15,
              language_id: 2,
            },
          },
        ],
        insurers: [
          {
            insurer_id: 2,
            name: "AMSA",
            pivot: {
              doctor_id: 15,
              insurer_id: 2,
            },
          },
          {
            insurer_id: 1,
            name: "AXA",
            pivot: {
              doctor_id: 15,
              insurer_id: 1,
            },
          },
          {
            insurer_id: 3,
            name: "IPM SONATEL",
            pivot: {
              doctor_id: 15,
              insurer_id: 3,
            },
          },
        ],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [
          {
            id: 12,
            coordonate_id: 1,
            apecode: null,
            category: "LABO",
            commercialregister: null,
            mustpayaddedvaluetax: null,
            name: "INSTITUT   PASTEUR ",
            ninea: null,
            siren: null,
            tvaintracommunautaire: null,
            latitude: 14.710989108507581,
            longitude: -17.480870128933162,
            createdby: "",
            creationdate: "2022-06-16T16:36:34.000000Z",
            disable: null,
            appointmentbooking: 1,
            description: null,
            documentgenerationrequired: null,
            enabled: null,
            eyoneinternalid: null,
            eyoneexternalid: null,
            following: null,
            globalinfo: null,
            healthprosector: null,
            openhours: "Lundi au Dimanche | 24H/24",
            prescription: null,
            publicprofil: "Etablissement d'enseignement et de recherche",
            qualifiedby: "FATOU MABTHIE",
            qualifieddata: null,
            qualifieddate: "2022-06-15",
            quote: null,
            receivedlegalinfos: null,
            sharepatientmedicalfile: null,
            teleconsultation: null,
            updatedby: null,
            updateddate: null,
            version: null,
            makepublicvisible: 1,
            eyoneuser: null,
            type_id: 3,
            pivot: {
              doctor_id: 15,
              medical_structure_id: 12,
            },
            coordonate: {
              id: 1,
              quartier_id: 1,
              town_id: 2,
              region_id: 1,
              country_id: 1,
              accessinformation: "dakar",
              createdby: "Fatou Mbathie",
              creationdate: "2021-12-19T01:00:36.000000Z",
              disable: 1,
              district: null,
              documentgenerationrequired: 0,
              email: null,
              eyoneexternalid: "string",
              eyoeninternalid: "string",
              faxnumber: null,
              fixnumber: "339879878",
              globaladressfield: "36 Av. Pasteur, Dakar",
              globalinfo: 0,
              internetsite: null,
              latitude: 14.656399423596058,
              longitude: -17.4351143186033,
              mobilenumber: null,
              othernumber: "255543322",
              postalbox: null,
              postalcode: null,
              streetname: null,
              streetnumber: null,
              updatedby: null,
              updateddate: "2022-04-16T07:34:13.000000Z",
              version: 0,
            },
          },
        ],
        medicalacts: [],
        payment_types: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "FREE MONEY",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 15,
              payment_type_id: 1,
            },
          },
          {
            id: 2,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "ESPECES",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 15,
              payment_type_id: 2,
            },
          },
          {
            id: 4,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "VISA / MASTER CARD",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 15,
              payment_type_id: 4,
            },
          },
        ],
        appointmentreasons: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:34:56.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            label: "Urgence",
            updatedby: null,
            updateddate: "2021-12-14T16:34:56.000000Z",
            version: null,
            pivot: {
              doctor_id: 15,
              appointment_reason_id: 1,
            },
          },
          {
            id: 2,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:34:56.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            label: "Consultation",
            updatedby: null,
            updateddate: "2021-12-14T16:34:56.000000Z",
            version: null,
            pivot: {
              doctor_id: 15,
              appointment_reason_id: 2,
            },
          },
        ],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 7,
        latitude: null,
        longitude: null,
        coordonate_id: 6,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-21T01:54:49.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: "2021-12-06",
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "7",
        eyoneexternalid: "7",
        firstname: "SEINI A",
        lastname: "BAGNOU",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Marieme Diouf",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: null,
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-21",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 1.4354762030727641,
        doctorspecializations: [
          {
            doctor_specialization_id: 20,
            name: "Gasterologue",
            pivot: {
              doctor_id: 7,
              doctor_specialization_id: 20,
            },
          },
        ],
        coordonate: {
          id: 6,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: "mamelles",
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-19T01:11:23.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338651100",
          globaladressfield: "Cité Keur Gorgui, 52, Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.71272917352248,
          longitude: -17.468209087357494,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 10,
        latitude: null,
        longitude: null,
        coordonate_id: 12,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T04:18:19.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: null,
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "10",
        eyoneexternalid: "10",
        firstname: "MAGUETTE NDIAYE ",
        lastname: "BA",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "fatou dsiop",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Gynécologue",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-25",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 4.792581213898166,
        doctorspecializations: [
          {
            doctor_specialization_id: 24,
            name: "Urologue",
            pivot: {
              doctor_id: 10,
              doctor_specialization_id: 24,
            },
          },
        ],
        coordonate: {
          id: 12,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: null,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-25T04:18:19.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338423475",
          globaladressfield: "76 Rue Carnot, Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.667948331809427,
          longitude: -17.43792303209636,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 16,
        latitude: null,
        longitude: null,
        coordonate_id: 9,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T05:41:11.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: null,
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "16",
        eyoneexternalid: "16",
        firstname: "SOUVASIN",
        lastname: "DIOUF",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: null,
        name: "Fatou Mbathie",
        openhours: "stringLundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: null,
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-25",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 3,
        title_id: 1,
        distance: 4.804788213024676,
        doctorspecializations: [
          {
            doctor_specialization_id: 27,
            name: "Dermatologue",
            pivot: {
              doctor_id: 16,
              doctor_specialization_id: 27,
            },
          },
          {
            doctor_specialization_id: 27,
            name: "Dermatologue",
            pivot: {
              doctor_id: 16,
              doctor_specialization_id: 27,
            },
          },
        ],
        coordonate: {
          id: 9,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: null,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-21T01:54:49.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338895151",
          globaladressfield: "13 bis Rue St Michel, Dakar",
          globalinfo: 0,
          internetsite: "http://www.labobio24.com/",
          latitude: 14.672342548568968,
          longitude: -17.433428002700254,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 3,
          name: "SAGE-FEMME",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 11,
        latitude: null,
        longitude: null,
        coordonate_id: 13,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T04:23:14.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: null,
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "11",
        eyoneexternalid: "11",
        firstname: "IBRAHIMA",
        lastname: "NDIAYE",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Fatou Mbathie",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Dermatologue ",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-25",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 4.823327789141696,
        doctorspecializations: [
          {
            doctor_specialization_id: 19,
            name: "Ethiopate/Kine",
            pivot: {
              doctor_id: 11,
              doctor_specialization_id: 19,
            },
          },
          {
            doctor_specialization_id: 20,
            name: "Gasterologue",
            pivot: {
              doctor_id: 11,
              doctor_specialization_id: 20,
            },
          },
        ],
        coordonate: {
          id: 13,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: null,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-25T04:23:14.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338428800",
          globaladressfield: "3 Rue Escarfait, Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.67130354443112,
          longitude: -17.434115605109852,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 20,
        latitude: null,
        longitude: null,
        coordonate_id: 24,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-29T20:11:57.000000Z",
        disable: 0,
        appointmentbooking: null,
        birthday: "1994-09-08",
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "20",
        eyoneexternalid: "20",
        firstname: "CAROLE MANGARA",
        lastname: "DERNEVILLE",
        following: null,
        globalinfo: 0,
        healthprosector: 1,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Mariaa diop",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Ophtalmologue",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-29",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 3,
        title_id: 2,
        distance: 4.901877215629866,
        doctorspecializations: [],
        coordonate: {
          id: 24,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: null,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-29T20:11:57.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338220602",
          globaladressfield: "5, 7 Av. Carde, Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.665738523361224,
          longitude: -17.439021322704296,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 3,
          name: "SAGE-FEMME",
        },
        photos: [],
        services_dto: [],
        languages: [
          {
            language_id: 2,
            label: "Français",
            pivot: {
              doctor_id: 20,
              language_id: 2,
            },
          },
          {
            language_id: 1,
            label: "Wolof",
            pivot: {
              doctor_id: 20,
              language_id: 1,
            },
          },
        ],
        insurers: [],
        title: {
          id: 2,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "PR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [
          {
            id: 2,
            category: null,
            code: null,
            description: null,
            name: "CHIRURGIE",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 1,
            pivot: {
              doctor_id: 20,
              medical_act_id: 2,
            },
          },
        ],
        payment_types: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "FREE MONEY",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 20,
              payment_type_id: 1,
            },
          },
        ],
        appointmentreasons: [
          {
            id: 2,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:34:56.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            label: "Consultation",
            updatedby: null,
            updateddate: "2021-12-14T16:34:56.000000Z",
            version: null,
            pivot: {
              doctor_id: 20,
              appointment_reason_id: 2,
            },
          },
        ],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 12,
        latitude: null,
        longitude: null,
        coordonate_id: 14,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T04:28:58.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: null,
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "12",
        eyoneexternalid: "12",
        firstname: "KARIM ",
        lastname: "NDIAYE",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Fatou Mbathie",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Dermatologue ",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-25",
        quote: 1,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: 1,
        teleconsultation: null,
        updatedby: null,
        updateddate: "2022-04-16T00:00:00.000000Z",
        version: 0,
        type_id: 3,
        title_id: 1,
        distance: 4.964533625994794,
        doctorspecializations: [
          {
            doctor_specialization_id: 20,
            name: "Gasterologue",
            pivot: {
              doctor_id: 12,
              doctor_specialization_id: 20,
            },
          },
        ],
        coordonate: {
          id: 14,
          quartier_id: 1,
          town_id: 2,
          region_id: 1,
          country_id: 1,
          accessinformation: "ddddddddddd",
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-25T04:28:58.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: "mbathie@gmail.com",
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338216550",
          globaladressfield: "178 Avenue Place 71, Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.666505733315113,
          longitude: -17.43719390510992,
          mobilenumber: "5444",
          othernumber: "3444445",
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: "2022-04-16T07:38:02.000000Z",
          version: 0,
        },
        type: {
          id: 3,
          name: "SAGE-FEMME",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 8,
        latitude: null,
        longitude: null,
        coordonate_id: 7,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-25T04:04:26.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: "2021-12-06",
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "8",
        eyoneexternalid: "8",
        firstname: "NGAGNE",
        lastname: "MBAYE",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: null,
        name: "Fatou Mbathie",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: null,
        qualifiedby: "Fatou Mbathie",
        qualifieddata: null,
        qualifieddate: "2021-12-25",
        quote: null,
        receivedlegalinfos: null,
        sexe: 0,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 8.908999312421637,
        doctorspecializations: [
          {
            doctor_specialization_id: 24,
            name: "Urologue",
            pivot: {
              doctor_id: 8,
              doctor_specialization_id: 24,
            },
          },
        ],
        coordonate: {
          id: 7,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: "dakar",
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-21T01:15:59.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: "cap@gmail.com",
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: "338542121",
          globaladressfield: "Pikine",
          globalinfo: 0,
          internetsite: null,
          latitude: 14.753342917552045,
          longitude: -17.40628194743771,
          mobilenumber: null,
          othernumber: "33332039",
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [
          {
            service_activable_id: 1,
            name: "PRISE DE RENDEZ-VOUS",
            pivot: {
              doctor_id: 8,
              service_activable_id: 1,
            },
          },
        ],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [],
        payment_types: [],
        appointmentreasons: [],
        datetimesslot: [
          {
            id: 13,
            createdby: "",
            creationdate: "2022-04-27 07:53:44",
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: null,
            timeslotdate: "2022-06-24",
            doctor_id: 8,
            status: "FREE",
            bookedDate: null,
            medicalStructureName: "",
            medicalStructureExternalId: "",
            timesslot: [
              {
                id: 14,
                createdby: "",
                creationdate: "2022-04-27 07:56:04",
                disable: null,
                documentgenerationrequired: null,
                enabled: null,
                eyoneinternalid: null,
                eyoneexternalid: null,
                endtime: "09:30:00",
                starttime: "10:00:00",
                status: null,
                date_slot_id: 13,
                medicalstructureexternalid: null,
                medicalstructurename: null,
                bookeddate: null,
              },
            ],
          },
        ],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [
              {
                id: 14,
                createdby: "",
                creationdate: "2022-04-27 07:56:04",
                disable: null,
                documentgenerationrequired: null,
                enabled: null,
                eyoneinternalid: null,
                eyoneexternalid: null,
                endtime: "09:30:00",
                starttime: "10:00:00",
                status: null,
                date_slot_id: 13,
                medicalstructureexternalid: null,
                medicalstructurename: null,
                bookeddate: null,
              },
            ],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 3,
        latitude: null,
        longitude: null,
        coordonate_id: 3,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-14T16:48:11.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: "1998-05-08",
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "3",
        eyoneexternalid: "3",
        firstname: "MBAYE  ",
        lastname: "NDOYE  ",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: null,
        name: "Fatou Mbathie",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: "Médecine Générale",
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-14",
        quote: 1,
        receivedlegalinfos: null,
        sexe: 1,
        sharepatientmedicalfile: 1,
        teleconsultation: 1,
        updatedby: null,
        updateddate: "2022-04-16T00:00:00.000000Z",
        version: 0,
        type_id: 2,
        title_id: 2,
        distance: 2524.8241225274396,
        doctorspecializations: [],
        coordonate: {
          id: 3,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: "MAMELLES",
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-19T01:04:33.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: null,
          globaladressfield: "Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 0,
          longitude: 0,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [
          {
            service_activable_id: 1,
            name: "PRISE DE RENDEZ-VOUS",
            pivot: {
              doctor_id: 3,
              service_activable_id: 1,
            },
          },
        ],
        languages: [
          {
            language_id: 2,
            label: "Français",
            pivot: {
              doctor_id: 3,
              language_id: 2,
            },
          },
        ],
        insurers: [],
        title: {
          id: 2,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "PR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [
          {
            id: 2,
            category: null,
            code: null,
            description: null,
            name: "CHIRURGIE",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 1,
            pivot: {
              doctor_id: 3,
              medical_act_id: 2,
            },
          },
          {
            id: 3,
            category: "ACTE",
            code: null,
            description: null,
            name: "ECG",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 3,
            pivot: {
              doctor_id: 3,
              medical_act_id: 3,
            },
          },
          {
            id: 2,
            category: null,
            code: null,
            description: null,
            name: "CHIRURGIE",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 1,
            pivot: {
              doctor_id: 3,
              medical_act_id: 2,
            },
          },
          {
            id: 2,
            category: null,
            code: null,
            description: null,
            name: "CHIRURGIE",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 1,
            pivot: {
              doctor_id: 3,
              medical_act_id: 2,
            },
          },
        ],
        payment_types: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "FREE MONEY",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 3,
              payment_type_id: 1,
            },
          },
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "FREE MONEY",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 3,
              payment_type_id: 1,
            },
          },
        ],
        appointmentreasons: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:34:56.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            label: "Urgence",
            updatedby: null,
            updateddate: "2021-12-14T16:34:56.000000Z",
            version: null,
            pivot: {
              doctor_id: 3,
              appointment_reason_id: 1,
            },
          },
        ],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
      {
        id: 4,
        latitude: null,
        longitude: null,
        coordonate_id: 3,
        createdby: "Fatou Mbathie",
        creationdate: "2021-12-14T17:16:21.000000Z",
        disable: 0,
        appointmentbooking: 1,
        birthday: "1977-12-31",
        comment: null,
        description: null,
        documentgenerationrequired: 0,
        enabled: 1,
        eyoneinternalid: "4",
        eyoneexternalid: "4",
        firstname: "EL HADJI MADIOR",
        lastname: "DIAGNE",
        following: null,
        globalinfo: 0,
        healthprosector: 0,
        identitydocumentnumber: "string",
        identitydocumenttype: 0,
        makepublicvisible: 1,
        name: "Malick Diop",
        openhours: "Lundi au vendredi 08h-17h",
        placeofbirth: null,
        prescription: null,
        publicprofil: null,
        qualifiedby: "Fatou Mbathie",
        qualifieddata: 1,
        qualifieddate: "2021-12-14",
        quote: null,
        receivedlegalinfos: null,
        sexe: 1,
        sharepatientmedicalfile: null,
        teleconsultation: null,
        updatedby: null,
        updateddate: null,
        version: 0,
        type_id: 2,
        title_id: 1,
        distance: 2524.8241225274396,
        doctorspecializations: [],
        coordonate: {
          id: 3,
          quartier_id: 1,
          town_id: 0,
          region_id: 0,
          country_id: 0,
          accessinformation: "MAMELLES",
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-19T01:04:33.000000Z",
          disable: 1,
          district: null,
          documentgenerationrequired: 0,
          email: null,
          eyoneexternalid: "string",
          eyoeninternalid: "string",
          faxnumber: null,
          fixnumber: null,
          globaladressfield: "Dakar",
          globalinfo: 0,
          internetsite: null,
          latitude: 0,
          longitude: 0,
          mobilenumber: null,
          othernumber: null,
          postalbox: null,
          postalcode: null,
          streetname: null,
          streetnumber: null,
          updatedby: null,
          updateddate: null,
          version: 0,
        },
        type: {
          id: 2,
          name: "INFIRMIER",
        },
        photos: [],
        services_dto: [],
        languages: [],
        insurers: [],
        title: {
          id: 1,
          createdby: "Fatou Mbathie",
          creationdate: "2021-12-14T18:12:46.000000Z",
          description: null,
          disable: null,
          eyoneexternalid: null,
          eyoneinternalid: null,
          globalinfo: null,
          name: "DR",
          updatedby: null,
          updateddate: "2021-12-14T18:12:46.000000Z",
        },
        structures: [],
        medicalacts: [
          {
            id: 1,
            category: "ACTE",
            code: null,
            description: null,
            name: "ECG",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 2,
            pivot: {
              doctor_id: 4,
              medical_act_id: 1,
            },
          },
          {
            id: 2,
            category: null,
            code: null,
            description: null,
            name: "CHIRURGIE",
            treatment: null,
            linkbillingtohospitalisation: 0,
            acttype: "ACTE",
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:36:37.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            updatedby: null,
            updateddate: "2021-12-14T16:36:37.000000Z",
            version: null,
            type_id: 1,
            pivot: {
              doctor_id: 4,
              medical_act_id: 2,
            },
          },
        ],
        payment_types: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "FREE MONEY",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 4,
              payment_type_id: 1,
            },
          },
          {
            id: 2,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:35:36.000000Z",
            description: null,
            disable: null,
            documentgenerationrequired: null,
            eyoneinternalid: null,
            eyoneexternalid: "",
            globalinfo: null,
            name: "ESPECES",
            updatedby: null,
            updateddate: "2021-12-14T16:35:36.000000Z",
            version: null,
            pivot: {
              doctor_id: 4,
              payment_type_id: 2,
            },
          },
        ],
        appointmentreasons: [
          {
            id: 1,
            createdby: "Fatou Mbathie",
            creationdate: "2021-12-14T16:34:56.000000Z",
            disable: null,
            eyoneexternalid: null,
            eyoneinternalid: null,
            globalinfo: null,
            label: "Urgence",
            updatedby: null,
            updateddate: "2021-12-14T16:34:56.000000Z",
            version: null,
            pivot: {
              doctor_id: 4,
              appointment_reason_id: 1,
            },
          },
        ],
        datetimesslot: [],
        isNavLeftPossible: false,
        availabilities: [
          {
            fullDate: "2022-06-24",
            day: "Vendredi",
            date: 24,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-25",
            day: "Samedi",
            date: 25,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-26",
            day: "Dimanche",
            date: 26,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-27",
            day: "Lundi",
            date: 27,
            month: "juin",
            slots: [],
          },
          {
            fullDate: "2022-06-28",
            day: "Mardi",
            date: 28,
            month: "juin",
            slots: [],
          },
        ],
        isExpendable: false,
        next_range_start: "2022-06-29",
        previous_range_start: "2022-06-19",
        rowNumber: [null, null, null, null],
      },
    ],
    first_page_url:
      "http://php-referential.j.layershift.co.uk/api/search_doctors?page=1",
    from: 1,
    last_page: 2,
    last_page_url:
      "http://php-referential.j.layershift.co.uk/api/search_doctors?page=2",
    links: [
      {
        url: null,
        label: "&laquo; Previous",
        active: false,
      },
      {
        url: "http://php-referential.j.layershift.co.uk/api/search_doctors?page=1",
        label: "1",
        active: true,
      },
      {
        url: "http://php-referential.j.layershift.co.uk/api/search_doctors?page=2",
        label: "2",
        active: false,
      },
      {
        url: "http://php-referential.j.layershift.co.uk/api/search_doctors?page=2",
        label: "Next &raquo;",
        active: false,
      },
    ],
    next_page_url:
      "http://php-referential.j.layershift.co.uk/api/search_doctors?page=2",
    path: "http://php-referential.j.layershift.co.uk/api/search_doctors",
    per_page: 10,
    prev_page_url: null,
    to: 10,
    total: 11,
  };


  constructor() { }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: environment.appName },
      { label: "Docteurs", active: true },
    ];
  }

}
