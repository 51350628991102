import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { headers } from "src/app/headerUtils";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppointmentsService {
  private _appointment: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this._appointment = new BehaviorSubject<any>("");
  }

  public setAppointment(appointment) {
    this._appointment.next(appointment);
  }

  public get appointment(): any {
    return this._appointment.value;
  }

  getAllManagedPassport(external) {
    return this.http.get<any[]>(
      `${environment.base}api/rest/v1/patients/manage-accounts/${external}`,
      headers.passport()
    );
  }

  getPatientAppointments(patientExternalId) {
    return this.http.get<[]>(
      `${environment.base}api/rest/v1/appointments/patients/${patientExternalId}`,
      headers.passport()
    );
  }

  bookAppointment(body) {
    return this.http.post<[]>(
      `${environment.base}api/rest/v1/appointments`,
      body,
      headers.passport()
    );
  }

  cancelAppointment(body) {
    return this.http.put<[]>(
      `${environment.base}api/rest/v1/appointments`,
      body,
      headers.passport()
    );
  }
}
