import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trimSlot",
})
export class TrimSlotsPipe implements PipeTransform {
  /**
   * Removes seconds from hours.
   * IE: 09:00:00 will be 09:00
   * @param value 
   * @returns 
   */
  transform(value: string): string {
    return value.substring(0, 5);
  }
}
