import { HttpResponse } from "@angular/common/http";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { AuthService } from "src/app/core/services/authentification.service";
import { ConfirmLoginValidator } from "src/app/core/services/confirm-login.validator";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  breadCrumbItems: Array<{}>;
  error = "";
  submitted = false;
  modifier = false;
  pwdForm: FormGroup;
  userInfosForm: FormGroup;
  imgName: any;
  imgExt: any;
  patient: any;
  imageAvailable: boolean = false;
  passportType: string;
  userName: any;

  constructor(
    public formBuilder: FormBuilder,
    private usr: UserProfileService,
    public domsanitizer: DomSanitizer,
    private loader: NgxUiLoaderService,
    private auth: AuthService,
    private h: HelpersService
  ) {}

  ngOnInit(): void {
    this.userName = sessionStorage.getItem("login");
    this.passportType = sessionStorage.getItem("passportType");
    this.breadCrumbItems = [
      { label: environment.appPasseport },
      { label: "Profil", active: true },
    ];
    this.userInfosForm = this.formBuilder.group(
      {
        prenom: ["", [Validators.nullValidator]],
        nom: ["", [Validators.nullValidator]],
        email: ["", [Validators.nullValidator]],
        login: ["", [Validators.nullValidator]],
        newLogin: ["", [Validators.required]],
        confirmation: ["", [Validators.required]],
        adresse: ["", [Validators.nullValidator]],
        bday: ["", [Validators.nullValidator]],
        sex: ["", [Validators.nullValidator]],
      },
      {
        validator: ConfirmLoginValidator("newLogin", "confirmation"),
      }
    );
    this.pwdForm = this.formBuilder.group(
      {
        oldPwd: ["", [Validators.required]],
        newPwd: ["", [Validators.required]],
        confirmNewPwd: ["", [Validators.required]],
      },
      {
        validator: ConfirmLoginValidator("newPwd", "confirmNewPwd"),
      }
    );

    this.loader.start();
    this.usr
      .getPatientByUsername(this.userName)
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.patient = r;
        this.userInfosForm.setValue({
          prenom: r.firstName,
          nom: r.lastName,
          email: r.mail,
          login: r.mobileNumber,
          adresse: r.adress,
          bday: r.birthday,
          sex: r.sex,
          newLogin: "",
          confirmation: "",
        });
      })
      .catch((e) => {
        this.loader.stop();
        if (e.error.errorCode === "EXPIRED_TOKEN") {
          this.loader.start();
          this.h.handleExpiredToken().then((_) => {
            this.usr
              .getPatientByUsername(this.userName)
              .pipe(timeout(30000))
              .toPromise()
              .then((r) => {
                this.loader.stop();
                this.patient = r;
                this.userInfosForm.setValue({
                  prenom: r.firstName,
                  nom: r.lastName,
                  email: r.mail,
                  login: r.mobileNumber,
                  adresse: r.adress,
                  bday: r.birthday,
                  sex: r.sex,
                  newLogin: "",
                  confirmation: "",
                });
              })
              .catch((e) => {
                this.loader.stop();
                this.h.handleRequestError(e);
              });
          });
        } else {
          this.h.handleRequestError(e);
        }
      });
  }

  ngAfterViewInit(): void {}

  get pi() {
    return this.userInfosForm.controls;
  }
  get p() {
    return this.pwdForm.controls;
  }

  update() {
    this.modifier = !this.modifier;
  }
  cancel() {
    this.submitted = false;
    this.modifier = !this.modifier;
    this.userInfosForm.patchValue({
      prenom: this.patient.firstName,
      nom: this.patient.lastName,
      email: this.patient.mail,
      login: this.patient.mobileNumber,
      adresse: this.patient.adress,
      bday: this.patient.birthday,
      sex: this.patient.sex,
      newLogin: "",
      confirmation: "",
    });
  }
  save() {
    this.submitted = true;
    if (this.userInfosForm.invalid) {
      return;
    } else {
      let patientExternalId = sessionStorage.getItem("patientExternalId");
      let body = {
        eyoneExternalId: patientExternalId,
        newConfirmedMobileNumber:
          "00221" + this.userInfosForm.value.confirmation,
        newMobileNumber: "00221" + this.userInfosForm.value.newLogin,
        oldMobileNumber: this.userInfosForm.value.login,
      };

      this.loader.start();
      this.usr
        .updateUsername(body)
        .toPromise()
        .then((_) => {
          this.loader.stop();
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été modifié avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) => this.auth.logout());
        })
        .catch((e) => {
          this.loader.stop();
          if (e.error.errorCode === "EXPIRED_TOKEN") {
            this.loader.start();
            this.h.handleExpiredToken().then((_) => {
              this.usr
                .updateUsername(body)
                .pipe(timeout(30000))
                .toPromise()
                .then((_) => {
                  this.loader.stop();
                  Swal.fire({
                    title: "Succès",
                    icon: "success",
                    text: "Votre compte a été modifié avec succès!",
                    showConfirmButton: false,
                    timer: 3000,
                  }).then((_) => this.auth.logout());
                })
                .catch((e) => {
                  this.loader.stop();
                  this.h.handleRequestError(e);
                });
            });
          } else {
            this.h.handleRequestError(e);
          }
        });
    }
  }
  updatePassword() {
    this.submitted = true;
    if (this.pwdForm.invalid) {
      return;
    } else {
      let userName = sessionStorage.getItem("login");
      let patientExternalId = sessionStorage.getItem("patientExternalId");
      let body = {
        userName: userName,
        eyoneExternalId: patientExternalId,
        newConfirmedPassWord: this.pwdForm.value.confirmNewPwd,
        newPassword: this.pwdForm.value.newPwd,
        oldPassword: this.pwdForm.value.oldPwd,
      };

      this.loader.start();
      this.usr
        .changePassword(body)
        .toPromise()
        .then((_) => {
          this.loader.stop();
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre mot de passe a été modifié avec succès!",
            confirmButtonText: "Se reconnecter",
            confirmButtonColor: "#2b3c8b",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.auth.logout();
            }
          });
        })
        .catch((e) => {
          this.loader.stop();
          if (e.error.errorCode === "EXPIRED_TOKEN") {
            this.loader.start();
            this.h.handleExpiredToken().then((_) => {
              this.usr
                .changePassword(body)
                .pipe(timeout(30000))
                .toPromise()
                .then((_) => {
                  this.loader.stop();
                  Swal.fire({
                    title: "Succès",
                    icon: "success",
                    text: "Votre mot de passe a été modifié avec succès!",
                    confirmButtonText: "Se reconnecter",
                    confirmButtonColor: "#2b3c8b",
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.auth.logout();
                    }
                  });
                })
                .catch((e) => {
                  this.loader.stop();
                  this.h.handleRequestError(e);
                });
            });
          } else {
            this.h.handleRequestError(e);
          }
        });
    }
  }

  onFileChange(event: any) {
    for (let file of event.target.files) {
      this.imgName = file.name;
      this.imgExt = this.getExtension1(this.imgName).toUpperCase();

      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded1.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }
  handleReaderLoaded1(e) {
    let file = {
      content: btoa(e.target.result),
      extension: this.getExtension1(this.imgName).toUpperCase(),
      name: this.imgName,
      originalName: this.imgName,
      // patientExternalid: this.patient.eyoneExternalId,
      documentTypeCode: "BILL",
    };
  }
  getExtension1(chemin) {
    var regex = /[^.]*$/i;
    var resultats = chemin.match(regex);
    return resultats[0];
  }
  createImageFromBlob(image: Blob, element: any) {
    const reader = new FileReader();
    reader.onload = () => {
      element.content = reader.result;
    };
    if (image) {
      reader.readAsDataURL(image);
      this.imageAvailable = true;
    }
  }
}
