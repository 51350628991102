import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgbAlertModule, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { LanguageService } from "../../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";

import { TopbarComponent } from "./topbar/topbar.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
import { HorizontaltopbarComponent } from "./horizontaltopbar/horizontaltopbar.component";
import { HorizontalnavbarComponent } from "./horizontalnavbar/horizontalnavbar.component";
import { PasseportHorizontalnavbarComponent } from "./passeporthorizontalnavbar/passeporthorizontalnavbar.component";
import { PasseportHorizontaltopbarComponent } from "./passeporthorizontaltopbar/passeporthorizontaltopbar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CountryISO, NgxIntlTelInputModule } from "ngx-intl-tel-input";
import intlTelInput from "intl-tel-input"
import { CountryCode } from "ngx-intl-tel-input/lib/data/country-code";
import { NgSelectModule } from "@ng-select/ng-select";
import { DppSnTopbarComponent } from './dpp-sn-topbar/dpp-sn-topbar.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    TopbarComponent,
    FooterComponent,
    SidebarComponent,
    RightsidebarComponent,
    HorizontaltopbarComponent,
    HorizontalnavbarComponent,
    PasseportHorizontalnavbarComponent,
    PasseportHorizontaltopbarComponent,
    DppSnTopbarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    NgbDropdownModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgxIntlTelInputModule,
    NgSelectModule,
  ],
  exports: [
    TopbarComponent,
    FooterComponent,
    SidebarComponent,
    RightsidebarComponent,
    HorizontaltopbarComponent,
    HorizontalnavbarComponent,
    PasseportHorizontalnavbarComponent,
    PasseportHorizontaltopbarComponent,
    DppSnTopbarComponent
  ],
  providers: [LanguageService],
})
export class SharedModule {}
