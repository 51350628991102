<div class="row">
    <div class="col-sm-12 col-md-12">
        <div id="tickets-table_filter" class="dataTables_filter text-md-left"><label
                class="d-inline-flex align-items-center">Rechercher:
                <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
    </div>
</div>
<div class="card tw-h-[50vh] tw-overflow-auto">
    <div class="card-body">
        <div class="table-responsive tw-bg-white">
            <table class="table table-centered">
                <thead class="thead-light">
                    <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Sexe</th>
                        <th>Date de naissance</th>
                        <th>Téléphone</th>
                        <th>Type de compte</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="managedAccounts.length !== 0">
                    <tr *ngFor="let managedAccount of managedAccounts|filter:term">
                        <td>{{managedAccount?.firstName}}</td>
                        <td>{{managedAccount?.lastName}}</td>
                        <td>{{managedAccount?.sex}}</td>
                        <td>{{managedAccount?.birthday}}</td>
                        <td>{{managedAccount?.mobileNumber}}</td>
                        <td>{{managedAccount?.passeportManagementTypeLabel}}</td>
                        <td>
                            <button (click)="upgrade(managedAccount)"
                                class="btn btn-sm tw-bg-[#009DF8] tw-text-white"><span><i
                                        class="ri-shield-user-line tw-align-bottom mr-1"></i></span> Passer à
                                un compte principal</button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="managedAccounts.length === 0">
                    <tr>
                        <td colspan="7" class="surface-100 text-center">Aucune donnée disponible.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>