import { Component, OnInit } from "@angular/core";

import { EventService } from "../../core/services/event.service";

@Component({
  selector: "app-passeportlayout",
  templateUrl: "./passeportlayout.component.html",
  styleUrls: ["./passeportlayout.component.scss"],
})
export class PasseportLayoutComponent implements OnInit {
  constructor(private eventService: EventService) {}

  ngOnInit() {}
}
