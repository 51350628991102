import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";

import { User } from "../models/auth.models";
import { Router } from "@angular/router";
import { headers } from "src/app/headerUtils";
import { environment } from "src/environments/environment";
import { HelpersService } from "./helpers/helpers.service";
import Swal from "sweetalert2";

@Injectable({ providedIn: "root" })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("user"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(login: string, password: string) {
    let params = new URLSearchParams();
    params.append("username", login);
    params.append("password", password);

    return this.http.post<any>(environment.oauthMs, {
      login,
      password,
    });
  }

  getPublicJwtToken(email, password) {
    let body = {
      email: email,
      password: password,
    };
    return this.http
      .post<any>(environment.referentiel + "auth/login", body)
      .pipe(timeout(30000))
      .toPromise()
      .then((data) => {
        sessionStorage.setItem("publicToken", data.data["access_token"]);
      })
      .catch((e) => {
        if (e.name === "TimeoutError") {
          Swal.fire({
            icon: "info",
            text: "La requête a expiré. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Erreur",
            icon: "error",
            text: e.error.message,
            showConfirmButton: false,
            timer: 10000,
          });
        }
      });
  }

  findPatientByUsername(username) {
    return this.http.get<any>(
      `${environment.base}api/rest/patientByUsername/${username}`,
      headers.passport()
    );
  }

  refreshToken() {
    let refresh_token = sessionStorage.getItem("refreshPassportToken");
    return this.http.post<any>(`${environment.base}auth/reload`, {
      refresh: refresh_token,
    });
  }

  logout() {
    // sessionStorage.clear();
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("passportToken");
    sessionStorage.removeItem("refreshPassportToken");
    sessionStorage.removeItem("patientExternalId");
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    sessionStorage.removeItem("passportType");
    this.currentUserSubject.next(null);
    if (
      this.router.url == "/reservation" ||
      this.router.url == "/profil" ||
      this.router.url.includes("/passeport")
    ) {
      this.router.navigate(["/"]).then(() => window.location.reload());
    } else {
      window.location.reload();
    }
  }
}
