<div id="layout-wrapper">
  <!-- <app-passeporthorizontaltopbar></app-passeporthorizontaltopbar>
  <app-passeporthorizontalnavbar></app-passeporthorizontalnavbar> -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content tw-bg-[#f1f5f7]">
    <div class="tw-px-0">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>

  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->