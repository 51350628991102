import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import localeFr from "@angular/common/locales/fr";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { environment } from "../environments/environment";

import { LayoutsModule } from "./layouts/layouts.module";
import { PagesModule } from "./public/pages.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";

import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { registerLocaleData } from "@angular/common";
import { ConnectionServiceModule } from "ng-connection-service";
import { NgIdleModule } from "@ng-idle/core";
import { NgbDateAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "./customAdapter";
import { CustomDateParserFormatter } from "./customDateParserFormatter";

registerLocaleData(localeFr, "fr");

FakeBackendInterceptor;

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    LayoutsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    ConnectionServiceModule,
    NgIdleModule.forRoot(),
  ],

  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: FakeBackendInterceptor,
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: "fr" },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
