import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrderPipe } from "ngx-order-pipe";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import {
  DAYS,
  FIVEDAYSBEFORESTR,
  MONTHS,
  NOW,
  TODAY,
  JOURS,
  MOIS,
} from "src/app/shared/data";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { AuthService } from "../authentification.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class HelpersService {
  listLang = [
    { text: "Français", flag: "assets/images/flags/french.jpg", lang: "fr" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
  ];

  constructor(
    private orderPipe: OrderPipe,
    private http: HttpClient,
    private auth: AuthService,
    private loader: NgxUiLoaderService,
    public cookiesService: CookieService
  ) {}

  checkLang() {
    let cv = this.cookiesService.get("lang");
    let val = this.listLang.filter((x) => x.lang === cv);
    let cn = val.map((element) => element.lang);
    if (val.length === 0) {
      return "fr";
    } else {
      return cn[0];
    }
  }

  setToBrowserStorage(k, v) {
    if (environment.production) {
      sessionStorage.setItem(k, v);
    } else {
      localStorage.setItem(k, v);
    }
  }
  getFromBrowserStorage(k) {
    if (environment.production) {
      let data = sessionStorage.getItem(k);
      return data;
    } else {
      let data = localStorage.getItem(k);
      return data;
    }
  }
  removeFromBrowserStorage(k) {
    if (environment.production) {
      sessionStorage.getItem(k);
    } else {
      localStorage.getItem(k);
    }
  }

  getPosition() {
    this.loader.start();
    sessionStorage.setItem("position", "true");
    Swal.fire({
      icon: "success",
      text: "Localisation activée avec succès.",
      showConfirmButton: false,
      timer: 2000,
    }).then(() => window.location.reload());
  }

  isNavigationSafe() {
    if (location.protocol === "http:" && location.hostname === "localhost") {
      return true;
    } else if (location.protocol === "https:") {
      return true;
    } else {
      return false;
    }
  }

  positionActivated() {
    if (sessionStorage.getItem("position") !== "true") {
      return false;
    } else {
      return true;
    }
  }

  isFree(slot) {
    if (slot.status === "FREE") {
      return true;
    } else {
      return false;
    }
  }
  isBusy(slot) {
    if (slot.status === "BUSY") {
      return true;
    } else {
      return false;
    }
  }
  isDisabled(slot) {
    if (slot.status === "DISABLE") {
      return true;
    } else {
      return false;
    }
  }
  isObsolete(slot) {
    let ts = `${NOW.getHours().toString().padStart(2, "0")}:${NOW.getMinutes()
      .toString()
      .padStart(2, "0")}:${NOW.getSeconds().toString().padStart(2, "0")}`;
    if (slot.timeslotdate === TODAY && ts > slot.starthour) {
      return true;
    } else {
      return false;
    }
  }
  removeAccent(word) {
    return word.replace(/é/g, "e");
  }
  removeSpaceAndAccent(word) {
    return word.toLowerCase().replace(/\s/g, "-").replace(/é/g, "e");
  }
  replaceSpace(word) {
    return word.toLowerCase().replace(/\s/g, "_").replace(/é/g, "e");
  }

  handleRequestError(e): any {
    console.error(e);
    if (e.name === "TimeoutError") {
      Swal.fire({
        icon: "info",
        text: "La requête a expiré. Veuillez réessayer plus tard.",
        showConfirmButton: false,
        timer: 10000,
      });
    } else {
      if (e.status === 401) {
        if (e.error.errorCode === "REQUIRED_USER") {
          this.auth.logout();
        } else if (e.error.errorCode === "INVALID_CREDENTIALS") {
          Swal.fire({
            title: "Erreur",
            icon: "error",
            text: e.error.message,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          this.auth.logout();
        }
      } else {
        Swal.fire({
          title: "Erreur",
          icon: "error",
          text: e.error.message,
          showConfirmButton: false,
          timer: 10000,
        });
      }
    }
  }
  handleRefRequestError(e): any {
    if (e.name === "TimeoutError") {
      Swal.fire({
        icon: "info",
        text: "La requête a expiré. Veuillez réessayer plus tard.",
        showConfirmButton: false,
        timer: 10000,
      });
    } else {
      Swal.fire({
        title: "Erreur",
        icon: "error",
        text: "Erreur lors de l'envoi de la requête. Veuillez réessayer plus tard.",
        showConfirmButton: false,
        timer: 10000,
      });
    }
  }

  async handleExpiredToken() {
    await this.auth
      .refreshToken()
      .pipe(timeout(120000))
      .toPromise()
      .then((r) => {
        sessionStorage.removeItem("passportToken");
        sessionStorage.removeItem("refreshPassportToken");
        sessionStorage.setItem("passportToken", r["access_token"]);
        sessionStorage.setItem("refreshPassportToken", r["refresh_token"]);
      })
      .catch((e) => this.auth.logout());
  }

  /**
   * SAL: service, assurance, langue
   * @param service
   * @param assurance
   * @param langue
   * @returns
   */
  professionalsSalQueryPath(service, assurance, langue) {
    let path;

    let latitude = "null";
    let longitude = "null";
    if (sessionStorage.getItem("position") === "true") {
      navigator.geolocation.getCurrentPosition((pos) => {
        latitude = pos.coords.latitude.toString();
        longitude = pos.coords.longitude.toString();
      }),
        (e) => console.error(e);
    }

    if (!service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null`;
    }
    if (service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&service=${service}`;
    }
    if (!service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&langue=${langue}`;
    }
    if (!service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&assurance=${assurance}`;
    }
    if (service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&service=${service}&langue=${langue}`;
    }
    if (service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&service=${service}&assurance=${assurance}`;
    }
    if (!service && langue && assurance) {
      path =
        environment.referentiel +
        environment.queryDoctors +
        `?latitude=null&longitude=null&langue=${langue}&assurance=${assurance}`;
    }
    return path;
  }

  /**
   * SAL: service, assurance, langue
   * @param service
   * @param assurance
   * @param langue
   * @returns
   */
  structuresSalQueryPath(service, assurance, langue) {
    let path;

    let latitude = "null";
    let longitude = "null";
    if (sessionStorage.getItem("position") === "true") {
      navigator.geolocation.getCurrentPosition((pos) => {
        latitude = pos.coords.latitude.toString();
        longitude = pos.coords.longitude.toString();
      }),
        (e) => console.error(e);
    }

    if (!service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}`;
    }
    if (service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}`;
    }
    if (!service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&langue=${langue}`;
    }
    if (!service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&assurance=${assurance}`;
    }
    if (service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}&langue=${langue}`;
    }
    if (service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}&assurance=${assurance}`;
    }
    if (!service && langue && assurance) {
      path =
        environment.referentiel +
        environment.queryStructures +
        `?latitude=${latitude}&longitude=${longitude}&langue=${langue}&assurance=${assurance}`;
    }
    return path;
  }

  /**
   * SAL: service, assurance, langue
   * @param service
   * @param assurance
   * @param langue
   * @returns
   */
  pharmaciesSalQueryPath(service, assurance, langue) {
    let path;

    let latitude = "null";
    let longitude = "null";
    if (sessionStorage.getItem("position") === "true") {
      navigator.geolocation.getCurrentPosition((pos) => {
        latitude = pos.coords.latitude.toString();
        longitude = pos.coords.longitude.toString();
      }),
        (e) => console.error(e);
    }

    if (!service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}`;
    }
    if (service && !langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}`;
    }
    if (!service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&langue=${langue}`;
    }
    if (!service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&assurance=${assurance}`;
    }
    if (service && langue && !assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}&langue=${langue}`;
    }
    if (service && !langue && assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&service=${service}&assurance=${assurance}`;
    }
    if (!service && langue && assurance) {
      path =
        environment.referentiel +
        environment.queryPharmacies +
        `?latitude=${latitude}&longitude=${longitude}&langue=${langue}&assurance=${assurance}`;
    }
    return path;
  }

  /**
   * Generate the initial dates range shown on professional's slot table.
   * @returns
   */
  initialDateRange() {
    let d1 = new Date(Date.now());
    let today = `${d1.getFullYear()}-${(d1.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${d1.getDate().toString().padStart(2, "0")}`;

    let dates = [];
    let from = new Date(today);
    let start = from;

    let fourDaysLater = new Date(
      new Date(start).setDate(new Date(start).getDate() + 4)
    );
    let fourDaysLaterAsStr = `${fourDaysLater.getFullYear()}-${(
      fourDaysLater.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fourDaysLater
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    let to = new Date(fourDaysLaterAsStr);

    for (let day = from; day <= to; day.setDate(day.getDate() + 1)) {
      let date =
        day.getFullYear().toString() +
        "-" +
        (day.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        day.getDate().toString().padStart(2, "0");
      dates.push(date);
    }
    return dates;
  }

  /**
   * Generate second dates range used when getting one professionals availabilities.
   * @param range_start
   * @returns
   */
  dateRange(range_start) {
    let dates = [];
    let from = new Date(range_start);
    let fourDaysLater = new Date(
      new Date(range_start).setDate(new Date(range_start).getDate() + 4)
    );
    let fourDaysLaterStr = `${fourDaysLater.getFullYear()}-${(
      fourDaysLater.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fourDaysLater
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    let to = new Date(fourDaysLaterStr);
    for (let day = from; day <= to; day.setDate(day.getDate() + 1)) {
      let date =
        day.getFullYear().toString() +
        "-" +
        (day.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        day.getDate().toString().padStart(2, "0");
      dates.push(date);
    }
    return dates;
  }

  // takeOnlineAppoint(list: []) {
  //   list.
  // }

  /**
   * Generate availabilities for each professional.
   * @param object
   * @returns
   */
  generateAvailabilities(object) {
    console.log(this.checkLang());
    let dates = this.initialDateRange();
    let availabilities = [];
    object.data.forEach((element) => {
      // if (element.appointmentbooking === 1) {
      //   element["takeOnlineSchedule"] = true;
      // } else {
      //   element["takeOnlineSchedule"] = false;
      // }
      availabilities = [];
      if (TODAY === dates[0]) {
        element["isNavLeftPossible"] = false;
      }
      // dates loop
      for (let d = 0; d < dates.length; d++) {
        let dts;
        dts = element.timeslots.filter((e) => {
          return e.timeslotdate == dates[d] && e.visible;
        });
        if (dts.length != 0) {
          let slots = this.orderPipe.transform(dts, "starthour");
          availabilities.push({
            fullDate: dates[d],
            day:
              this.checkLang() === "fr"
                ? JOURS[new Date(dates[d]).getDay()]
                : DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month:
              this.checkLang() === "fr"
                ? MOIS[new Date(dates[d]).getDay()]
                : MONTHS[new Date(dates[d]).getDay()],
            slots: slots,
          });
        } else {
          availabilities.push({
            fullDate: dates[d],
            day:
              this.checkLang() === "fr"
                ? JOURS[new Date(dates[d]).getDay()]
                : DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month:
              this.checkLang() === "fr"
                ? MOIS[new Date(dates[d]).getDay()]
                : MONTHS[new Date(dates[d]).getDay()],
            slots: [],
          });
        }
      }
      let from = new Date(TODAY);
      let start = from;
      let fiveDaysLater = new Date(
        new Date(start).setDate(new Date(start).getDate() + 5)
      );
      let fiveDaysLaterAsStr = `${fiveDaysLater.getFullYear()}-${(
        fiveDaysLater.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${fiveDaysLater
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      element["availabilities"] = availabilities;
      element["isExpendable"] = false;
      element["next_range_start"] = fiveDaysLaterAsStr;
      element["previous_range_start"] = FIVEDAYSBEFORESTR;
      element.availabilities.forEach((e) => {
        var lengths = [];
        element["rowNumber"] = Array(4)
          .fill(1, 4)
          .map((x, i) => i);
        if (e.slots.length != 0) {
          lengths.push(e.slots.length);
          if (Math.max(...lengths) > 4) {
            element["isExpendable"] = true;
            element["isCollapsed"] = false;
          } else {
            element["isExpendable"] = false;
          }
        }
      });
    });
    return object;
  }

  /**
   * Generate previous availabilities.
   * @param response
   * @param allProfessionals
   * @param professional
   * @returns
   */
  generatePreviousAvailabilities(response, allProfessionals, professional) {
    let availabilities = [];
    allProfessionals.data.forEach((element) => {
      if (element.id === professional.id) {
        element["timeslots"] = response[0]?.timeslots;
        let dates = this.dateRange(element.previous_range_start);
        // check if possible to view previous slots
        if (TODAY === dates[0]) {
          element["isNavLeftPossible"] = false;
        } else {
          element["isNavLeftPossible"] = true;
        }
        // Update next date range start
        let fiveDaysLater = new Date(
          new Date(element.previous_range_start).setDate(
            new Date(element.previous_range_start).getDate() + 5
          )
        );
        let fiveDaysBefore = new Date(
          new Date(element.previous_range_start).setDate(
            new Date(element.previous_range_start).getDate() - 5
          )
        );
        element["previous_range_start"] = `${fiveDaysBefore.getFullYear()}-${(
          fiveDaysBefore.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${fiveDaysBefore
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        element["next_range_start"] = `${fiveDaysLater.getFullYear()}-${(
          fiveDaysLater.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${fiveDaysLater
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        for (let d = 0; d < dates.length; d++) {
          let dts;
          if (element.timeslots.length != 0) {
            dts = element?.timeslots?.filter((e) => {
              return e.timeslotdate == dates[d] && e.visible;
            });
            if (dts?.length != 0) {
              let slots = this.orderPipe.transform(dts, "starthour");
              availabilities.push({
                fullDate: dates[d],
                day: DAYS[new Date(dates[d]).getDay()],
                date: new Date(dates[d]).getDate(),
                month: MONTHS[new Date(dates[d]).getMonth()],
                slots: slots,
              });
            } else {
              availabilities.push({
                fullDate: dates[d],
                day: DAYS[new Date(dates[d]).getDay()],
                date: new Date(dates[d]).getDate(),
                month: MONTHS[new Date(dates[d]).getMonth()],
                slots: [],
              });
            }
          } else {
            availabilities.push({
              fullDate: dates[d],
              day: DAYS[new Date(dates[d]).getDay()],
              date: new Date(dates[d]).getDate(),
              month: MONTHS[new Date(dates[d]).getMonth()],
              slots: [],
            });
          }
        }
        element["availabilities"] = availabilities;
        element["isExpendable"] = false;
        element.availabilities.forEach((e) => {
          let lengths = [];
          element["rowNumber"] = Array(4)
            .fill(1, 4)
            .map((x, i) => i);
          if (e.slots.length != 0) {
            lengths.push(e.slots.length);
            if (Math.max(...lengths) > 4) {
              element["isExpendable"] = true;
              element["isCollapsed"] = false;
            } else {
              element["isExpendable"] = false;
            }
          }
        });
      }
    });
    return allProfessionals;
  }

  /**
   * Generate next availabilities.
   * @param response
   * @param allProfessionals
   * @param professional
   * @returns
   */
  generateNextAvailabilities(response, allProfessionals, professional) {
    let availabilities = [];
    allProfessionals.data.forEach((element) => {
      if (element.id === professional.id) {
        element["timeslots"] = response[0]?.timeslots;
        let dates = this.dateRange(element.next_range_start);
        // check if possible to view previous slots
        if (TODAY === dates[0]) {
          element["isNavLeftPossible"] = false;
        } else {
          element["isNavLeftPossible"] = true;
        }
        // Update next date range start
        let fiveDaysLater = new Date(
          new Date(element.next_range_start).setDate(
            new Date(element.next_range_start).getDate() + 5
          )
        );
        let fiveDaysBefore = new Date(
          new Date(element.next_range_start).setDate(
            new Date(element.next_range_start).getDate() - 5
          )
        );
        element["previous_range_start"] = `${fiveDaysBefore.getFullYear()}-${(
          fiveDaysBefore.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${fiveDaysBefore
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        element["next_range_start"] = `${fiveDaysLater.getFullYear()}-${(
          fiveDaysLater.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${fiveDaysLater
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        for (let d = 0; d < dates.length; d++) {
          let dts;
          if (element.timeslots.length != 0) {
            dts = element?.timeslots?.filter((e) => {
              return e.timeslotdate == dates[d] && e.visible;
            });
            if (dts?.length != 0) {
              let slots = this.orderPipe.transform(dts, "starthour");
              availabilities.push({
                fullDate: dates[d],
                day: DAYS[new Date(dates[d]).getDay()],
                date: new Date(dates[d]).getDate(),
                month: MONTHS[new Date(dates[d]).getMonth()],
                slots: slots,
              });
            } else {
              availabilities.push({
                fullDate: dates[d],
                day: DAYS[new Date(dates[d]).getDay()],
                date: new Date(dates[d]).getDate(),
                month: MONTHS[new Date(dates[d]).getMonth()],
                slots: [],
              });
            }
          } else {
            availabilities.push({
              fullDate: dates[d],
              day: DAYS[new Date(dates[d]).getDay()],
              date: new Date(dates[d]).getDate(),
              month: MONTHS[new Date(dates[d]).getMonth()],
              slots: [],
            });
          }
        }
        element["availabilities"] = availabilities;
        element["isExpendable"] = false;
        element.availabilities.forEach((e) => {
          let lengths = [];
          element["rowNumber"] = Array(4)
            .fill(1, 4)
            .map((x, i) => i);
          if (e.slots.length != 0) {
            lengths.push(e.slots.length);
            if (Math.max(...lengths) > 4) {
              element["isExpendable"] = true;
              element["isCollapsed"] = false;
            } else {
              element["isExpendable"] = false;
            }
          }
        });
      }
    });
    return allProfessionals;
  }

  generateOneProfessionalAvailabilities(professional) {
    let dates = this.initialDateRange();
    let availabilities = [];
    for (let d = 0; d < dates.length; d++) {
      let dts;
      dts = professional.timeslots.filter((e) => {
        return e.timeslotdate == dates[d] && e.visible;
      });
      if (dts.length != 0) {
        let slots = this.orderPipe.transform(dts, "starthour");
        availabilities.push({
          fullDate: dates[d],
          day: DAYS[new Date(dates[d]).getDay()],
          date: new Date(dates[d]).getDate(),
          month: MONTHS[new Date(dates[d]).getMonth()],
          slots: slots,
        });
      } else {
        availabilities.push({
          fullDate: dates[d],
          day: DAYS[new Date(dates[d]).getDay()],
          date: new Date(dates[d]).getDate(),
          month: MONTHS[new Date(dates[d]).getMonth()],
          slots: [],
        });
      }
    }
    let from = new Date(TODAY);
    let start = from;
    let fiveDaysLater = new Date(
      new Date(start).setDate(new Date(start).getDate() + 5)
    );
    let fiveDaysLaterAsStr = `${fiveDaysLater.getFullYear()}-${(
      fiveDaysLater.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fiveDaysLater
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    professional["availabilities"] = availabilities;
    professional["isExpendable"] = false;
    professional["next_range_start"] = fiveDaysLaterAsStr;
    professional["previous_range_start"] = FIVEDAYSBEFORESTR;
    professional.availabilities.forEach((e) => {
      let lengths = [];
      professional["rowNumber"] = Array(4)
        .fill(1, 4)
        .map((x, i) => i);
      if (e.slots.length != 0) {
        lengths.push(e.slots.length);
        if (Math.max(...lengths) > 4) {
          professional["isExpendable"] = true;
          professional["isCollapsed"] = false;
        } else {
          professional["isExpendable"] = false;
        }
      }
    });
    return professional;
  }

  generateOneProfessionalPreviousAvailabilities(professional, response) {
    let availabilities = [];
    professional["timeslots"] = response[0]?.timeslots;
    let dates = this.dateRange(professional.previous_range_start);
    // check if possible to view previous slots
    if (TODAY === dates[0]) {
      professional["isNavLeftPossible"] = false;
    } else {
      professional["isNavLeftPossible"] = true;
    }
    // Update next date range start
    let from = new Date(professional.previous_range_start);
    let fiveDaysLater = new Date(
      new Date(professional.previous_range_start).setDate(
        new Date(professional.previous_range_start).getDate() + 5
      )
    );
    let fiveDaysBefore = new Date(
      new Date(professional.previous_range_start).setDate(
        new Date(professional.previous_range_start).getDate() - 5
      )
    );
    professional["previous_range_start"] = `${fiveDaysBefore.getFullYear()}-${(
      fiveDaysBefore.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fiveDaysBefore
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    professional["next_range_start"] = `${fiveDaysLater.getFullYear()}-${(
      fiveDaysLater.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fiveDaysLater
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    for (let d = 0; d < dates.length; d++) {
      let dts;
      if (professional.timeslots.length != 0) {
        dts = professional?.timeslots?.filter((e) => {
          return e.timeslotdate == dates[d] && e.visible;
        });
        if (dts?.length != 0) {
          let slots = this.orderPipe.transform(dts, "starthour");
          availabilities.push({
            fullDate: dates[d],
            day: DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month: MONTHS[new Date(dates[d]).getMonth()],
            slots: slots,
          });
        } else {
          availabilities.push({
            fullDate: dates[d],
            day: DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month: MONTHS[new Date(dates[d]).getMonth()],
            slots: [],
          });
        }
      } else {
        availabilities.push({
          fullDate: dates[d],
          day: DAYS[new Date(dates[d]).getDay()],
          date: new Date(dates[d]).getDate(),
          month: MONTHS[new Date(dates[d]).getMonth()],
          slots: [],
        });
      }
    }
    professional["availabilities"] = availabilities;
    professional["isExpendable"] = false;
    professional.availabilities.forEach((e) => {
      let lengths = [];
      professional["rowNumber"] = Array(4)
        .fill(1, 4)
        .map((x, i) => i);
      if (e.slots.length != 0) {
        lengths.push(e.slots.length);
        if (Math.max(...lengths) > 4) {
          professional["isExpendable"] = true;
          professional["isCollapsed"] = false;
        } else {
          professional["isExpendable"] = false;
        }
      }
    });
    return professional;
  }

  generateOneProfessionaNextAvailabilities(professional, response) {
    let availabilities = [];
    professional["timeslots"] = response[0]?.timeslots;
    let dates = this.dateRange(professional.next_range_start);
    // check if possible to view previous slots
    if (TODAY === dates[0]) {
      professional["isNavLeftPossible"] = false;
    } else {
      professional["isNavLeftPossible"] = true;
    }
    // Update next date range start
    let fiveDaysLater = new Date(
      new Date(professional.next_range_start).setDate(
        new Date(professional.next_range_start).getDate() + 5
      )
    );
    let fiveDaysBefore = new Date(
      new Date(professional.next_range_start).setDate(
        new Date(professional.next_range_start).getDate() - 5
      )
    );
    professional["previous_range_start"] = `${fiveDaysBefore.getFullYear()}-${(
      fiveDaysBefore.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fiveDaysBefore
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    professional["next_range_start"] = `${fiveDaysLater.getFullYear()}-${(
      fiveDaysLater.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${fiveDaysLater
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    for (let d = 0; d < dates.length; d++) {
      let dts;
      if (professional.timeslots.length != 0) {
        dts = professional?.timeslots?.filter((e) => {
          return e.timeslotdate == dates[d] && e.visible;
        });
        if (dts?.length != 0) {
          let slots = this.orderPipe.transform(dts, "starthour");
          availabilities.push({
            fullDate: dates[d],
            day: DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month: MONTHS[new Date(dates[d]).getMonth()],
            slots: slots,
          });
        } else {
          availabilities.push({
            fullDate: dates[d],
            day: DAYS[new Date(dates[d]).getDay()],
            date: new Date(dates[d]).getDate(),
            month: MONTHS[new Date(dates[d]).getMonth()],
            slots: [],
          });
        }
      } else {
        availabilities.push({
          fullDate: dates[d],
          day: DAYS[new Date(dates[d]).getDay()],
          date: new Date(dates[d]).getDate(),
          month: MONTHS[new Date(dates[d]).getMonth()],
          slots: [],
        });
      }
    }
    professional["availabilities"] = availabilities;
    professional["isExpendable"] = false;
    professional.availabilities.forEach((e) => {
      let lengths = [];
      professional["rowNumber"] = Array(4)
        .fill(1, 4)
        .map((x, i) => i);
      if (e.slots.length != 0) {
        lengths.push(e.slots.length);
        if (Math.max(...lengths) > 4) {
          professional["isExpendable"] = true;
          professional["isCollapsed"] = false;
        } else {
          professional["isExpendable"] = false;
        }
      }
    });
    return professional;
  }

  getImage(url): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Content-Type",
      "application/json, text/plain, */*"
    );
    headers = headers.append(
      "Authorization",
      "Bearer " + sessionStorage.getItem("passportToken")
    );

    return this.http.get<Blob>(`${url}`, {
      headers: headers,
      responseType: "blob" as "json",
    });
  }
}
