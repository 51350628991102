import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { ConnectionService } from "ng-connection-service";
import Swal from "sweetalert2";
import { AuthService } from "./core/services/authentification.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "Dossier Patient Partagé";
  isConnected = true;
  noInternetConnection: boolean;
  idleState = "NOT_STARTED";
  countdown?: number = null;

  constructor(
    private auth: AuthService,
    private connectionService: ConnectionService,
    private idle: Idle,
    cd: ChangeDetectorRef
  ) {
    // set idle parameters
    idle.setIdle(1200); // how long can they be inactive before considered idle, in seconds (1200)
    idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      cd.detectChanges();
      Swal.close();
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      Swal.close();
      auth.logout();
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe((seconds) => {
      this.countdown = seconds;
      if (this.countdown === null) {
        Swal.close();
      }
      Swal.fire({
        title: "Votre session va expirer dans:",
        text: `${seconds}`,
        position: "top-end",
        showConfirmButton: false,
        toast: true,
      });
    });
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
  }

  ngAfterViewInit(): void {
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    if (patientExternalId) {
      this.reset();
    }
  }

  ngOnInit(): void {
    this.auth.getPublicJwtToken("francis.ngom@eyone.net", "passe");
    this.connectivityListener();
  }

  connectivityListener() {
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
        Swal.fire({
          title: "Connexion internet restaurée",
          icon: "success",
          text: "Vous êtes de nouveau connecté(e) à internet.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        this.noInternetConnection = true;
        Swal.fire(
          "Connexion internet perdue",
          "Vérifiez que vous êtes connecté(e) au wifi ou aux données mobiles.",
          "warning"
        );
      }
    });
  }
}
