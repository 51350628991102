<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm text-uppercase">
            {{'HEADER.MEDICAL_PASSPORT' | translate}}
          </span>
          <span class="logo-lg text-uppercase">
            {{'HEADER.MEDICAL_PASSPORT' | translate}}
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm tw-text-white tw-font-black">
            <img src="assets/images/logo-light.png" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img class="mr-2" src="assets/images/logo-light.png" alt="" height="45">
          </span>
          <span class="logo-lg tw-text-xs tw-text-white tw-font-black text-uppercase"> {{'HEADER.MEDICAL_PASSPORT' |
            translate}}</span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="ri-menu-2-line align-middle"></i>
      </button>
    </div>

    <div class="tw-flex tw-content-center tw-items-center">

      <div class="tw-inline-block tw-mr-4">
        <button
          class="btn tw-flex tw-content-center tw-items-center tw-bg-[#009DF8] tw-text-white hover:tw-bg-[#26AFFF]"
          (click)="goToPublic()"><span class="tw-font-bold">{{'HEADER.GOTOPUBLIC' | translate}}</span></button>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/users/placeholder.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">{{ firstName }} {{ lastName }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/profil" href="javascript: void(0);"><i
              class="ri-user-line align-middle mr-1"></i>
            {{'HEADER.LOGIN.MY_PROFILE' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

    </div>
  </div>
</header>