<!-- <form class="form-horizontal" [formGroup]="signupForm" (ngSubmit)="onSubmit()"> -->
<ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">
    Veuillez renseigner tous les champs.
</ngb-alert>
<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

<p class="tw-m-0"><span class="text-danger">*</span> Choix du motif</p>
<div class="form-group">
    <select class="form-control" id="exampleFormControlSelect1" [ngModel]="selectedMotif"
        (ngModelChange)="onServiceChange($event)">
        <option *ngFor="let motif of professional?.appointmentreasons" [ngValue]=motif>{{
            motif?.label }}</option>
    </select>
    <small *ngIf="submitted && !selectedMotif" class="text-danger tw-text-xs">
        Vous devez choisir un motif de rendez-vous.
    </small>
</div>

<p class="tw-m-0"><span class="text-danger">*</span> Sélectionnez le lieu de rendez-vous</p>
<div class="form-group">
    <select class="form-control" id="exampleFormControlSelect1" [ngModel]="selectedPlace"
        (ngModelChange)="onSelectPlace($event)">
        <option *ngFor="let place of professional?.structures" [ngValue]=place>{{
            place?.name }}</option>
    </select>
    <small *ngIf="submitted && !selectedPlace" class="text-danger tw-text-xs">
        Vous devez sélectionner le lieu de rendez-vous.
    </small>
</div>
<!-- <div *ngIf="professional?.structures.length > 1" class="tw-w-12/12 sm:tw-w-6/12 tw-flex tw-mt-1">
    <div *ngFor="let place of professional?.structures; let index = i" class="custom-control custom-radio tw-mr-6 mb-1">
        <input type="radio" class="custom-control-input" [id]="place?.id" [ngModel]="selectedPlace"
            (ngModelChange)="onSelectPlace($event)" value={{place}}>
        <label class="custom-control-label text-nowrap" [for]="place?.id">{{ place?.name }}</label>
    </div>
    <small *ngIf="submitted && !selectedPlace" class="text-danger tw-text-xs">
        Vous devez choisir le lieu du rendez-vous.
    </small>
</div>
<div *ngIf="professional?.structures.length === 1" class="tw-w-12/12 sm:tw-w-6/12 tw-flex tw-mb-3">
    <p class="tw-m-0 tw-px-2 tw-bg-blue-100 tw-font-bold tw-rounded">{{ professional.structures[0].name }}</p>
    <small *ngIf="submitted && !selectedPlace" class="text-danger tw-text-xs">
        Vous devez choisir le lieu du rendez-vous.
    </small>
</div> -->

<div *ngIf="proches.length !== 0" class="mt-2">
    <p class="tw-m-0"><span class="text-danger">*</span> Pour qui prenez-vous ce rendez-vous ?</p>
    <small *ngIf="submitted && !beneficiary" class="text-danger tw-text-xs">
        Vous devez choisir le patient pour qui vous prenez rendez-vous.
    </small>
    <div class="w-100 tw-flex tw-flex-col tw-mt-1">
        <div class="custom-control custom-radio tw-mr-6 mb-2">
            <input type="radio" class="custom-control-input" id="moi" value="moi" [ngModel]="beneficiary"
                (ngModelChange)="onSelectBeneficiary($event)">
            <label class="custom-control-label" for="moi">Pour moi</label>
        </div>
        <div *ngFor="let proche of proches; let index = i" class="custom-control custom-radio tw-mr-6 mb-2">
            <input type="radio" class="custom-control-input" [id]="proche?.eyoneExternalId"
                value="{{ proche?.eyoneExternalId }}" [ngModel]="beneficiary"
                (ngModelChange)="onSelectBeneficiary($event)">
            <label class="custom-control-label" [for]="proche?.eyoneExternalId">Pour {{ proche?.firstName }} {{
                proche?.lastName
                }}</label>
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-sm-center">
    <button (click)="book()" class="btn tw-w-12/12 sm:tw-w-6/12 tw-bg-[#2b3c8b] tw-text-white">Prendre
        Rendez-Vous</button>
</div>