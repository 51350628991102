<app-dpp-sn-topbar></app-dpp-sn-topbar>

<section id="welcome" class="welcome-hero-one">
  <div class="h-100 tw-relative d-flex flex-column justify-content-center">

    <div class="container-fluid">
      <img class="ml-1" src="assets/images/welcome-txt.svg" alt="" width="320">
    </div>
    <h1 class="container-fluid tw-text-5xl tw-font-bold text-white mt-4" style="font-family: Poppins;">
      sur le portail du Dossier
    </h1>
    <h1 class="container-fluid tw-text-5xl tw-font-bold text-white mt-2" style="font-family: Poppins;">
      Patient Partagé du Sénégal
    </h1>

    <div class="container-fluid">
      <button routerLink="/inscription" class="btn tw-bg-[#D00000] mr-auto tw-py-3 px-4 tw-mt-20">
        <h6 class="mb-0 text-white">Créer Mon Dossier Patient</h6>
      </button>
    </div>

    <div class="bl-rd tw-absolute tw-py-7 tw-w-6/12 bg-white" style="bottom: 0px; right: 0px">
      <h1 class="mb-0 text-center">Dossier Patient Partagé</h1>
    </div>

  </div>
</section>

<section id="valueNsearch" class="welcome-hero-two">
  <div class="h-100 tw-relative d-flex flex-column align-items-center justify-content-center">

    <div class="br-rd tw-absolute tw-py-12 tw-w-6/12 bg-white d-flex justify-content-center"
      style="top: 0px; left: 0px">
      <div id="vacsn">
        <h3 class="mb-0" style="font-family: Poppins;">Valeur ajoutée de votre</h3>
        <h3 class="mb-0 mt-1 tw-text-[#D00000]" style="font-family: Poppins;">carnet de santé numérique
        </h3>
        <p class="mb-1 mt-3 font-size-14" style="font-family: Poppins;">Le dossier Patient partagé (DPP) est une
          initiative du
          Sénégal à</p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">travers le Ministère de la santé et de l'Action
          Sociale.</p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">Le DPP vous donne un accès sécurisé à votre vie
          médicale en</p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">consolidant toutes vos informations médicales pour
          une gestion et
        </p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">une mise à disposition plus efficace de vos
          informations de santé.
        </p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">Consultez vos diagnostics, ordonnances et
          prescriptions,</p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">antécédents,... et facilitez la collaboration entre
          les
          professionnels</p>
        <p class="mb-1 font-size-14" style="font-family: Poppins;">de la santé pour une prise en charge optimale de vos
          soins.</p>
      </div>
    </div>
    <div class="container-fluid tw-absolute d-flex justify-content-between align-items-center px-0"
      style="bottom: 276px">
      <h4 class="mb-0 text-white" style="font-family: Poppins;">Que recherchez-vous ?</h4>
      <button class="btn tw-bg-[#D00000] tw-py-3 px-5 float-right">
        <h6 class="mb-0 text-white">Carnet Sanitaire</h6>
      </button>
    </div>

    <div class="w-100 tw-absolute" style="bottom: 6px">
      <div class="container-fluid bg-white d-flex tw-h-64">

        <div class="row justify-content-center align-items-center">
          <div role="button" routerLink="/professionnels"
            class="h-100 col-md-4 px-4 d-flex justify-content-center align-items-center hover:tw-bg-slate-50">
            <img class="mr-3" src="assets/images/pro-icon.svg" width="80">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="tw-text-[#D00000] tw-font-extrabold" style="font-family: Poppins;">Médecins</h6>
              <p class="text-dark text-wrap font-size-13 mb-0" style="font-family: Poppins;">
                Trouvez la liste des médecins agréés par le MSAS ainsi que leurs spécialités et d'autres informations
                pratiques.
              </p>
            </div>
          </div>
          <div role="button" routerLink="/structures"
            class="h-100 col-md-4 px-4 d-flex justify-content-center align-items-center hover:tw-bg-slate-50">
            <img class="mr-3" src="assets/images/str-icon.svg" width="80">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="tw-text-[#D00000] tw-font-extrabold" style="font-family: Poppins;">Structures de santé</h6>
              <p class="text-dark text-wrap font-size-13 mb-0" style="font-family: Poppins;">
                Trouvez la liste des structures de santés agréés par le MSAS ainsi que d'autres informations pratiques
                telles que les services proposés et les horaires.
              </p>
            </div>
          </div>
          <div role="button" routerLink="/pharmacies"
            class="h-100 col-md-4 px-4 d-flex justify-content-center align-items-center hover:tw-bg-slate-50">
            <img class="mr-3" src="assets/images/phar-icon.svg" width="80">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="tw-text-[#D00000] tw-font-extrabold" style="font-family: Poppins;">Pharmacies</h6>
              <p class="text-dark text-wrap font-size-13 mb-0" style="font-family: Poppins;">
                Trouvez la listes des pharmacies agrées par le MSAS et d'autres informations utiles telles que celles
                qui sont de garde.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="w-100 tw-absolute d-flex" style="bottom: 0px">
      <div class="tw-bg-green-600 tw-w-6/12 tw-h-1.5"></div>
      <div class="tw-bg-yellow-300 tw-w-6/12 tw-h-1.5"></div>
      <div class="tw-bg-[#D00000] tw-w-6/12 tw-h-1.5"></div>
    </div>

  </div>
</section>

<section id="why" class="mt-5">
  <div class="container-fluid d-flex flex-column px-0">

    <h3 class="mb-0 text-dark" style="font-family: Poppins;">Pourquoi choisir le Dossier Patient Partagé ?</h3>

    <div class="d-flex flex-column">
      <div class="row mx-0 mt-4">
        <div class="col-md-5 pt-card"></div>
        <div class="col-md-7 bg-white tw-h-[22rem] d-flex flex-column px-5">

          <div class="tw-bg-[#D00000] tw-h-11 tw-absolute red-banner d-flex align-items-center tw-pl-10">
            <h4 class="mb-0 text-white" style="font-family: Poppins;">Vous êtes un patient ?</h4>
          </div>

          <div class="d-flex flex-column tw-mt-28">

            <div class="d-flex align-items-center">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Ne perdez plus de temps dans votre parcours de
                soins</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Ayez votre historique médical en tout temps et
                tout lieu</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Aidez votre médecin dans sa prise de
                décision</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Géolocalisez un professionnel de santé</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Améliorez votre suivi médical</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Faire mes démarches santé en ligne</span>
            </div>

          </div>

        </div>
      </div>
      <div class="w-100 tw-h-6 tw-bg-gradient-to-r tw-from-transparent tw-to-black"></div>
    </div>

    <div class="d-flex flex-column mt-4">
      <div class="row mx-0 mt-4">
        <div class="col-md-5 pr-card px-0"></div>
        <div class="col-md-7 bg-white tw-h-[22rem] d-flex flex-column px-5">

          <div class="tw-bg-[#D00000] tw-h-11 tw-absolute red-banner d-flex align-items-center tw-pl-10">
            <h4 class="mb-0 text-white" style="font-family: Poppins;">Vous êtes un professionnel ?</h4>
          </div>

          <div class="d-flex flex-column tw-mt-28">

            <div class="d-flex align-items-center">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Accéder à l'historique médical de votre
                patient</span>
            </div>
            <div class="d-flex align-items-start mt-2">
              <img class="mr-3 mt-1" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Disposer des meilleures informations pour une
                prise de décision optimale</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Editer le dossier patient</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <img class="mr-3" src="assets/icons/dot.svg" width="10">
              <span class="font-size-14" style="font-family: Poppins;">Faciliter le parcours de soins de votre
                patient</span>
            </div>

          </div>

        </div>
      </div>
      <div class="w-100 tw-h-6 tw-bg-gradient-to-r tw-from-black"></div>
    </div>

    <img class="mt-5 mb-5" src="assets/images/sn-flag.svg" height="100">

  </div>
</section>