import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { UiModule } from "../shared/ui/ui.module";
import { WidgetModule } from "../shared/widget/widget.module";

import { PagesRoutingModule } from "./pages-routing.module";

import {
  NgbNavModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbModalModule,
  NgbAlertModule,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { AccueilComponent } from "./accueil/accueil.component";
import { ListeComponent } from "./professionnels/liste/liste.component";
import { DetailsProfessionnelComponent } from "./professionnels/details/details-professionnel.component";
import { SearchDoctorsComponent } from "./search-doctors/search-doctors.component";
import { StructuresListComponent } from "./structures/structures-list/structures-list.component";
import { StructureDetailsComponent } from "./structures/structure-details/structure-details.component";
import { PharmaciesListComponent } from "./pharmacies/pharmacies-list/pharmacies-list.component";
import { PharmacyDetailsComponent } from "./pharmacies/pharmacy-details/pharmacy-details.component";
import { MatOptionModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { OrderModule } from "ngx-order-pipe";
import { TrimSlotsPipe } from "../core/pipes/trim-slots.pipe";
import { AgmCoreModule } from "@agm/core";
import { BookSlotComponent } from "./book-slot/book-slot.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { CguComponent } from "./cgu/cgu.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TranslateModule } from "@ngx-translate/core";
import { TempComponent } from "./temp/temp.component";
import { SharedModule } from "../layouts/shared/shared.module";
import { InscriptionComponent } from "./inscription/inscription.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3,
};

@NgModule({
  declarations: [
    AccueilComponent,
    ListeComponent,
    DetailsProfessionnelComponent,
    SearchDoctorsComponent,
    StructuresListComponent,
    StructureDetailsComponent,
    PharmaciesListComponent,
    PharmacyDetailsComponent,
    TrimSlotsPipe,
    BookSlotComponent,
    ContactUsComponent,
    CguComponent,
    TempComponent,
    InscriptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    WidgetModule,
    NgbTypeaheadModule,
    MatOptionModule,
    MatAutocompleteModule,
    OrderModule,
    NgbModalModule,
    NgbAlertModule,
    NgbTooltipModule,
    NgxIntlTelInputModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyA7FVfOFzYA2nPvANt36rQkUYpU80wz6KQ",
      libraries: ["geometry"],
    }),
    TranslateModule,
    SharedModule,
    NgbDatepickerModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  entryComponents: [],
})
export class PagesModule {}
