import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { AppointmentsService } from "src/app/core/services/appointments/appointments.service";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-book-slot",
  templateUrl: "./book-slot.component.html",
  styleUrls: ["./book-slot.component.scss"],
})
export class BookSlotComponent implements OnInit {
  @Input() professional;
  @Input() slot;
  host: any;
  bookingForm: FormGroup;
  submitted = false;
  error = "";
  successmsg = false;
  selectedMotif: any;
  selectedPlace: any;
  beneficiary: any;
  place: any;
  proches = [];
  startDate;
  endDate;

  constructor(
    private appt: AppointmentsService,
    private formBuilder: FormBuilder,
    private usr: UserProfileService,
    private loader: NgxUiLoaderService,
    private h: HelpersService,
    private modal: NgbModal
  ) {
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    this.usr.getPassportUserRelatives(patientExternalId).subscribe((res) => {
      if (res.length > 0) {
        this.proches = res;
      } else {
        this.beneficiary = sessionStorage.getItem("patientExternalId");
      }
    });
  }

  ngOnInit(): void {
    let timeslotdate = new Date(this.slot.timeslotdate)
      .toLocaleString()
      .substring(0, 10);
    this.startDate = `${timeslotdate} ${this.slot?.starthour.substring(0, 5)}`;
    this.endDate = `${timeslotdate} ${this.slot?.endhour.substring(0, 5)}`;
    this.bookingForm = this.formBuilder.group({
      motif: ["", Validators.required],
      place: ["", Validators.required],
      patient: ["", Validators.required],
    });

    if (this.professional?.structures?.length === 1) {
      this.selectedPlace = this.professional?.structures[0];
    }
  }

  onServiceChange(motif) {
    this.selectedMotif = motif;
  }

  onSelectBeneficiary(beneficiary) {
    this.beneficiary = beneficiary;
  }

  onSelectPlace(place) {
    this.selectedPlace = place;
  }

  book() {
    this.submitted = true;
    if (!this.selectedMotif || !this.selectedPlace || !this.beneficiary) {
      return;
    } else {
      let slot = {
        motif: this.selectedMotif.label,
        motifExternalId: this.selectedMotif.eyoneexternalid,
        startDate: this.startDate,
        endDate: this.endDate,
        doctorName: `${this.professional.firstname} ${this.professional.lastname}`,
        doctorEyoneExternalId: this.professional.eyoneexternalid,
        patientBookerExternalId: sessionStorage.getItem("patientExternalId"),
        timeSlotExternalId: this.slot.eyoneexternalid,
      };

      if (this.proches.length === 0) {
        slot["patientBookedExternalId"] =
          sessionStorage.getItem("patientExternalId");
      } else if (this.proches.length !== 0) {
        slot["patientBookedExternalId"] =
          this.beneficiary === "moi"
            ? sessionStorage.getItem("patientExternalId")
            : this.beneficiary;
      }

      if (this.professional.structures.length !== 0) {
        slot["medicalStructureName"] =
          this.professional.structures.length > 1
            ? this.selectedPlace.name
            : this.professional.structures[0].name;
        slot["medicalStructureEyoneExternalId"] =
          this.professional.structures.length > 1
            ? this.selectedPlace.eyoneexternalid
            : this.professional.structures[0].eyoneexternalid;
      }

      // console.warn(slot);

      this.loader.start();
      this.appt
        .bookAppointment(slot)
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.modal.dismissAll();
          Swal.fire({
            icon: "success",
            text: "Demande de rendez-vous enregistrée avec succès, une confirmation vous sera notifiée.",
            showConfirmButton: false,
            timer: 5000,
          }).then((_) => window.location.reload());
        })
        .catch((e) => {
          this.loader.stop();
          if (e.error.errorCode === "EXPIRED_TOKEN") {
            this.loader.start();
            this.h.handleExpiredToken().then((_) => {
              this.appt
                .bookAppointment(slot)
                .pipe(timeout(30000))
                .toPromise()
                .then((_) => {
                  this.loader.stop();
                  this.modal.dismissAll();
                  Swal.fire({
                    icon: "success",
                    text: "Demande de rendez-vous enregistrée avec succès, une confirmation vous sera notifiée.",
                    showConfirmButton: false,
                    timer: 5000,
                  }).then((_) => window.location.reload());
                })
                .catch((e) => {
                  this.loader.stop();
                  this.h.handleRequestError(e);
                });
            });
          } else {
            this.h.handleRequestError(e);
          }
        });
    }
  }
}
