<app-dpp-sn-topbar></app-dpp-sn-topbar>

<div *ngIf="!pharmacy" class="container-fluid tw-px-6">
    <p>Aucune pharmacie avec l'ID <span class="font-weight-bold text-dark text-lg">{{ id }}</span>.</p>
</div>

<section *ngIf="pharmacy" class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h6 class="mb-0 text-white text-uppercase" style="font-family: Poppins;">
                    Dossier Patient Partagé
                </h6>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Portail Public</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);"
                            routerLink="/pharmacies">
                            Pharmacies</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Détails</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div *ngIf="pharmacy" class="container-fluid">
    <div class="row mx-0">
        <div class="col-12 tw-relative d-flex flex-column justify-content-center align-items-center">
            <div class="tw-absolute tw-h-40 p-4 bg-white w-100" style="top: -35px;">
                <div class="tw-w-full tw-flex tw-content-start tw-items-center">
                    <!-- Avatar -->
                    <div *ngIf="pharmacy?.photos?.length == 0"
                        class="tw-h-28 tw-w-28 tw-cursor-pointer tw-mr-4 tw-bg-slate-300 tw-rounded tw-flex tw-content-center tw-items-center">
                        <img class="tw-rounded tw-h-28 tw-w-28" src="assets/images/users/placeholder.png" alt="avatar">
                    </div>
                    <div *ngIf="pharmacy?.photos?.length != 0"
                        class="tw-h-28 tw-w-28 tw-cursor-pointer tw-mr-4 tw-bg-slate-300 tw-rounded tw-flex tw-content-center tw-items-center">
                        <img class="tw-rounded tw-h-28 tw-w-28" src="{{ pharmacy?.photos[0]?.url }}" alt="avatar">
                    </div>
                    <!-- Personal infos -->
                    <div class="w-100 tw-flex tw-flex-col tw-content-between tw-items-start">
                        <!-- Name -->
                        <p class="tw-m-0 tw-text-slate-800 tw-text-md tw-font-bold">{{ pharmacy?.name }} </p>

                        <!-- Other details -->
                        <div class="w-100 tw-flex tw-flex-col tw-content-start tw-items-start tw-mt-2">
                            <!-- Languages and mail -->
                            <div class="tw-w-3/12 tw-flex tw-flex-col tw-content-start tw-items-start">
                                <!-- <div *ngIf="pharmacy?.languages.length != 0" class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-slate-800 tw-text-md tw-font-bold">Langues: </span>
                                    <div *ngFor="let lang of pharmacy?.languages"> <span class="tw-mr-3 tw-text-xs">{{
                                            lang?.label
                                            }}</span></div>
                                </div> -->
                                <div *ngIf="pharmacy?.coordonate?.email"
                                    class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-xs"><i class="ri-mail-line"></i></span>
                                    <div> <span class="tw-mr-2 tw-text-xs">{{ pharmacy?.coordonate?.email
                                            }}</span></div>
                                </div>
                            </div>
                            <!-- Phone numbers -->
                            <div *ngIf="pharmacy?.coordonate?.mobilenumber || pharmacy?.coordonate?.fixnumber"
                                class="tw-w-3/12">
                                <div class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-xs"><i class="ri-phone-line"></i></span>
                                    <div> <span class="tw-mr-4 tw-text-xs">{{ pharmacy?.coordonate?.mobilenumber
                                            }}</span> <span class="tw-text-xs">{{
                                            pharmacy?.coordonate?.fixnumber
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Details section -->
<div *ngIf="!pharmacy" class="tw-h-screen"></div>
<section *ngIf="pharmacy" class="tw-mt-[9rem]">
    <div class="container-fluid mb-5">
        <div
            class="tw-flex tw-content-start tw-items-start tw-flex-col lg:tw-flex-row lg:tw-content-start lg:tw-items-start">
            <div class="w-100">
                <!-- First block of infos -->
                <div class="tw-flex tw-flex-col tw-bg-white tw-mt-4 tw-pb-5">
                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-refund-2-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">Organismes de
                                remboursement
                                acceptés</p>
                        </div>
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-money-dollar-box-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">Moyens de
                                paiement
                                acceptés</p>
                        </div>
                    </div>

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="pharmacy?.insurers?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">Aucune information</span>
                                </div>
                                <div *ngIf="pharmacy?.insurers?.length != 0">
                                    <div *ngFor="let ass of pharmacy?.insurers"> <span class="tw-mr-6 tw-text-xs">{{
                                            ass?.name
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="pharmacy?.payments?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">Aucune information</span>
                                </div>
                                <div *ngIf="pharmacy?.payments?.length != 0">
                                    <div *ngFor="let payment of pharmacy?.payments"> <span class="tw-mr-6 tw-text-xs">{{
                                            payment?.name
                                            }}</span></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Second block of infos -->
                <div class="tw-flex tw-flex-col tw-bg-white tw-mt-4 tw-pb-5">

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-refund-2-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">Adresse et
                                localisation</p>
                        </div>
                        <div class="tw-w-6/12"></div>
                    </div>

                    <div class="tw-flex tw-content-between tw-items-start tw-px-5">

                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="!pharmacy?.coordonate?.globaladressfield">
                                    <span class="tw-mr-6 tw-text-xs">Aucune information</span>
                                </div>
                                <div *ngIf="pharmacy?.coordonate?.globaladressfield">
                                    <span class="tw-mr-4 tw-text-xs">{{ pharmacy?.coordonate?.globaladressfield }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="tw-w-6/12 tw-flex tw-content-center tw-items-center tw-bg-slate-100 sm:tw-hidden lg:tw-inline lg:tw-w-6/12 lg:tw-flex lg:tw-content-center lg:tw-items-center lg:tw-bg-slate-100"
                            style="height: 200px; border-radius: 8px; overflow: hidden;">
                            <agm-map [latitude]="pharmacy?.coordonate?.latitude"
                                [longitude]="pharmacy?.coordonate?.longitude" [zoom]="zoom" [disableDefaultUI]="false"
                                [zoomControl]="false"
                                style="width: 100%; height: 100%; border-radius: 8px; overflow: hidden;">

                                <agm-marker [iconUrl]="iconUrl" [latitude]="pharmacy?.coordonate?.latitude"
                                    [longitude]="pharmacy?.coordonate?.longitude" [markerDraggable]="false">
                                    <agm-info-window>
                                        <span class="fw-bold">{{ pharmacy?.name }}</span>
                                    </agm-info-window>
                                </agm-marker>

                            </agm-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>