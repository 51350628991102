import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AutocompleteService } from "src/app/core/services/autocomplete.service";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { environment } from "src/environments/environment";
import { debounceTime, distinctUntilChanged, timeout } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/core/services/authentification.service";
import { UserProfileService } from "src/app/core/services/user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from "sweetalert2";
import { ConfirmLoginValidator } from "src/app/core/services/confirm-login.validator";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-liste",
  templateUrl: "./liste.component.html",
  styleUrls: ["./liste.component.scss"],
})
export class ListeComponent implements OnInit, AfterViewInit {
  breadCrumbItems: Array<{}>;
  term: any;
  zoom: number = 15;
  iconUrl: {
    scaledSize: {
      width: 50;
      height: 50;
    };
  };
  user: any;
  response;
  query = {};
  params = {};
  error = "";
  submitted = false;
  successmsg = false;
  selectedService = "Filtrer par service (effacer le filtre)";
  selectedInsurer = "Filtrer par assurance (effacer le filtre)";
  selectedLang = "Filtrer par langue (effacer le filtre)";

  retrievedUser: any;

  login = true;
  signup = false;
  reset = false;
  verif = false;
  firstConnect = false;

  places: [] = [];
  specialities: [] = [];
  professionals: [] = [];
  structures: [] = [];
  pharmacies: [] = [];
  services: [] = [];
  insurers: [] = [];
  languages: [] = [];

  specialityIsSelected = false;

  placeForm: FormGroup;
  termForm: FormGroup;

  loginForm: FormGroup;
  signupForm: FormGroup;
  activationForm: FormGroup;
  resetForm: FormGroup;
  pwdForm: FormGroup;

  closed$ = new Subject<void>();
  fakeList = Array(4)
    .fill(1, 4)
    .map((x, i) => i);
  patientExternalId: any;
  selectedProfessional: any;
  selectedSlot: any;
  positionIsActivated: boolean;
  token: any;
  refresh: any;
  connectedUser: any;
  initiated = false;
  place;
  specialite;
  placeLoading = false;
  termLoading = false;

  latitude = "null";
  longitude = "null";

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Senegal,
    CountryISO.CôteDIvoire,
    CountryISO.Cameroon,
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aC: AutocompleteService,
    public h: HelpersService,
    public formBuilder: FormBuilder,
    private modal: NgbModal,
    public auth: AuthService,
    private userService: UserProfileService,
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private translate: TranslateService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngAfterViewInit(): void {
    this.initiated = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      const place = params.get("place");
      const speciality = params.get("speciality");

      if (place !== null) {
        this.placeForm.patchValue({ name: place });
      }
      if (speciality !== null) {
        this.termForm.patchValue({ term: speciality });
      }
    });
  }

  ngOnInit() {
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.getTranslations();
    // });
    // this.getTranslations();
    window.scroll(0, 0);

    let token = sessionStorage.getItem("publicToken");
    if (!token) {
      this.auth
        .getPublicJwtToken("francis.ngom@eyone.net", "passe")
        .then((_) => this.ngOnInit());
    } else {
      this.patientExternalId = sessionStorage.getItem("patientExternalId");
      this.breadCrumbItems = [
        { label: environment.appName },
        { label: "Professionnels", active: true },
      ];
      // Forms
      this.placeForm = this.formBuilder.group({
        name: ["", [Validators.nullValidator]],
      });
      this.termForm = this.formBuilder.group({
        term: ["", [Validators.nullValidator]],
      });
      this.loginForm = this.formBuilder.group({
        login: ["", [Validators.required]],
        password: ["", [Validators.required]],
      });
      this.signupForm = this.formBuilder.group({
        prenom: ["", Validators.required],
        nom: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        tel: ["", Validators.required],
        addresse: ["", Validators.required],
        password: ["", Validators.required],
        sexe: ["", Validators.required],
      });
      this.activationForm = this.formBuilder.group({
        code: ["", [Validators.nullValidator]],
      });
      this.resetForm = this.formBuilder.group({
        reset: ["", [Validators.nullValidator]],
        email: ["", [Validators.nullValidator]],
        tel: ["", [Validators.nullValidator]],
      });
      this.pwdForm = this.formBuilder.group(
        {
          oldPwd: ["", [Validators.required]],
          newPwd: ["", [Validators.required]],
          confirmNewPwd: ["", [Validators.required]],
        },
        {
          validator: ConfirmLoginValidator("newPwd", "confirmNewPwd"),
        }
      );

      this.loader.start();
      this.route.queryParams.subscribe(async (params) => {
        const page = params["page"];
        const service = params["service"];
        const assurance = params["assurance"];
        const langue = params["langue"];

        if (page !== undefined) {
          let token = sessionStorage.getItem("publicToken");
          let header = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          if (sessionStorage.getItem("position") === "true") {
            if (this.initiated) this.loader.start();
            navigator.geolocation.getCurrentPosition((pos) => {
              this.latitude = pos.coords.latitude.toString();
              this.longitude = pos.coords.longitude.toString();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.queryDoctors +
                    `?latitude=${this.latitude}&longitude=${this.longitude}&page=${page}`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = this.h.generateAvailabilities(r);
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            });
          } else {
            if (this.initiated) this.loader.start();
            this.http
              .get<any>(
                environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=null&longitude=null&page=${page}`,
                { headers: header }
              )
              .pipe(timeout(30000))
              .toPromise()
              .then((r) => {
                this.response = this.h.generateAvailabilities(r);
                this.loader.stop();
              })
              .catch((e) => {
                this.h.handleRefRequestError(e);
                this.loader.stop();
              });
          }
        } else if (service || assurance || langue) {
          let token = sessionStorage.getItem("publicToken");
          let header = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          let path;
          if (sessionStorage.getItem("position") === "true") {
            if (this.initiated) this.loader.start();
            navigator.geolocation.getCurrentPosition((pos) => {
              this.latitude = pos.coords.latitude.toString();
              this.longitude = pos.coords.longitude.toString();
              if (!service && !langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}`;
              }
              if (service && !langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}`;
              }
              if (!service && langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&langue=${langue}`;
              }
              if (!service && !langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&assurance=${assurance}`;
              }
              if (service && langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}&langue=${langue}`;
              }
              if (service && !langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}&assurance=${assurance}`;
              }
              if (!service && langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryDoctors +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&langue=${langue}&assurance=${assurance}`;
              }
              this.http
                .get<any>(path, { headers: header })
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = this.h.generateAvailabilities(r);
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }),
              (e) => console.error(e);
          } else {
            path = this.h.professionalsSalQueryPath(service, assurance, langue);
            if (this.initiated) this.loader.start();
            this.http
              .get<any>(path, { headers: header })
              .pipe(timeout(30000))
              .toPromise()
              .then((r) => {
                this.response = this.h.generateAvailabilities(r);
                this.loader.stop();
              })
              .catch((e) => {
                this.h.handleRefRequestError(e);
                this.loader.stop();
              });
          }
        } else {
          if (
            !this.route.snapshot.params.place &&
            this.route.snapshot.params.speciality
          ) {
            let token = sessionStorage.getItem("publicToken");
            let header = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            });
            let speciality = this.route.snapshot.params.speciality as string;
            speciality = this.h.removeAccent(speciality);
            if (sessionStorage.getItem("position") === "true") {
              if (this.initiated) this.loader.start();
              navigator.geolocation.getCurrentPosition((pos) => {
                this.latitude = pos.coords.latitude.toString();
                this.longitude = pos.coords.longitude.toString();
                this.http
                  .get<any>(
                    environment.referentiel +
                      environment.searchDoctors +
                      "specialite/" +
                      speciality +
                      `?latitude=${this.latitude}&longitude=${this.longitude}`,
                    { headers: header }
                  )
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((r) => {
                    this.response = this.h.generateAvailabilities(r);
                    this.loader.stop();
                  })
                  .catch((e) => {
                    this.h.handleRefRequestError(e);
                    this.loader.stop();
                  });
              }),
                (e) => console.error(e);
            } else {
              if (this.initiated) this.loader.start();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.searchDoctors +
                    "specialite/" +
                    speciality +
                    `?latitude=null&longitude=null`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = this.h.generateAvailabilities(r);
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }
          } else if (
            this.route.snapshot.params.place &&
            !this.route.snapshot.params.speciality
          ) {
            let token = sessionStorage.getItem("publicToken");
            let header = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            });
            let place = this.route.snapshot.params.place as string;
            place = this.h.removeAccent(place);
            if (sessionStorage.getItem("position") === "true") {
              if (this.initiated) this.loader.start();
              navigator.geolocation.getCurrentPosition((pos) => {
                this.latitude = pos.coords.latitude.toString();
                this.longitude = pos.coords.longitude.toString();
                this.http
                  .get<any>(
                    environment.referentiel +
                      environment.searchDoctors +
                      "lieu/" +
                      place +
                      `?latitude=${this.latitude}&longitude=${this.longitude}`,
                    { headers: header }
                  )
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((r) => {
                    this.response = this.h.generateAvailabilities(r);
                    this.loader.stop();
                  })
                  .catch((e) => {
                    this.h.handleRefRequestError(e);
                    this.loader.stop();
                  });
              }),
                (e) => console.error(e);
            } else {
              if (this.initiated) this.loader.start();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.searchDoctors +
                    "lieu/" +
                    place +
                    `?latitude=null&longitude=null`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = this.h.generateAvailabilities(r);
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }
          } else if (
            this.route.snapshot.params.place &&
            this.route.snapshot.params.speciality
          ) {
            let token = sessionStorage.getItem("publicToken");
            let header = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            });
            let place = this.route.snapshot.params.place as string;
            let speciality = this.route.snapshot.params.speciality as string;
            place = this.h.removeAccent(place);
            speciality = this.h.removeAccent(speciality);
            if (sessionStorage.getItem("position") === "true") {
              if (this.initiated) this.loader.start();
              navigator.geolocation.getCurrentPosition((pos) => {
                this.latitude = pos.coords.latitude.toString();
                this.longitude = pos.coords.longitude.toString();
                this.http
                  .get<any>(
                    environment.referentiel +
                      environment.searchDoctors +
                      `${speciality}/${place}?latitude=${this.latitude}&longitude=${this.longitude}`,
                    { headers: header }
                  )
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((r) => {
                    this.response = this.h.generateAvailabilities(r);
                    this.loader.stop();
                  })
                  .catch((e) => {
                    this.h.handleRefRequestError(e);
                    this.loader.stop();
                  });
              }),
                (e) => console.error(e);
            } else {
              if (this.initiated) this.loader.start();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.searchDoctors +
                    `${speciality}/${place}?latitude=null&longitude=null`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.loader.stop();
                  this.response = this.h.generateAvailabilities(r);
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }
          } else {
            let token = sessionStorage.getItem("publicToken");
            let header = new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            });
            if (sessionStorage.getItem("position") === "true") {
              if (this.initiated) this.loader.start();
              navigator.geolocation.getCurrentPosition((pos) => {
                this.latitude = pos.coords.latitude.toString();
                this.longitude = pos.coords.longitude.toString();
                this.http
                  .get<any>(
                    environment.referentiel +
                      environment.queryDoctors +
                      `?latitude=${this.latitude}&longitude=${this.longitude}`,
                    { headers: header }
                  )
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((r) => {
                    this.response = this.h.generateAvailabilities(r);
                    this.loader.stop();
                  })
                  .catch((e) => {
                    this.loader.stop();
                    this.h.handleRefRequestError(e);
                  });
              }),
                (e) => console.error(e);
            } else {
              if (this.initiated) this.loader.start();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.queryDoctors +
                    `?latitude=null&longitude=null`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = this.h.generateAvailabilities(r);
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }
          }
        }
      });

      // Filters data
      this.getAllServices();
      this.getAllInsurers();
      this.getAllLanguages();
    }

    this.searchPlaceEventListener();
    this.searchTermEventListener();
  }

  get log() {
    return this.loginForm.controls;
  }
  get sig() {
    return this.signupForm.controls;
  }
  get v() {
    return this.activationForm.controls;
  }
  get p() {
    return this.pwdForm.controls;
  }

  getTranslations() {
    this.translate.get("SHARED.FILTER_BY_SERVICE").subscribe((text: string) => {
      this.selectedService = text;
    });
    this.translate.get("SHARED.FILTER_BY_INSURER").subscribe((text: string) => {
      this.selectedInsurer = text;
    });
    this.translate.get("SHARED.FILTER_BY_LANG").subscribe((text: string) => {
      this.selectedLang = text;
    });
  }

  searchPlaceEventListener() {
    this.placeForm
      .get("name")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.place !== this.placeForm.value.name) {
          this.placeLoading = true;
          this.aC
            .placeautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.placeLoading = false;
              this.places = res;
            })
            .catch((e) => {
              this.placeLoading = false;
              this.places = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.place = "";
          this.places = [];
        }
      });
  }

  searchTermEventListener() {
    this.termForm
      .get("term")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.specialite !== this.termForm.value.term) {
          this.termLoading = true;
          this.aC
            .termautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.termLoading = false;
              this.specialities = res.specialites;
              this.structures = res.structures;
              this.professionals = res.docteurs;
              this.pharmacies = res.pharmacies;
            })
            .catch((e) => {
              this.termLoading = false;
              this.specialities = [];
              this.structures = [];
              this.professionals = [];
              this.pharmacies = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.specialite = "";
          this.specialities = [];
          this.structures = [];
          this.professionals = [];
          this.pharmacies = [];
        }
      });
  }

  voirPlus(data) {
    var lengths = [];
    data.isCollapsed = true;
    data.availabilities.forEach((e) => {
      if (e.slots.length != 0) {
        lengths.push(e.slots.length);
      }
    });
    var len = Math.max(...lengths);
    data.rowNumber = Array(len)
      .fill(1, 4)
      .map((x, i) => i);
    this.fakeList = Array(len)
      .fill(1, 4)
      .map((x, i) => i);
  }
  voirMoins(data) {
    data.isCollapsed = false;
    data.rowNumber = Array(4)
      .fill(1, 4)
      .map((x, i) => i);
    this.fakeList = Array(4)
      .fill(1, 4)
      .map((x, i) => i);
  }

  navigateLeft(professional) {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    this.loader.start();
    this.http
      .get<any[]>(
        environment.referentiel +
          environment.getTimeSlots +
          `?id=${professional.id}&start_date=${professional.previous_range_start}&limit=5`,
        { headers: header }
      )
      .subscribe((res) => {
        this.loader.stop();
        this.response = this.h.generatePreviousAvailabilities(
          res,
          this.response,
          professional
        );
      });
  }

  navigateRight(professional) {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    this.loader.start();
    try {
      this.http
        .get<any[]>(
          environment.referentiel +
            environment.getTimeSlots +
            `?id=${professional.id}&start_date=${professional.next_range_start}&limit=5`,
          { headers: header }
        )
        .subscribe((res) => {
          this.loader.stop();
          this.response = this.h.generateNextAvailabilities(
            res,
            this.response,
            professional
          );
        });
    } catch (error) {
      Swal.fire(
        "Erreur",
        "Une erreur est survenue lors du chargement des données",
        "error"
      );
    }
  }

  previousPage() {
    if (this.response.current_page == 2) {
      this.router.navigate(["/professionnels"]);
    } else {
      this.router.navigate(["/professionnels"], {
        queryParams: { page: this.response.current_page - 1 },
      });
    }
    window.scroll(0, 0);
  }

  nextPage() {
    this.router.navigate(["/professionnels"], {
      queryParams: { page: this.response.current_page + 1 },
    });
    window.scroll(0, 0);
  }

  onClickSearchBtn() {
    if (
      !this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      this.router.navigate(["/professionnels"]);
    } else if (
      this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      let place = this.placeForm?.get("name").value as string;
      place = this.h.removeSpaceAndAccent(place);
      this.router.navigate([`/professionnels/pos/${place}`]);
    } else if (
      this.termForm?.get("term").value &&
      !this.placeForm?.get("name").value
    ) {
      let speciality = this.termForm.get("term").value as string;
      speciality = this.h.removeSpaceAndAccent(speciality);
      this.router.navigate([`/professionnels/speciality/${speciality}`]);
    } else if (
      this.termForm?.get("term").value &&
      this.placeForm?.get("name").value
    ) {
      if (this.specialityIsSelected) {
        let place = this.placeForm.get("name").value as string;
        let speciality = this.termForm.get("term").value as string;
        place = this.h.removeSpaceAndAccent(place);
        speciality = this.h.removeSpaceAndAccent(speciality);
        this.router.navigate([`/professionnels/${speciality}/${place}`]);
      }
    }
  }

  getAllServices() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}services`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.services = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }
  getAllInsurers() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}insurers`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.insurers = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }
  getAllLanguages() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}languages`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.languages = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }

  onSelectSpecialite(specialite) {
    this.specialite = specialite;
    this.specialityIsSelected = true;
    this.termForm.patchValue({ term: specialite });
  }

  onSelectStructure(structure) {
    let id = structure.id;
    this.router.navigate([`/details-structure/${id}`]);
  }
  onSelectProfessional(professional) {
    let id = professional.id;
    this.router.navigate([`/details-professionnel/${id}`]);
  }
  onSelectPharmacy(pharmacy) {
    let id = pharmacy.id;
    this.router.navigate([`/details-pharmacie/${id}`]);
  }

  onSelectPlace(place) {
    this.place = place;
    this.placeForm.patchValue({ name: place });
  }

  handleClosed(event) {
    this.closed$.next();
  }

  removeQuery(from) {
    delete this.params[from];
    this.router.navigate(["/professionnels"], { queryParams: this.params });
  }

  addQuery(to, query) {
    query = this.h.removeSpaceAndAccent(query);
    this.params[to] = query;
    this.query[to] = query;
    this.router.navigate(["/professionnels"], { queryParams: this.params });
  }

  onServiceChange(val) {
    switch (val) {
      case "Filtrer par service (effacer le filtre)":
        this.removeQuery("service");
        break;
      case "Filter by service (clear filter)":
        this.removeQuery("service");
        break;
      default:
        this.addQuery("service", val);
        break;
    }
  }

  onInsurerChange(val) {
    switch (val) {
      case "Filtrer par assurance (effacer le filtre)":
        this.removeQuery("assurance");
        break;
      case "Filter by insurer (clear filter)":
        this.removeQuery("assurance");
        break;
      default:
        this.addQuery("assurance", val);
        break;
    }
  }

  onLangChange(val) {
    switch (val) {
      case "Filtrer par langue (effacer le filtre)":
        this.removeQuery("langue");
        break;
      case "Filter by language (clear filter)":
        this.removeQuery("langue");
        break;
      default:
        this.addQuery("langue", val);
        break;
    }
  }

  onSelectSlot(authModal, bookingModal, professional, slot) {
    this.selectedProfessional = professional;
    this.selectedSlot = slot;
    if (this.patientExternalId) {
      this.openSlotBookingModal(bookingModal);
    } else {
      Swal.fire({
        text: "Vous devez vous identifier pour prendre rendez-vous.",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 4000,
      });
      this.openAuthModal(authModal);
    }
  }

  openAuthModal(modal) {
    this.modal.open(modal, {
      size: "md",
      centered: true,
    });
  }
  openSlotBookingModal(modal) {
    this.modal.open(modal, {
      size: "md",
      scrollable: true,
    });
  }

  onSubmit() {
    this.error = "";
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      let num = this.log.phone.value.e164Number as string;
      num = num.replace("+", "00");
      this.loader.start();
      this.auth
        .login(num, this.log.password.value)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.userService
            .getPatientByUsername(r.userName, r["access_token"])
            .pipe(timeout(30000))
            .toPromise()
            .then((p) => {
              this.connectedUser = p;
              this.token = r["access_token"];
              this.refresh = r["refresh_token"];
              if (p.passeportCreationStatusLabel == "A valider par l'usager") {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.verif = true;
                this.submitted = false;
                this.connectedUser = p;
              } else if (
                p.passeportCreationStatusLabel ==
                "A valider par l'usager après création par un tier"
              ) {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.submitted = false;
                this.firstConnect = true;
                this.connectedUser = p;
              } else if (p.passeportCreationStatusLabel == "Actif") {
                sessionStorage.setItem("passportToken", r["access_token"]);
                sessionStorage.setItem(
                  "refreshPassportToken",
                  r["refresh_token"]
                );
                sessionStorage.setItem("login", p["login"]);
                sessionStorage.setItem("firstName", p["firstName"]);
                sessionStorage.setItem("lastName", p["lastName"]);
                sessionStorage.setItem(
                  "passportType",
                  p["passeportManagementType"]
                );
                sessionStorage.setItem(
                  "patientExternalId",
                  p["eyoneExternalId"]
                );
                this.modal.dismissAll();
                window.location.reload();
              }
            })
            .catch((e) => {
              this.loader.stop();
              this.h.handleRefRequestError(e);
            });
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRefRequestError(e);
        });
    }
  }

  onSubmitRegister() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    } else {
      this.loader.start();
      let user = {
        password: this.signupForm.value.password,
        firstName: this.signupForm.value.prenom,
        lastName: this.signupForm.value.nom,
        sex: this.signupForm.value.sexe,
        mobileNumber: "00221" + this.signupForm.value.tel,
        mail: this.signupForm.value.email,
        birthday: null,
        countryCode: "SN",
        regionCode: "DKR",
        adress: this.signupForm.value.addresse,
        fixeNumber: null,
        base64Photo: null,
        photoExtension: null,
        passeportCreationSource: "PASSEPORT_WEB",
        passeportCreationStatus: "TO_BE_ACTIVATED",
        passeportManagementType: "MAIN",
        passeportOrganismCreator: "Postman",
        passeportUserCreator: "Henri",
        creationValidationMode: "SMS",
        passeportCreationDate: null,
        creationValidationDate: null,
        mainEyoneExternalId: null,
      };
      this.userService
        .register(user)
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.successmsg = true;
          this.login = true;
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRefRequestError(e);
        });
    }
  }

  switchToReset() {
    this.error = "";
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelReset() {
    this.error = "";
    this.resetForm.reset();
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelVerif() {
    this.error = "";
    this.submitted = false;
    this.loginForm.reset();
    this.login = !this.login;
    this.verif = !this.verif;
  }

  openVerification(verification) {
    this.modal.open(verification, { size: "sm" });
  }

  async resetPwd() {
    let body = {
      phoneNumber: "00221" + this.resetForm.value.tel,
      sendingChannel: "SMS",
      username: "00221" + this.resetForm.value.tel,
    };
    this.loader.start();
    await this.http
      .post<any>(
        `${environment.base}v1/registration/reInitPatientPassword`,
        body,
        {
          responseType: "text/plain;charset=ISO-8859-1" as "json",
        }
      )
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.modal.dismissAll();
        Swal.fire({
          title: "Succès",
          icon: "success",
          text:
            r + " Un SMS vous a été transmis sur votre numéro de téléphone.",
          showConfirmButton: false,
          timer: 6000,
        });
      })
      .catch((e) => {
        this.loader.stop();
        this.modal.dismissAll();
        this.h.handleRefRequestError(e);
      });
  }

  async verify() {
    this.submitted = true;
    if (this.activationForm.invalid) {
      return;
    } else {
      this.loader.start();
      let body = {
        codeIdentifier: this.connectedUser.mobileNumber,
        codeIdentifierType: "PHONE_NUMBER",
        codeType: "ACTIVATE_MAIN_ACCOUNT",
        inputCode: this.activationForm.value.code,
        // managedExternalId: this.connectedUser.eyoneExternalId,
      };
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", `Bearer ${this.token}`);
      await this.http
        .post<any>(`${environment.base}v1/codes-validation`, body, {
          headers: headers,
        })
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("login", this.connectedUser["login"]);
          sessionStorage.setItem("firstName", this.connectedUser["firstName"]);
          sessionStorage.setItem("lastName", this.connectedUser["lastName"]);
          sessionStorage.setItem(
            "passportToken",
            this.connectedUser["access_token"]
          );
          sessionStorage.setItem(
            "refreshPassportToken",
            this.connectedUser["refresh_token"]
          );
          sessionStorage.setItem(
            "patientExternalId",
            this.connectedUser["eyoneExternalId"]
          );
          sessionStorage.setItem(
            "passportType",
            this.connectedUser["passeportManagementType"]
          );
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  updatePassword() {
    this.submitted = true;
    let userName = sessionStorage.getItem("login");
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    if (patientExternalId !== undefined) {
    }
    if (this.pwdForm.invalid) {
      return;
    } else {
      let body = {
        userName: userName,
        eyoneExternalId: patientExternalId,
        newConfirmedPassWord: this.pwdForm.value.confirmNewPwd,
        newPassword: this.pwdForm.value.newPwd,
        oldPassword: this.pwdForm.value.oldPwd,
      };
      this.loader.start();
      this.userService
        .changePassword(body)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          Swal.fire({
            icon: "success",
            text: "Mot de passe modifié avec succès.",
            showConfirmButton: false,
            timer: 2000,
          }).then((_) => this.auth.logout());
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRefRequestError(e);
        });
    }
  }

  async changePassword() {
    this.submitted = true;
    if (this.pwdForm.invalid) {
      return;
    } else {
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", "Bearer " + this.token);
      let body = {
        eyoneExternalId: this.connectedUser.eyoneExternalId,
        password: this.pwdForm.value.newPwd,
      };
      this.loader.start();
      await this.http
        .put<any>(
          `${environment.base}api/rest/v1/patients/activation-by-password`,
          body,
          { headers: headers }
        )
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("passportToken", this.token);
          sessionStorage.setItem("refreshPassportToken", r["refresh_token"]);
          sessionStorage.setItem("login", r["login"]);
          sessionStorage.setItem("firstName", r["firstName"]);
          sessionStorage.setItem("lastName", r["lastName"]);
          sessionStorage.setItem("passportType", r["passeportManagementType"]);
          sessionStorage.setItem("patientExternalId", r["eyoneExternalId"]);
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  book() {}

  activatePosition() {
    this.h.getPosition();
  }
}
