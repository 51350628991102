export const environment = {
  production: false,
  appName: "Passeport Médical",
  appPasseport: "Votre passeport",
  oauthMs: "https://medicalsuite.eyone.net/passeport/auth/login",
  // referentiel: "http://php-referential-rct.j.layershift.co.uk/api/",
  referentiel: "https://referential-back.eyone.net/api/",
  ms: "https://medicalsuite.eyone.net/passeport/api/rest/",
  base: "https://medicalsuite.eyone.net/passeport/",
  searchDoctors: "search_doctors/",
  searchStructures: "search_structures/",
  searchPharmacies: "search_pharmacies/",
  queryDoctors: "search_doctors",
  queryStructures: "search_structures",
  queryPharmacies: "search_pharmacies",
  availabilities: "availabilities/",
  doctorDetails: "doctor_details/",
  structureDetails: "structure_details/",
  pharmacyDetails: "pharmacy_details/",
  getTimeSlots: "gettimeslots",
};
