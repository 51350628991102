<app-pagetitle title="Mon profil" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<div class="container-fluid tw-px-6">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Informations générales</a>
            <ng-template ngbNavContent>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header tw-bg-slate-50">
                                <div class="row justify-content-center">
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <img *ngIf="!imageAvailable" class="rounded-circle"
                                            src="assets/images/users/placeholder.png" alt="Avatar"
                                            style="height:80px;width:80px">
                                        <!-- <img *ngIf="imageAvailable" class="rounded-circle"
                                            [src]="domsanitizer.bypassSecurityTrustUrl(patient.content)" alt="Avatar"
                                            style="height:80px;width:80px"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <form class="form-horizontal" [formGroup]="userInfosForm">
                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                                    <!-- P & N -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="prenom">Prénom</label>
                                                <input readonly formControlName="prenom" class="form-control"
                                                    id="prenom"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.prenom.errors }">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="nom">Nom</label>
                                                <input readonly formControlName="nom" class="form-control" id="nom"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.nom.errors }">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Login -->
                                    <div *ngIf="!modifier" class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="m-0" for="login">Login / Téléphone</label>
                                                <input [readonly]="!modifier" formControlName="login"
                                                    class="form-control" id="login"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.login.errors }">
                                                <div *ngIf="submitted && pi.login.errors" class="invalid-feedback">
                                                    <div *ngIf="pi.login.errors.required">Vous devez entrer un
                                                        identifiant.</div>
                                                    <div *ngIf="pi.login.errors.login">L'identifiant doit être valide.
                                                    </div>
                                                </div>
                                                <div></div>
                                                <a *ngIf="!modifier" (click)="update()"
                                                    class="tw-text-xs text-success tw-cursor-pointer mt-1"
                                                    style="float:right;">Modifier</a>
                                                <a *ngIf="modifier" (click)="cancel()"
                                                    class="tw-text-xs text-dark tw-cursor-pointer mt-1"
                                                    style="float:right;">Annuler
                                                    la modification</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Update login -->
                                    <div *ngIf="modifier" class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0 text-success" for="newLogin"> <span
                                                        class="text-danger">*</span>
                                                    Nouveau numéro</label>
                                                <input [readonly]="!modifier" type="tel" formControlName="newLogin"
                                                    class="form-control" id="newLogin" placeholder=""
                                                    [ngClass]="{ 'is-invalid': submitted && pi.newLogin.errors }">
                                                <div *ngIf="submitted && pi.newLogin.errors" class="invalid-feedback">
                                                    <div *ngIf="pi.newLogin.errors.required">Entrez un numéro de
                                                        téléphone.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="modifier" class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0 text-success" for="confirmation"> <span
                                                        class="text-danger">*</span> Confirmer le numéro</label>
                                                <input [readonly]="!modifier" type="tel" formControlName="confirmation"
                                                    class="form-control" id="confirmation"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.confirmation.errors }">
                                                <div *ngIf="submitted && pi.confirmation.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="pi.confirmation.errors.required">Confirmez votre numéro.
                                                    </div>
                                                    <div
                                                        *ngIf="userInfosForm.value.newLogin !== userInfosForm.value.confirmation">
                                                        La confirmation est incorrecte.</div>
                                                </div>
                                                <a *ngIf="!modifier" (click)="update()"
                                                    class="tw-text-xs text-success tw-cursor-pointer mt-1"
                                                    style="float:right;">Modifier</a>
                                                <a *ngIf="modifier" (click)="cancel()"
                                                    class="tw-text-xs text-dark tw-cursor-pointer mt-1"
                                                    style="float:right;">Annuler
                                                    la modification</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- E & A -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="email">Email</label>
                                                <input formControlName="email" class="form-control" id="email"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.email.errors }">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="adresse">Adresse</label>
                                                <input readonly formControlName="adresse" class="form-control"
                                                    id="adresse"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.adresse.errors }">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- B & Sx -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="bday">Date de naissance</label>
                                                <input readonly formControlName="bday" class="form-control" id="bday"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.bday.errors }">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="m-0" for="sex">Sexe</label>
                                                <input readonly formControlName="sex" class="form-control" id="sex"
                                                    [ngClass]="{ 'is-invalid': submitted && pi.sex.errors }">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <div class="row justify-content-center">
                                    <button (click)="save()" [disabled]="!modifier" class="btn text-white col-lg-6"
                                        style="background-color: #2b3c8b;">Enregistrer les
                                        changements</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <!-- MDP -->
        <li ngbNavItem>
            <a ngbNavLink>Mot de passe</a>
            <ng-template ngbNavContent>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <form class="form-horizontal" [formGroup]="pwdForm">
                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                                    <!-- Ancien -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="m-0" for="old">Ancien mot de passe</label>
                                                <input formControlName="oldPwd" type="password" class="form-control"
                                                    id="old" [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
                                                <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                                                    <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                                                        l'ancien mot de passe.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Nouveau -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="m-0" for="new">Nouveau mot de passe</label>
                                                <input formControlName="newPwd" type="password" class="form-control"
                                                    id="new" [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
                                                <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                                                    <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                                                        nouveau mot de passe.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Confirmation -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                                                    passe</label>
                                                <input formControlName="confirmNewPwd" type="password"
                                                    class="form-control" id="confirmNewPwd"
                                                    [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
                                                <div *ngIf="submitted && p.confirmNewPwd.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                                                        mot de passe.
                                                    </div>
                                                    <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                                                        La confirmation est incorrecte.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <div class="row justify-content-center">
                                    <button (click)="updatePassword()" class="btn text-white col-lg-6"
                                        style="background-color: #2b3c8b;">Enregistrer les
                                        changements</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <!-- Act. -->
        <li ngbNavItem>
            <a ngbNavLink>Mon activité</a>
            <ng-template ngbNavContent>
                <app-activite></app-activite>
            </ng-template>
        </li>
        <!-- Act. -->
        <li *ngIf="passportType === 'MAIN'" ngbNavItem>
            <a ngbNavLink>Mes comptes gérés</a>
            <ng-template ngbNavContent>
                <app-comptes-geres></app-comptes-geres>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>