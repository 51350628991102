<!-- breadcrumb item -->
<div class="container-fluid tw-px-6">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between tw-pb-3 sm:tw-pb-6">
        <h4 class="mb-0">{{ title }}</h4>
        <div class="page-title-right tw-hidden sm:tw-inline">
          <ol class="breadcrumb m-0">
            <ng-container *ngFor="let item of breadcrumbItems">
              <li class="breadcrumb-item" *ngIf="!item.active"><a href="javascript: void(0);" routerLink="{{ item?.routerLink }}">{{ item.label }}</a>
              </li>
              <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}
              </li>
            </ng-container>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>