<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm text-uppercase">
            {{'HEADER.MEDICAL_PASSPORT' | translate}}
          </span>
          <span class="logo-lg text-uppercase">
            {{'HEADER.MEDICAL_PASSPORT' | translate}}
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm tw-text-white tw-font-black">
            <img src="assets/images/logo-light.png" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img class="mr-2" src="assets/images/logo-light.png" alt="" height="45">
          </span>
          <span class="logo-lg tw-text-xs tw-text-white tw-font-black text-uppercase">{{'HEADER.MEDICAL_PASSPORT' |
            translate}}</span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="ri-menu-2-line align-middle"></i>
      </button>
    </div>

    <div class="tw-flex tw-items-center">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ml-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ml-1">Français</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div *ngIf="patientExternalId" class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <img *ngIf="imageAvailable" class="rounded-circle header-profile-user"
            [src]="domsanitizer.bypassSecurityTrustUrl(patient.content)" alt="Header Avatar"> -->
          <img *ngIf="!imageAvailable" class="rounded-circle header-profile-user"
            src="assets/images/users/placeholder.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">{{ firstName }} {{ lastName }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);" routerLink="/passeport">
            {{'HEADER.LOGIN.MY_PASSPORT' | translate}}</a>
          <a class="dropdown-item" routerLink="/profil" href="javascript: void(0);">
            {{'HEADER.LOGIN.MY_PROFILE' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div *ngIf="!patientExternalId">
        <button (click)="goToPasseport(content)" type="button"
          class="btn waves-effect tw-py-2 tw-px-3 tw-bg-white tw-font-bold hover:tw-bg-[#009DF8] hover:tw-text-white">{{'HEADER.LOGIN.MY_PASSPORT'
          | translate}}</button>
      </div>
    </div>
  </div>
</header>

<ng-template #content let-modal>
  <div *ngIf="login">
    <div class="modal-header">
      <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">{{'HEADER.LOGIN.LOGIN' |
        translate}}</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- Form -->
      <form class="form-horizontal w-100" [formGroup]="loginForm">
        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

        <div class="form-group w-100">
          <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [maxLength]="15" [separateDialCode]="separateDialCode" name="phone" formControlName="phone"
            [searchCountryPlaceholder]="'Recherchez un pays'">
          </ngx-intl-tel-input>
          <div *ngIf="submitted && log.phone.errors" class="invalid-feedback">
            <div *ngIf="log.phone.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
            <div *ngIf="log.phone.errors.login">{{'SHARED.FIELD_REQUIRED' | translate}}</div>
          </div>
        </div>
        <!-- <div class="form-group auth-form-group-custom mb-4">
              <i class="ri-user-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
              <label for="login">Identifiant</label>
              <input formControlName="login" class="form-control" id="login" placeholder="Identifiant"
                [ngClass]="{ 'is-invalid': submitted && log.login.errors }">
              <div *ngIf="submitted && log.login.errors" class="invalid-feedback">
                <div *ngIf="log.login.errors.required">Vous devez entrer un identifiant.</div>
                <div *ngIf="log.login.errors.login">L'identifiant doit être valide.</div>
              </div>
            </div> -->

        <div class="form-group auth-form-group-custom mb-4">
          <i class="ri-lock-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
          <label for="password">{{'HEADER.LOGIN.PASS' | translate}}</label>
          <input type="password" formControlName="password" class="form-control" id="password"
            [ngClass]="{ 'is-invalid': submitted && log.password.errors }" placeholder="Mot de passe" />
          <div *ngIf="submitted && log.password.errors" class="invalid-feedback">
            <div *ngIf="log.password.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
          </div>
        </div>

        <div class="mt-4 text-center">
          <button class="btn w-md waves-effect waves-light text-white" style="background-color:#2b3c8b;" type="button"
            (click)="onSubmit()">{{'HEADER.LOGIN.TO_LOGIN' | translate}}</button>
        </div>

        <div class="mt-4 text-center">
          <a (click)="switchToReset()" class="text-muted tw-cursor-pointer"><i class="mdi mdi-lock mr-1"></i>
            {{'HEADER.LOGIN.FORGOT' | translate}}</a>
        </div>
      </form>

      <!-- <div class="mt-5 text-center">
        <p>Vous n'avez pas de compte ? <a (click)="login=!login" class="font-weight-medium tw-cursor-pointer"
            style="color:#2b3c8b;">
            INSCRIVEZ-VOUS ICI </a> </p>
      </div> -->
    </div>
  </div>

  <div *ngIf="signup">
    <div class="modal-header">
      <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Inscription</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-2 mt-2">
        <form class="form-horizontal" [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">
          <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Inscription réussie.
          </ngb-alert>
          <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-user-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="prenom"><span class="mr-2 text-danger">*</span> Prénom</label>
            <input type="text" formControlName="prenom" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.prenom.errors }" id="prenom" placeholder="">
            <div *ngIf="submitted && sig.prenom.errors" class="invalid-feedback">
              <div *ngIf="sig.prenom.errors.required">Vous devez entrer un prénom.</div>
            </div>
          </div>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-user-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="nom"> <span class="mr-2 text-danger">*</span>Nom</label>
            <input type="text" formControlName="nom" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.nom.errors }" id="nom" placeholder="">
            <div *ngIf="submitted && sig.nom.errors" class="invalid-feedback">
              <div *ngIf="sig.nom.errors.required">Vous devez entrer un nom.</div>
            </div>
          </div>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-mail-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="email"> <span class="mr-2 text-danger">*</span>Email</label>
            <input type="email" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.email.errors }" id="email" placeholder="">
            <div *ngIf="submitted && sig.email.errors" class="invalid-feedback">
              <div *ngIf="sig.email.errors.required">Vous devez entrer un email.</div>
              <div *ngIf="sig.email.errors.email">L'addresse email doit être valide.</div>
            </div>
          </div>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-phone-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="tel"> <span class="mr-2 text-danger">*</span>Téléphone</label>
            <input type="text" formControlName="tel" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.tel.errors }" id="tel" placeholder="">
            <div *ngIf="submitted && sig.tel.errors" class="invalid-feedback">
              <div *ngIf="sig.tel.errors.required">Vous devez entrer un numero de téléphone.</div>
            </div>
          </div>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-home-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="addresse"> <span class="mr-2 text-danger">*</span>Addresse</label>
            <input type="text" formControlName="addresse" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.addresse.errors }" id="addresse" placeholder="">
            <div *ngIf="submitted && sig.addresse.errors" class="invalid-feedback">
              <div *ngIf="sig.addresse.errors.required">Vous devez entrer une adresse.</div>
            </div>
          </div>

          <div class="form-group auth-form-group-custom mb-3">
            <i class="ri-lock-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
            <label for="password"> <span class="mr-2 text-danger">*</span>Mot de passe</label>
            <input type="password" formControlName="password" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && sig.password.errors }" id="password" placeholder="">
            <div *ngIf="submitted && sig.password.errors" class="invalid-feedback">
              <div *ngIf="sig.password.errors.required">Vous devez entrer un mot de passe.</div>
            </div>
          </div>

          <p class="tw-m-0"> <span class="mr-2 text-danger">*</span>Sexe</p>
          <div class="tw-flex tw-mt-2">
            <div class="form-group custom-control custom-radio tw-mr-3">
              <input type="radio" formControlName="sexe" class="custom-control-input" id="m" value="M">
              <label class="custom-control-label" for="m">Masculin</label>
            </div>
            <div class="form-group custom-control custom-radio">
              <input type="radio" formControlName="sexe" class="custom-control-input" id="f" value="F">
              <label class="custom-control-label" for="f">Féminin</label>
            </div>
          </div>


          <div class="text-center">
            <button class="btn w-md waves-effect waves-light text-white" type="submit"
              style="background-color:#2b3c8b;">S'inscrire</button>
          </div>

          <div class="mt-4 text-center">
            <p class="mb-0">En vous inscrivant, vous acceptez les <a target="_blank" routerLink="/cgu"
                style="color:#2b3c8b;">Conditions
                d'Utilisation</a></p>
          </div>
        </form>
      </div>

      <div class="mt-5 text-center">
        <p>Vous avez déjà un compte ? <a (click)="signup=!signup" class="font-weight-medium tw-cursor-pointer"
            style="color:#2b3c8b;">
            CONNECTEZ-VOUS ICI</a> </p>
      </div>
    </div>
  </div>

  <div *ngIf="reset">
    <div class="modal-header">
      <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">{{'HEADER.LOGIN.RESET_PASS'
        | translate}}</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [formGroup]="resetForm">
      <p class="m-0">{{'HEADER.LOGIN.TYPE_PHONE' | translate}}</p>
      <p class="m-0">{{'HEADER.LOGIN.TYPE_PHONE2' | translate}}</p>
      <!-- <div class="tw-flex">
            <div class="form-group custom-control custom-radio tw-mr-3">
              <input type="radio" formControlName="reset" class="custom-control-input" id="sms" value="SMS">
              <label class="custom-control-label" for="sms">par SMS</label>
            </div>
            <div class="form-group custom-control custom-radio">
              <input type="radio" formControlName="reset" class="custom-control-input" id="email" value="email">
              <label class="custom-control-label" for="email">par Email</label>
            </div>
          </div> -->
      <input class="form-control mt-2" formControlName="tel" type="tel"
        placeholder="{{'HEADER.LOGIN.ENTER_PHONE' | translate}}">
      <!-- <input *ngIf="resetForm.value.reset === 'email'" class="form-control mt-2" formControlName="email"
            type="email" placeholder="Entrez votre email"> -->
    </div>
    <div class="modal-footer">
      <button (click)="cancelReset()" type="button" class="btn btn-outline-dark mr-2">{{'SHARED.CANCEL' |
        translate}}</button>
      <button (click)="resetPwd()" type="button" class="btn text-white"
        style="background-color:#2b3c8b;">{{'SHARED.CONFIRM' | translate}}</button>
    </div>
  </div>

  <div *ngIf="verif">
    <div class="modal-header">
      <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Vérification compte</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Vous allez recevoir un code par SMS.</p>
      <form class="form-horizontal" [formGroup]="activationForm">
        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
        <div class="form-group auth-form-group-custom">
          <i class="ri-lock-2-line auti-custom-input-icon" style="color:#2b3c8b;"></i>
          <label for="code">Code</label>
          <input formControlName="code" class="form-control" id="code" placeholder="Entrez le code"
            [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
          <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
            <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="cancelVerif()" type="button" class="btn btn-outline-dark mr-2">Retour</button>
      <button type="button" class="btn text-white" (click)="verify()"
        style="background-color:#2b3c8b;">Vérifier</button>
    </div>
  </div>

  <div *ngIf="firstConnect">
    <div class="modal-header">
      <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Nouveau mot de passe</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Vous devez modifier votre mot de passe.</p>
      <form class="form-horizontal" [formGroup]="pwdForm">
        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
        <!-- Ancien -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="m-0" for="old">Ancien mot de passe</label>
              <input formControlName="oldPwd" type="password" class="form-control" id="old"
                [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
              <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                  l'ancien mot de passe.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Nouveau -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="m-0" for="new">Nouveau mot de passe</label>
              <input formControlName="newPwd" type="password" class="form-control" id="new"
                [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
              <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                  nouveau mot de passe.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Confirmation -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                passe</label>
              <input formControlName="confirmNewPwd" type="password" class="form-control" id="confirmNewPwd"
                [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
              <div *ngIf="submitted && p.confirmNewPwd.errors" class="invalid-feedback">
                <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                  mot de passe.
                </div>
                <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                  La confirmation est incorrecte.</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="changePassword()" class="btn text-white col-lg-6" style="background-color: #2b3c8b;">Enregistrer
        les
        changements</button>
    </div>
  </div>
</ng-template>

<!-- Verification -->
<ng-template #verification let-modal2>
  <div class="modal-header">
    <h4 class="modal-title">Code de vérification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Vous allez recevoir un code par SMS.</p>
    <div class="p-2 mt-2">
      <form class="form-horizontal" [formGroup]="activationForm">
        <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
        <div class="form-group auth-form-group-custom">
          <i class="ri-user-2-line auti-custom-input-icon"></i>
          <label for="code">Code</label>
          <input formControlName="code" class="form-control" id="code" placeholder="Entrez code"
            [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
          <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
            <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="verify()">Vérifier</button>
  </div>
</ng-template>