import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Accueil",
    link: "/",
  },
  {
    id: 1,
    label: "Professionnels",
    link: "/professionnels",
  },
  {
    id: 1,
    label: "Structures",
    link: "/structures",
  },
  {
    id: 1,
    label: "Pharmacies",
    link: "/pharmacies",
  },
];
