<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
      <div class="collapse navbar-collapse" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" routerLink="/passeport"
                id="topnav-components" role="button" (click)="setActive('accueil')">
                <span [ngClass]="{'accent': onAccueil}">{{'MENUITEMS.PASSPORT.HOME' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);"
                routerLink="/passeport/rendez-vous" id="topnav-components" role="button" (click)="setActive('rv')">
                <span [ngClass]="{'accent': onRv}">{{'MENUITEMS.PASSPORT.APPT' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);"
                routerLink="/passeport/contrats" id="topnav-components" role="button" (click)="setActive('assurances')">
                <span [ngClass]="{'accent': onAssurances}">{{'MENUITEMS.PASSPORT.INSURANCES' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);"
                routerLink="/passeport/votre-dossier" id="topnav-components" role="button"
                (click)="setActive('dossier')">
                <span [ngClass]="{'accent': onDossier}">{{'MENUITEMS.PASSPORT.PATIENT_FILE' | translate}}</span>
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);"
                routerLink="/passeport/autorisations" id="topnav-components" role="button"
                (click)="setActive('autorisations')">
                <span [ngClass]="{'accent': onAuth}">{{'MENUITEMS.PASSPORT.AUTH' | translate}}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>