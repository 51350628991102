<app-dpp-sn-topbar></app-dpp-sn-topbar>

<section class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h6 class="mb-0 text-white text-uppercase" style="font-family: Poppins;">
                    Dossier Patient Partagé
                </h6>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Portail Public</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Pharmacies</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">

    <div class="row mx-0">
        <div class="col-12 tw-relative d-flex flex-column justify-content-center align-items-center">
            <div [ngClass]="h.isNavigationSafe() && !h.positionActivated() ? 'tw-h-56' : 'tw-h-40'"
                class="tw-absolute p-4 bg-white w-100" style="top: -35px;">
                <div *ngIf="h.isNavigationSafe() && !h.positionActivated()">
                    <ngb-alert class="py-2 px-3" [dismissible]="false" type="info">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="ri-information-fill mr-2"></i>
                                Activez votre localisation pour voir les structures, pharmacies ou professionnels autour
                                de
                                vous.
                            </div>
                            <div>
                                <button (click)="activatePosition()" type="button"
                                    class="btn tw-bg-[#2b3c8b] text-white btn-sm">Activer</button>
                            </div>
                        </div>
                    </ngb-alert>
                </div>
                <!-- Search field -->
                <div class="tw-flex tw-justify-center tw-items-center">
                    <div class="tw-flex tw-justify-center tw-items-center w-100 tw-h-12 bg-light tw-rounded-l">

                        <!-- Place autocomplete -->
                        <div class="tw-w-2/5 tw-flex tw-content-start tw-items-center" [formGroup]="placeForm">
                            <!-- Lieu input -->
                            <input class="bg-light tw-w-full tw-h-full tw-outline-0 tw-border-0 tw-pl-2" type="search"
                                name="place" id="place" placeholder="Rechercher un lieu" formControlName="name"
                                InputText (click)="handleClosed($event)" [matAutocomplete]="place">
                            <!-- Autocomplete -->
                            <mat-autocomplete #place="matAutocomplete" class="tw-rounded tw-mt-6">
                                <div *ngIf="places?.length != 0">
                                    <mat-option *ngFor="let place of places" (click)="onSelectPlace(place?.town?.name)">
                                        <span class="tw-text-xs tw-text-slate-800">{{ place?.town?.name }}</span>
                                    </mat-option>
                                    <hr class="m-0">
                                </div>
                                <mat-option *ngIf="placeForm.value.name && places?.length === 0">
                                    <span style="font-size: 0.7rem;color:rgb(30 41 59);">{{ placeLoading ?
                                        'Chargement...' : '0 resultat' }}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <!-- Divider -->
                        <div class="tw-h-full tw-w-px tw-bg-slate-200"></div>

                        <!-- Autocomplete -->
                        <div class="tw-w-3/5 tw-flex tw-content-start tw-items-center" [formGroup]="termForm">
                            <input class="bg-light tw-w-full tw-h-full tw-outline-0 tw-border-0 tw-pl-2" type="search"
                                name="term" id="term" placeholder="Médecin, Structure, Pharmacie..." pInputText
                                (click)="handleClosed($event)" formControlName="term" [matAutocomplete]="auto">
                            <!-- Autocomplete -->
                            <mat-autocomplete #auto="matAutocomplete" class="tw-rounded tw-mt-6">
                                <!-- Specialités -->
                                <div *ngIf="specialities?.length != 0">
                                    <mat-option *ngFor="let spec of specialities"
                                        (click)="onSelectSpecialite(spec.nom)">
                                        <span class="tw-text-xs">{{ spec?.nom }}</span>
                                    </mat-option>
                                    <hr class="m-0">
                                </div>
                                <!-- Docteurs -->
                                <div *ngIf="professionals?.length != 0">
                                    <mat-option class="d-flex justify-content-start align-items-center"
                                        routerLink="details-professionnel/{{doctor?.eyoneexternalid}}"
                                        *ngFor="let doctor of professionals | orderBy: 'firstname'"
                                        (click)="onSelectProfessional(doctor)">

                                        <div class="d-flex justify-content-start align-items-center">
                                            <span class="tw-text-xs tw-mr-2">
                                                <i class="ri-account-circle-line tw-text-slate-800"></i>
                                            </span>
                                            <span class="tw-text-xs tw-text-slate-800">
                                                {{ doctor?.nom }}
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div *ngFor="let spec of doctor?.specialite"
                                                class="tw-m-0 tw-mr-2 tw-text-xs tw-text-[#009DF8]">
                                                {{ spec?.name }}
                                            </div>
                                        </div>

                                    </mat-option>
                                </div>
                                <!-- Structures -->
                                <div *ngIf="structures?.length != 0">
                                    <mat-option *ngFor="let structure of structures | orderBy: 'denomination'"
                                        (click)="onSelectStructure(structure)">
                                        <span class="tw-text-xs tw-mr-2">
                                            <i class="ri-hospital-line tw-text-slate-800"></i>
                                        </span>
                                        <span class="tw-text-xs tw-text-slate-800">
                                            {{ structure?.nom }}
                                        </span>
                                    </mat-option>
                                </div>
                                <!-- Pharmacies -->
                                <div *ngIf="pharmacies?.length != 0">
                                    <mat-option *ngFor="let pharmacy of pharmacies | orderBy: 'denomination'"
                                        (click)="onSelectPharmacy(pharmacy)">
                                        <span class="tw-text-xs tw-mr-2">
                                            <i class="ri-hospital-line tw-text-slate-800"></i>
                                        </span>
                                        <span class="tw-text-xs tw-text-slate-800">
                                            {{ pharmacy?.nom }}
                                        </span>
                                    </mat-option>
                                </div>

                                <div
                                    *ngIf="(termForm.value.term) && (specialities?.length === 0) && (professionals?.length === 0) && (structures?.length === 0) && (pharmacies?.length === 0)">
                                    <mat-option>
                                        <span class="tw-text-xs tw-text-slate-800">{{ termLoading ?
                                            'Chargement...' : '0 resultat' }}</span>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                        </div>

                    </div>
                    <button
                        class="tw-border-0 tw-bg-[#D00000] tw-w-4/12 tw-h-12 tw-rounded-r tw-shadow-lg tw-text-white hover:tw-bg-[#c70202]"
                        (click)="onClickSearchBtn()">Rechercher</button>
                </div>
                <!-- Filters -->
                <div class="tw-flex tw-justify-center tw-items-center tw-gap-x-3 mt-3">
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect1" [ngModel]="selectedService"
                            (ngModelChange)="onServiceChange($event)">
                            <option selected>{{ selectedService }}</option>
                            <option *ngFor="let service of services" value="{{ service?.name }}">{{
                                service?.name }}</option>
                        </select>
                    </div>
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect2" [ngModel]="selectedInsurer"
                            (ngModelChange)="onInsurerChange($event)">
                            <option selected>{{ selectedInsurer }}</option>
                            <option *ngFor="let insurer of insurers" value="{{ insurer?.name }}">{{
                                insurer?.name }}</option>
                        </select>
                    </div>
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect3" [ngModel]="selectedLang"
                            (ngModelChange)="onLangChange($event)">
                            <option selected>{{ selectedLang }}</option>
                            <option *ngFor="let lang of languages" value="{{ lang?.label }}">{{ lang?.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!response" class="tw-h-screen"></div>

    <div *ngIf="response?.data.length !== 0"
        [ngClass]="h.isNavigationSafe() && !h.positionActivated() ? 'tw-mt-[14rem]' : 'tw-mt-[10rem]'" class="w-100">
        <div class="tw-grid tw-grid-cols-1 tw-gap-y-6 lg:tw-grid-cols-2 lg:tw-gap-6 tw-mb-6">
            <div *ngFor="let data of response?.data">
                <div class="tw-px-3 tw-py-3 tw-bg-white tw-flex tw-content-start tw-items-start"
                    style="border-radius: 8px;">
                    <div class="tw-w-full tw-flex tw-content-between tw-items-center lg:tw-w-1/2"
                        style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;">
                        <div class="tw-flex tw-content-start tw-items-start">
                            <!-- <div *ngIf="data?.photos.length == 0"
                                class="tw-rounded-full tw-cursor-pointer tw-w-12 tw-h-12 tw-mr-4">
                                <img class="tw-rounded-full tw-w-12 tw-h-12" src="assets/images/users/henri.png"
                                    alt="avatar">
                            </div> -->

                            <div class="tw-flex tw-flex-col tw-content-start tw-items-start">
                                <p class="tw-text-slate-800 tw-text-md tw-m-0">
                                    {{ data?.name }}
                                </p>
                                <p class="tw-m-0 tw-text-slate-500 tw-text-xs tw-mt-1">{{
                                    data?.coordonate?.globaladressfield }}</p>
                                <p *ngIf="data?.distance" class="tw-m-0"><span
                                        class="tw-mt-1 tw-flex tw-content-start tw-items-center tw-text-xs"><i
                                            class="ri-navigation-fill tw-text-xs tw-mr-1 tw-text-green-300"></i>
                                        {{ data?.distance | number: '1.2-2' }} km</span></p>
                                <!-- <p class="tw-m-0 tw-text-green-700 tw-mt-2 tw-font-bold tw-text-xs">Ouvert</p> -->
                                <p *ngIf="data?.coordonate?.fixnumber || data?.coordonate?.mobilenumber"
                                    class="tw-mt-2 tw-mb-0 tw-flex tw-content-start tw-items-center">
                                    <span *ngIf="data?.coordonate?.fixnumber || data?.coordonate?.mobilenumber"
                                        class="tw-mr-2"><i class="ri-phone-fill"></i></span> <span
                                        *ngIf="data?.coordonate?.fixnumber" class="tw-mr-2 tw-text-xs"> {{
                                        data?.coordonate?.fixnumber }} </span>
                                    <span *ngIf="data?.coordonate?.mobilenumber" class="tw-mr-2 tw-text-xs">-</span>
                                    <span *ngIf="data?.coordonate?.mobilenumber" class="tw-text-xs"> {{
                                        data?.coordonate?.mobilenumber }} </span>
                                </p>
                                <!-- <p *ngIf="data?.payments.length != 0" class="tw-mt-2 tw-text-xs">Accepte:</p> -->
                                <ng-container class="tw-mt-1 tw-flex tw-flex-wrap">
                                    <span *ngFor="let payment of data?.payments"
                                        class="tw-bg-gray-200 tw-px-2 tw-py-1 tw-mr-2 tw-mt-2 tw-rounded tw-text-xs tw-text-black">
                                        {{ payment?.name }}
                                    </span>
                                </ng-container>
                                <div class="tw-flex tw-content-start tw-items-center tw-mt-3">
                                    <a href="javascript:void(0)" routerLink="/details-pharmacie/{{data?.id}}"
                                        class="tw-bg-[#D00000] tw-rounded-full tw-py-1 tw-px-3 tw-text-white tw-text-xs hover:tw-bg-[blue-800]">{{'SHARED.SEE_DETAILS'
                                        | translate}}</a>
                                </div>
                                <!-- <p class="tw-mt-2 tw-flex tw-flex-wrap">
                                <span
                                    class="tw-bg-[#2b3c8b] tw-px-2 tw-py-1 tw-mr-2 tw-mt-2 tw-rounded tw-text-white tw-text-xs">
                                    Garde
                                </span>
                            </p> -->
                            </div>
                        </div>
                    </div>
                    <div class="tw-w-full tw-flex tw-content-center tw-items-center tw-bg-slate-100 lg:tw-w-1/2"
                        style="height: 180px; border-radius: 8px; overflow: hidden;">
                        <agm-map [latitude]="data?.coordonate?.latitude" [longitude]="data?.coordonate?.longitude"
                            [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
                            style="width: 100%; height: 100%; border-radius: 8px; overflow: hidden;">

                            <agm-marker [iconUrl]="iconUrl" [latitude]="data?.coordonate?.latitude"
                                [longitude]="data?.coordonate?.longitude" [markerDraggable]="false">
                                <agm-info-window>
                                    <span class="fw-bold">{{ data?.name }}</span>
                                </agm-info-window>
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Pagination -->
    <div class="w-100 d-flex justify-content-end tw-mb-8">
        <button *ngIf="response?.prev_page_url"
            class="tw-m-0 tw-border-0 tw-bg-white hover:tw-bg-slate-200 tw-py-3 tw-px-8 tw-shadow-1 tw-mr-3"
            (click)="previousPage()"><span class="font-bold text-2xl">Précédent</span></button>
        <button *ngIf="!response?.prev_page_url" class="tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-8 tw-shadow-1"
            disabled><span class="font-bold text-2xl">Précédent</span></button>

        <button *ngIf="response?.next_page_url"
            class="tw-text-right tw-m-0 tw-border-0 tw-bg-white hover:tw-bg-slate-200 tw-py-3 tw-px-8 tw-shadow-1"
            (click)="nextPage()"><span class="font-bold text-2xl">Suivant</span></button>
        <button *ngIf="!response?.next_page_url"
            class="tw-text-right tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-8 tw-shadow-1" disabled><span
                class="font-bold text-2xl">Suivant</span></button>
    </div>

</div>