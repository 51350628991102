import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { AuthService } from "src/app/core/services/authentification.service";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-pharmacy-details",
  templateUrl: "./pharmacy-details.component.html",
  styleUrls: ["./pharmacy-details.component.scss"],
})
export class PharmacyDetailsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  pharmacy: any;
  id;
  zoom: number = 15;
  iconUrl: {
    scaledSize: {
      width: 50;
      height: 50;
    };
  };

  constructor(
    private route: ActivatedRoute,
    public h: HelpersService,
    public formBuilder: FormBuilder,
    public auth: AuthService,
    private http: HttpClient,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Accueil" },
      { label: "Pharmacies", routerLink: "/pharmacies" },
      {
        label: "Pharmacie",
        active: true,
      },
    ];

    this.route.params.subscribe(async (params) => {
      this.id = params["id"];
      let token = sessionStorage.getItem("publicToken");
      let header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      this.loader.start();
      this.http
        .get<any>(
          environment.referentiel + environment.pharmacyDetails + this.id,
          {
            headers: header,
          }
        )
        .pipe(timeout(30000))
        .toPromise()
        .then((res) => {
          this.loader.stop();
          if (res.name) {
            this.pharmacy = res;
          }
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRequestError(e);
        });
    });
  }
}
