import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { AuthService } from "src/app/core/services/authentification.service";
import { ConfirmLoginValidator } from "src/app/core/services/confirm-login.validator";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";

@Component({
  selector: "app-details",
  templateUrl: "./details-professionnel.component.html",
  styleUrls: ["./details-professionnel.component.scss"],
})
export class DetailsProfessionnelComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  profile: any;
  id;
  error = "";
  zoom: number = 15;
  iconUrl: {
    scaledSize: {
      width: 50;
      height: 50;
    };
  };
  submitted = false;
  successmsg = false;

  login = true;
  signup = false;
  reset = false;
  verif = false;
  firstConnect = false;

  patient: any;

  loginForm: FormGroup;
  signupForm: FormGroup;
  activationForm: FormGroup;
  pwdForm: FormGroup;
  resetForm: FormGroup;

  retrievedUser: any;
  token: any;

  fakeList = Array(4)
    .fill(1, 4)
    .map((x, i) => i);
  connectedUser: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Senegal,
    CountryISO.CôteDIvoire,
    CountryISO.Cameroon,
  ];
  patientExternalId: any;
  selectedProfessional: any;
  selectedSlot: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public h: HelpersService,
    public formBuilder: FormBuilder,
    private modal: NgbModal,
    public auth: AuthService,
    private userService: UserProfileService,
    private http: HttpClient,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    let token = sessionStorage.getItem("publicToken");
    if (!token) {
      this.auth
        .getPublicJwtToken("francis.ngom@eyone.net", "passe")
        .then((_) => this.ngOnInit());
    } else {
      this.patientExternalId = sessionStorage.getItem("patientExternalId");
      this.breadCrumbItems = [
        { label: "Accueil" },
        { label: "Professionnels", routerLink: "/professionnels" },
        {
          label: "Professionnel",
          active: true,
        },
      ];
      this.loginForm = this.formBuilder.group({
        login: ["", [Validators.required]],
        password: ["", [Validators.required]],
      });
      this.signupForm = this.formBuilder.group({
        prenom: ["", Validators.required],
        nom: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        tel: ["", Validators.required],
        addresse: ["", Validators.required],
        password: ["", Validators.required],
        sexe: ["", Validators.required],
      });
      this.activationForm = this.formBuilder.group({
        code: ["", [Validators.nullValidator]],
      });
      this.resetForm = this.formBuilder.group({
        reset: ["", [Validators.nullValidator]],
        email: ["", [Validators.nullValidator]],
        tel: ["", [Validators.nullValidator]],
      });
      this.pwdForm = this.formBuilder.group(
        {
          oldPwd: ["", [Validators.required]],
          newPwd: ["", [Validators.required]],
          confirmNewPwd: ["", [Validators.required]],
        },
        {
          validator: ConfirmLoginValidator("newPwd", "confirmNewPwd"),
        }
      );

      this.route.params.subscribe(async (params) => {
        this.id = params["id"];
        let token = sessionStorage.getItem("publicToken");
        let header = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });
        this.loader.start();
        this.http
          .get<any>(
            environment.referentiel + environment.doctorDetails + this.id,
            {
              headers: header,
            }
          )
          .pipe(timeout(30000))
          .toPromise()
          .then((res) => {
            this.loader.stop();
            if (res.firstname) {
              this.profile = this.h.generateOneProfessionalAvailabilities(res);
            }
          })
          .catch((e) => {
            this.loader.stop();
            this.h.handleRequestError(e);
          });
      });
    }
  }

  get log() {
    return this.loginForm.controls;
  }
  get sig() {
    return this.signupForm.controls;
  }
  get v() {
    return this.activationForm.controls;
  }
  get p() {
    return this.pwdForm.controls;
  }

  voirPlus() {
    var lengths = [];
    this.profile.isCollapsed = true;
    this.profile.availabilities.forEach((e) => {
      if (e.slots.length != 0) {
        lengths.push(e.slots.length);
      }
    });
    var len = Math.max(...lengths);
    this.profile.rowNumber = Array(len)
      .fill(1, 4)
      .map((x, i) => i);
    this.fakeList = Array(len)
      .fill(1, 4)
      .map((x, i) => i);
  }
  voirMoins() {
    this.profile.isCollapsed = false;
    this.profile.rowNumber = Array(4)
      .fill(1, 4)
      .map((x, i) => i);
    this.fakeList = Array(4)
      .fill(1, 4)
      .map((x, i) => i);
  }

  navigateLeft(professional) {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    this.loader.start();
    try {
      this.http
        .get<any[]>(
          environment.referentiel +
            environment.getTimeSlots +
            `?id=${professional.id}&start_date=${professional.previous_range_start}&limit=5`,
          { headers: header }
        )
        .subscribe((res) => {
          this.loader.stop();
          this.profile = this.h.generateOneProfessionalPreviousAvailabilities(
            this.profile,
            res
          );
        });
    } catch (error) {
      Swal.fire(
        "Erreur",
        "Une erreur est survenue lors du chargement des données",
        "error"
      );
    }
  }

  navigateRight(professional) {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    this.loader.start();
    try {
      this.http
        .get<any[]>(
          environment.referentiel +
            environment.getTimeSlots +
            `?id=${professional.id}&start_date=${professional.next_range_start}&limit=5`,
          { headers: header }
        )
        .subscribe((res) => {
          this.loader.stop();
          this.profile = this.h.generateOneProfessionaNextAvailabilities(
            this.profile,
            res
          );
        });
    } catch (error) {
      Swal.fire(
        "Erreur",
        "Une erreur est survenue lors du chargement des données",
        "error"
      );
    }
  }

  onSelectSlot(authModal, bookingModal, slot) {
    this.selectedSlot = slot;
    if (this.patientExternalId) {
      this.openSlotBookingModal(bookingModal);
    } else {
      Swal.fire({
        text: "Vous devez vous identifier pour prendre rendez-vous.",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 4000,
      });
      this.open(authModal);
    }
  }

  open(content) {
    this.modal.open(content, {
      size: "md",
    });
  }
  openSlotBookingModal(modal) {
    this.modal.open(modal, {
      size: "md",
      scrollable: true,
    });
  }

  onSubmit() {
    this.error = "";
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      let num = this.log.phone.value.e164Number as string;
      num = num.replace("+", "00");
      this.loader.start();
      this.auth
        .login(num, this.log.password.value)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.userService
            .getPatientByUsername(r.userName, r["access_token"])
            .pipe(timeout(30000))
            .toPromise()
            .then((p) => {
              this.connectedUser = p;
              this.token = r["access_token"];
              if (p.passeportCreationStatusLabel == "A valider par l'usager") {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.verif = true;
                this.submitted = false;
                this.connectedUser = p;
              } else if (
                p.passeportCreationStatusLabel ==
                "A valider par l'usager après création par un tier"
              ) {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.submitted = false;
                this.firstConnect = true;
                this.connectedUser = p;
              } else if (p.passeportCreationStatusLabel == "Actif") {
                sessionStorage.setItem("passportToken", r["access_token"]);
                sessionStorage.setItem(
                  "refreshPassportToken",
                  r["refresh_token"]
                );
                sessionStorage.setItem("login", p["login"]);
                sessionStorage.setItem("firstName", p["firstName"]);
                sessionStorage.setItem("lastName", p["lastName"]);
                sessionStorage.setItem(
                  "passportType",
                  p["passeportManagementType"]
                );
                sessionStorage.setItem(
                  "patientExternalId",
                  p["eyoneExternalId"]
                );
                this.modal.dismissAll();
                window.location.reload();
              }
            })
            .catch((e) => {
              this.loader.stop();
              this.h.handleRefRequestError(e);
            });
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRefRequestError(e);
        });
    }
  }

  onSubmitRegister() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    } else {
      this.loader.start();
      let user = {
        password: this.signupForm.value.password,
        firstName: this.signupForm.value.prenom,
        lastName: this.signupForm.value.nom,
        sex: this.signupForm.value.sexe,
        mobileNumber: "00221" + this.signupForm.value.tel,
        mail: this.signupForm.value.email,
        birthday: null,
        countryCode: "SN",
        regionCode: "DKR",
        adress: this.signupForm.value.addresse,
        fixeNumber: null,
        base64Photo: null,
        photoExtension: null,
        passeportCreationSource: "PASSEPORT_WEB",
        passeportCreationStatus: "TO_BE_ACTIVATED",
        passeportManagementType: "MAIN",
        passeportOrganismCreator: "Postman",
        passeportUserCreator: "Henri",
        creationValidationMode: "SMS",
        passeportCreationDate: null,
        creationValidationDate: null,
        mainEyoneExternalId: null,
      };
      this.userService
        .register(user)
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.successmsg = true;
          this.login = true;
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRequestError(e);
        });
    }
  }

  switchToReset() {
    this.error = "";
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelReset() {
    this.error = "";
    this.resetForm.reset();
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelVerif() {
    this.error = "";
    this.submitted = false;
    this.loginForm.reset();
    this.login = !this.login;
    this.verif = !this.verif;
  }

  openVerification(verification) {
    this.modal.open(verification, { size: "sm" });
  }

  async verify() {
    this.submitted = true;
    if (this.activationForm.invalid) {
      return;
    } else {
      this.loader.start();
      let body = {
        codeIdentifier: this.connectedUser.mobileNumber,
        codeIdentifierType: "PHONE_NUMBER",
        codeType: "ACTIVATE_MAIN_ACCOUNT",
        inputCode: this.activationForm.value.code,
        // managedExternalId: this.connectedUser.eyoneExternalId,
      };
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", `Bearer ${this.token}`);
      await this.http
        .post<any>(`${environment.base}v1/codes-validation`, body, {
          headers: headers,
        })
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("login", this.connectedUser["login"]);
          sessionStorage.setItem("firstName", this.connectedUser["firstName"]);
          sessionStorage.setItem("lastName", this.connectedUser["lastName"]);
          sessionStorage.setItem(
            "passportToken",
            this.connectedUser["access_token"]
          );
          sessionStorage.setItem(
            "refreshPassportToken",
            this.connectedUser["refresh_token"]
          );
          sessionStorage.setItem(
            "patientExternalId",
            this.connectedUser["eyoneExternalId"]
          );
          sessionStorage.setItem(
            "passportType",
            this.connectedUser["passeportManagementType"]
          );
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  async resetPwd() {
    let body = {
      phoneNumber: "00221" + this.resetForm.value.tel,
      sendingChannel: "SMS",
      username: "00221" + this.resetForm.value.tel,
    };
    this.loader.start();
    await this.http
      .post<any>(
        `${environment.base}v1/registration/reInitPatientPassword`,
        body,
        {
          responseType: "text/plain;charset=ISO-8859-1" as "json",
        }
      )
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.modal.dismissAll();
        Swal.fire({
          title: "Succès",
          icon: "success",
          text:
            r + " Un SMS vous a été transmis sur votre numéro de téléphone.",
          showConfirmButton: false,
          timer: 6000,
        });
      })
      .catch((e) => {
        this.loader.stop();
        this.modal.dismissAll();
        this.h.handleRequestError(e);
      });
  }

  updatePassword() {
    this.submitted = true;
    let userName = sessionStorage.getItem("login");
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    if (patientExternalId !== undefined) {
    }
    if (this.pwdForm.invalid) {
      return;
    } else {
      let body = {
        userName: userName,
        eyoneExternalId: patientExternalId,
        newConfirmedPassWord: this.pwdForm.value.confirmNewPwd,
        newPassword: this.pwdForm.value.newPwd,
        oldPassword: this.pwdForm.value.oldPwd,
      };
      this.loader.start();
      this.userService
        .changePassword(body)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          Swal.fire({
            icon: "success",
            text: "Mot de passe modifié avec succès.",
            showConfirmButton: false,
            timer: 2000,
          }).then((_) => this.auth.logout());
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  async changePassword() {
    this.submitted = true;
    if (this.pwdForm.invalid) {
      return;
    } else {
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", "Bearer " + this.token);
      let body = {
        eyoneExternalId: this.connectedUser.eyoneExternalId,
        password: this.pwdForm.value.newPwd,
      };
      this.loader.start();
      await this.http
        .put<any>(
          `${environment.base}api/rest/v1/patients/activation-by-password`,
          body,
          { headers: headers }
        )
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("passportToken", this.token);
          sessionStorage.setItem("refreshPassportToken", r["refresh_token"]);
          sessionStorage.setItem("login", r["login"]);
          sessionStorage.setItem("firstName", r["firstName"]);
          sessionStorage.setItem("lastName", r["lastName"]);
          sessionStorage.setItem("passportType", r["passeportManagementType"]);
          sessionStorage.setItem("patientExternalId", r["eyoneExternalId"]);
          return Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }
}
