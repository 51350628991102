<div id="layout-wrapper">
  <!-- <app-horizontaltopbar></app-horizontaltopbar>
  <app-horizontalnavbar></app-horizontalnavbar> -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content tw-bg-[#f1f5f7]">
    <!-- page-content -->
    <div class="tw-px-0">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>

  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->