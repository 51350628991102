import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { AuthService } from "src/app/core/services/authentification.service";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { ConfirmLoginValidator } from "src/app/core/services/confirm-login.validator";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-inscription",
  templateUrl: "./inscription.component.html",
  styleUrls: ["./inscription.component.scss"],
})
export class InscriptionComponent implements OnInit {
  form: FormGroup;
  loginForm: FormGroup;
  activationForm: FormGroup;
  resetForm: FormGroup;
  pwdForm: FormGroup;

  masculin: any;
  feminin: any;
  sexe: any;
  token: any;
  refresh: any;
  connectedUser: any;
  error: any;
  retrievedUser: any;
  requestedCard: any;

  submitted: boolean = false;
  login: boolean = true;
  signup: boolean = false;
  reset: boolean = false;
  verif: boolean = false;
  firstConnect: boolean = false;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Senegal,
    CountryISO.CôteDIvoire,
    CountryISO.Cameroon,
  ];

  constructor(
    public formBuilder: FormBuilder,
    private modal: NgbModal,
    public auth: AuthService,
    private userService: UserProfileService,
    public h: HelpersService,
    private loader: NgxUiLoaderService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);

    this.form = this.formBuilder.group(
      {
        sex: ["", [Validators.required]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        phone: ["", [Validators.required]],
        mobileNumber: ["", [Validators.nullValidator]],
        password: ["", [Validators.required]],
        birthday: ["", [Validators.nullValidator]],
        countryCode: ["", [Validators.nullValidator]],
        adress: ["", [Validators.nullValidator]],
        mail: ["", [Validators.nullValidator]],
        passeportCreationSource: ["PASSEPORT_WEB", [Validators.nullValidator]],
        passeportManagementType: ["MAIN", [Validators.nullValidator]],
        creationValidationMode: ["SMS", [Validators.nullValidator]],
        requestedCard: [null, [Validators.nullValidator]],
        confirmedPassword: ["", [Validators.required]],
      },
      {
        validator: ConfirmLoginValidator("password", "confirmedPassword"),
      }
    );
    this.loginForm = this.formBuilder.group({
      phone: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
    this.activationForm = this.formBuilder.group({
      code: ["", [Validators.nullValidator]],
    });
    this.resetForm = this.formBuilder.group({
      reset: ["", [Validators.nullValidator]],
      email: ["", [Validators.nullValidator]],
      tel: ["", [Validators.nullValidator]],
    });
    this.pwdForm = this.formBuilder.group(
      {
        oldPwd: ["", [Validators.required]],
        newPwd: ["", [Validators.required]],
        confirmNewPwd: ["", [Validators.required]],
      },
      {
        validator: ConfirmLoginValidator("newPwd", "confirmNewPwd"),
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get log() {
    return this.loginForm.controls;
  }
  get v() {
    return this.activationForm.controls;
  }
  get p() {
    return this.pwdForm.controls;
  }

  onCheck() {
    if (this.sexe === "M") {
      this.masculin = true;
      this.feminin = false;
    } else {
      this.feminin = true;
      this.masculin = false;
    }
    this.form.patchValue({ sex: this.sexe });
  }

  onCheckReq() {
    if (this.requestedCard === "Y") {
      this.form.patchValue({ requestedCard: true });
    } else {
      this.form.patchValue({ requestedCard: false });
    }
  }

  openAuthModal(modal) {
    this.modal.open(modal, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
  }

  submit(authModal) {
    this.submitted = true;
    if (this.form.invalid) return;
    let p = this.f.phone.value;
    let countryCode = p.countryCode;
    let mobileNumber = p.e164Number.toString().replace("+", "00");
    this.form.patchValue({ mobileNumber, countryCode });
    // console.log(this.form.value);
    // return;

    this.loader.start();
    this.userService
      .register(this.form.value)
      .pipe(timeout(30000))
      .toPromise()
      .then((_) => {
        this.loader.stop();
        this.submitted = false;
        this.form.reset();
        this.openAuthModal(authModal);
      })
      .catch((e) => {
        this.loader.stop();
        if (e.error.errorCode === "EXPIRED_TOKEN") {
          this.loader.start();
          this.h.handleExpiredToken().then((_) => {
            this.userService
              .register(this.form.value)
              .pipe(timeout(30000))
              .toPromise()
              .then((_) => {
                this.loader.stop();
                this.submitted = false;
                this.form.reset();
                this.openAuthModal(authModal);
              })
              .catch((e) => {
                this.loader.stop();
                this.h.handleRequestError(e);
              });
          });
        } else {
          this.h.handleRequestError(e);
        }
      });
  }

  onSubmit() {
    this.error = "";
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      let num = this.log.phone.value.e164Number as string;
      num = num.replace("+", "00");
      this.loader.start();
      this.auth
        .login(num, this.log.password.value)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.userService
            .getPatientByUsername(r.userName, r["access_token"])
            .pipe(timeout(30000))
            .toPromise()
            .then((p) => {
              this.connectedUser = p;
              this.token = r["access_token"];
              this.refresh = r["refresh_token"];
              if (p.passeportCreationStatusLabel == "A valider par l'usager") {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.verif = true;
                this.submitted = false;
                this.connectedUser = p;
              } else if (
                p.passeportCreationStatusLabel ==
                "A valider par l'usager après création par un tier"
              ) {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.submitted = false;
                this.firstConnect = true;
                this.connectedUser = p;
              } else if (p.passeportCreationStatusLabel == "Actif") {
                sessionStorage.setItem("passportToken", r["access_token"]);
                sessionStorage.setItem(
                  "refreshPassportToken",
                  r["refresh_token"]
                );
                sessionStorage.setItem("login", p["login"]);
                sessionStorage.setItem("firstName", p["firstName"]);
                sessionStorage.setItem("lastName", p["lastName"]);
                sessionStorage.setItem(
                  "passportType",
                  p["passeportManagementType"]
                );
                sessionStorage.setItem(
                  "patientExternalId",
                  p["eyoneExternalId"]
                );
                this.modal.dismissAll();
                window.location.reload();
              }
            })
            .catch((e) => {
              this.loader.stop();
              this.h.handleRefRequestError(e);
            });
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRefRequestError(e);
        });
    }
  }

  switchToReset() {
    this.error = "";
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelReset() {
    this.error = "";
    this.resetForm.reset();
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelVerif() {
    this.error = "";
    this.submitted = false;
    this.loginForm.reset();
    this.login = !this.login;
    this.verif = !this.verif;
  }

  openVerification(verification) {
    this.modal.open(verification, { size: "sm", backdrop: "static" });
  }

  async resetPwd() {
    let body = {
      phoneNumber: "00221" + this.resetForm.value.tel,
      sendingChannel: "SMS",
      username: "00221" + this.resetForm.value.tel,
    };
    this.loader.start();
    await this.http
      .post<any>(
        `${environment.base}v1/registration/reInitPatientPassword`,
        body,
        {
          responseType: "text/plain;charset=ISO-8859-1" as "json",
        }
      )
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.modal.dismissAll();
        Swal.fire({
          title: "Succès",
          icon: "success",
          text:
            r + " Un SMS vous a été transmis sur votre numéro de téléphone.",
          showConfirmButton: false,
          timer: 6000,
        });
      })
      .catch((e) => {
        this.loader.stop();
        this.modal.dismissAll();
        this.h.handleRefRequestError(e);
      });
  }

  async verify() {
    this.submitted = true;
    if (this.activationForm.invalid) {
      return;
    } else {
      this.loader.start();
      let body = {
        codeIdentifier: this.connectedUser.mobileNumber,
        codeIdentifierType: "PHONE_NUMBER",
        codeType: "ACTIVATE_MAIN_ACCOUNT",
        inputCode: this.activationForm.value.code,
      };
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", `Bearer ${this.token}`);
      await this.http
        .post<any>(`${environment.base}v1/codes-validation`, body, {
          headers: headers,
        })
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("login", this.connectedUser["login"]);
          sessionStorage.setItem("firstName", this.connectedUser["firstName"]);
          sessionStorage.setItem("lastName", this.connectedUser["lastName"]);
          sessionStorage.setItem(
            "passportToken",
            this.connectedUser["access_token"]
          );
          sessionStorage.setItem(
            "refreshPassportToken",
            this.connectedUser["refresh_token"]
          );
          sessionStorage.setItem(
            "patientExternalId",
            this.connectedUser["eyoneExternalId"]
          );
          sessionStorage.setItem(
            "passportType",
            this.connectedUser["passeportManagementType"]
          );
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  updatePassword() {
    this.submitted = true;
    let userName = sessionStorage.getItem("login");
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    if (patientExternalId !== undefined) {
    }
    if (this.pwdForm.invalid) {
      return;
    } else {
      let body = {
        userName: userName,
        eyoneExternalId: patientExternalId,
        newConfirmedPassWord: this.pwdForm.value.confirmNewPwd,
        newPassword: this.pwdForm.value.newPwd,
        oldPassword: this.pwdForm.value.oldPwd,
      };
      this.loader.start();
      this.userService
        .changePassword(body)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          Swal.fire({
            icon: "success",
            text: "Mot de passe modifié avec succès.",
            showConfirmButton: false,
            timer: 2000,
          }).then((_) => this.auth.logout());
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRefRequestError(e);
        });
    }
  }

  async changePassword() {
    this.submitted = true;
    if (this.pwdForm.invalid) {
      return;
    } else {
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", "Bearer " + this.token);
      let body = {
        eyoneExternalId: this.connectedUser.eyoneExternalId,
        password: this.pwdForm.value.newPwd,
      };
      this.loader.start();
      await this.http
        .put<any>(
          `${environment.base}api/rest/v1/patients/activation-by-password`,
          body,
          { headers: headers }
        )
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("passportToken", this.token);
          sessionStorage.setItem("refreshPassportToken", r["refresh_token"]);
          sessionStorage.setItem("login", r["login"]);
          sessionStorage.setItem("firstName", r["firstName"]);
          sessionStorage.setItem("lastName", r["lastName"]);
          sessionStorage.setItem("passportType", r["passeportManagementType"]);
          sessionStorage.setItem("patientExternalId", r["eyoneExternalId"]);
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }
}
