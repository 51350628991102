<app-dpp-sn-topbar></app-dpp-sn-topbar>

<section class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h6 class="mb-0 text-white text-uppercase" style="font-family: Poppins;">
                    Dossier Patient Partagé
                </h6>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Portail Public</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Médecins</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">

    <div class="row mx-0">
        <div class="col-12  tw-relative d-flex flex-column justify-content-center align-items-center">
            <div [ngClass]="h.isNavigationSafe() && !h.positionActivated() ? 'tw-h-56' : 'tw-h-40'"
                class="tw-absolute p-4 bg-white w-100" style="top: -35px;">
                <div *ngIf="h.isNavigationSafe() && !h.positionActivated()">
                    <ngb-alert class="py-2 px-3" [dismissible]="false" type="info">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="ri-information-fill mr-2"></i>
                                Activez votre localisation pour voir les structures, pharmacies ou professionnels autour
                                de
                                vous.
                            </div>
                            <div>
                                <button type="button" (click)="activatePosition()"
                                    class="btn tw-bg-[#2b3c8b] text-white btn-sm">Activer</button>
                            </div>
                        </div>
                    </ngb-alert>
                </div>
                <!-- Search field -->
                <div class="tw-flex tw-justify-center tw-items-center">
                    <div class="tw-flex tw-justify-center tw-items-center w-100 tw-h-12 bg-light tw-rounded-l">

                        <!-- Place autocomplete -->
                        <div class="tw-w-2/5 tw-flex tw-content-start tw-items-center" [formGroup]="placeForm">
                            <!-- Lieu input -->
                            <input class="bg-light tw-w-full tw-h-full tw-outline-0 tw-border-0 tw-pl-2" type="search"
                                name="place" id="place" placeholder="Rechercher un lieu" formControlName="name"
                                InputText (click)="handleClosed($event)" [matAutocomplete]="place">
                            <!-- Autocomplete -->
                            <mat-autocomplete #place="matAutocomplete" class="tw-rounded tw-mt-6">
                                <div *ngIf="places?.length != 0">
                                    <mat-option *ngFor="let place of places" (click)="onSelectPlace(place?.town?.name)">
                                        <span class="tw-text-xs tw-text-slate-800">{{ place?.town?.name }}</span>
                                    </mat-option>
                                    <hr class="m-0">
                                </div>
                                <mat-option *ngIf="placeForm.value.name && places?.length === 0">
                                    <span style="font-size: 0.7rem;color:rgb(30 41 59);">{{ placeLoading ?
                                        'Chargement...' : '0 resultat' }}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <!-- Divider -->
                        <div class="tw-h-full tw-w-px tw-bg-slate-200"></div>

                        <!-- Autocomplete -->
                        <div class="tw-w-3/5 tw-flex tw-content-start tw-items-center" [formGroup]="termForm">
                            <input class="bg-light tw-w-full tw-h-full tw-outline-0 tw-border-0 tw-pl-2" type="search"
                                name="term" id="term" placeholder="Médecin, Structure, Pharmacie..." pInputText
                                (click)="handleClosed($event)" formControlName="term" [matAutocomplete]="auto">
                            <!-- Autocomplete -->
                            <mat-autocomplete #auto="matAutocomplete" class="tw-rounded tw-mt-6">
                                <!-- Specialités -->
                                <div *ngIf="specialities?.length != 0">
                                    <mat-option *ngFor="let spec of specialities"
                                        (click)="onSelectSpecialite(spec.nom)">
                                        <span class="tw-text-xs">{{ spec?.nom }}</span>
                                    </mat-option>
                                    <hr class="m-0">
                                </div>
                                <!-- Docteurs -->
                                <div *ngIf="professionals?.length != 0">
                                    <mat-option class="d-flex justify-content-start align-items-center"
                                        routerLink="details-professionnel/{{doctor?.eyoneexternalid}}"
                                        *ngFor="let doctor of professionals | orderBy: 'firstname'"
                                        (click)="onSelectProfessional(doctor)">

                                        <div class="d-flex justify-content-start align-items-center">
                                            <span class="tw-text-xs tw-mr-2">
                                                <i class="ri-account-circle-line tw-text-slate-800"></i>
                                            </span>
                                            <span class="tw-text-xs tw-text-slate-800">
                                                {{ doctor?.nom }}
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div *ngFor="let spec of doctor?.specialite"
                                                class="tw-m-0 tw-mr-2 tw-text-xs tw-text-[#009DF8]">
                                                {{ spec?.name }}
                                            </div>
                                        </div>

                                    </mat-option>
                                </div>
                                <!-- Structures -->
                                <div *ngIf="structures?.length != 0">
                                    <mat-option *ngFor="let structure of structures | orderBy: 'denomination'"
                                        (click)="onSelectStructure(structure)">
                                        <span class="tw-text-xs tw-mr-2">
                                            <i class="ri-hospital-line tw-text-slate-800"></i>
                                        </span>
                                        <span class="tw-text-xs tw-text-slate-800">
                                            {{ structure?.nom }}
                                        </span>
                                    </mat-option>
                                </div>
                                <!-- Pharmacies -->
                                <div *ngIf="pharmacies?.length != 0">
                                    <mat-option *ngFor="let pharmacy of pharmacies | orderBy: 'denomination'"
                                        (click)="onSelectPharmacy(pharmacy)">
                                        <span class="tw-text-xs tw-mr-2">
                                            <i class="ri-hospital-line tw-text-slate-800"></i>
                                        </span>
                                        <span class="tw-text-xs tw-text-slate-800">
                                            {{ pharmacy?.nom }}
                                        </span>
                                    </mat-option>
                                </div>

                                <div
                                    *ngIf="(termForm.value.term) && (specialities?.length === 0) && (professionals?.length === 0) && (structures?.length === 0) && (pharmacies?.length === 0)">
                                    <mat-option>
                                        <span class="tw-text-xs tw-text-slate-800">{{ termLoading ?
                                            'Chargement...' : '0 resultat' }}</span>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                        </div>

                    </div>
                    <button
                        class="tw-border-0 tw-bg-[#D00000] tw-w-4/12 tw-h-12 tw-rounded-r tw-shadow-lg tw-text-white hover:tw-bg-[#c70202]"
                        (click)="onClickSearchBtn()">Rechercher</button>
                </div>
                <!-- Filters -->
                <div class="tw-flex tw-justify-center tw-items-center tw-gap-x-3 mt-3">
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect1" [ngModel]="selectedService"
                            (ngModelChange)="onServiceChange($event)">
                            <option selected>{{ selectedService }}</option>
                            <option *ngFor="let service of services" value="{{ service?.name }}">{{
                                service?.name }}</option>
                        </select>
                    </div>
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect2" [ngModel]="selectedInsurer"
                            (ngModelChange)="onInsurerChange($event)">
                            <option selected>{{ selectedInsurer }}</option>
                            <option *ngFor="let insurer of insurers" value="{{ insurer?.name }}">{{
                                insurer?.name }}</option>
                        </select>
                    </div>
                    <div class="tw-w-4/12">
                        <select class="form-control" id="exampleFormControlSelect3" [ngModel]="selectedLang"
                            (ngModelChange)="onLangChange($event)">
                            <option selected>{{ selectedLang }}</option>
                            <option *ngFor="let lang of languages" value="{{ lang?.label }}">{{ lang?.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!response" class="tw-h-screen"></div>
    <!-- DOCTORS W/ SLOTS top padding of 3 -->
    <div *ngIf="response?.data.length !== 0"
        [ngClass]="h.isNavigationSafe() && !h.positionActivated() ? 'tw-mt-[14rem]' : 'tw-mt-[10rem]'" class="w-100">
        <div *ngFor="let data of response?.data"
            class="d-flex flex-column justify-content-center align-items-center tw-mb-6">
            <div class="tw-w-full p-3 tw-bg-white tw-flex tw-content-start tw-items-start">
                <!-- Personal infos -->
                <div class="tw-inline tw-w-6/12 tw-flex tw-flex-col tw-content-between tw-items-start sm:tw-hidden">
                    <div class="tw-flex tw-content-center tw-items-center">
                        <div *ngIf="data?.photos.length == 0"
                            class="tw-rounded-full tw-bg-slate-100 tw-cursor-pointer tw-w-10 tw-h-10 tw-mr-3">
                            <img class="tw-rounded-full tw-w-10 tw-h-10" src="assets/images/users/placeholder.png"
                                alt="avatar">
                        </div>
                        <div *ngIf="data?.photos.length != 0"
                            class="tw-rounded-full tw-cursor-pointer tw-w-10 tw-h-10 tw-mr-3">
                            <img class="tw-rounded-full tw-w-10 tw-h-10" src="data?.photos[0].url" alt="avatar">
                        </div>
                        <span class="tw-text-slate-800 tw-text-md tw-m-0">
                            {{ data?.firstname }} {{ data?.lastname }}
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-col tw-content-start tw-items-start">
                        <p class="tw-m-0 tw-text-slate-500 tw-text-xs tw-mt-2">{{
                            data?.coordonate?.globaladressfield }}</p>
                        <p *ngIf="data?.distance" class="tw-m-0"><span
                                class="tw-mt-1 tw-flex tw-content-start tw-items-center tw-text-xs"><i
                                    class="ri-navigation-fill tw-text-xs tw-mr-1 tw-text-green-300"></i>
                                {{ data?.distance | number: '1.2-2' }} km</span></p>
                        <div class="tw-flex tw-content-start tw-items-center tw-mt-3">
                            <a href="javascript:void(0)" routerLink="/details-professionnel/{{data?.id}}"
                                class="tw-bg-[#2b3c8b] tw-rounded-full tw-py-1 tw-px-3 tw-text-white tw-text-xs hover:tw-bg-blue-800">{{'SHARED.SEE_DETAILS'
                                | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="tw-hidden sm:tw-inline tw-flex tw-content-between tw-items-center sm:tw-w-5/12 lg:tw-w-3/12"
                    style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;">
                    <div class="tw-flex tw-content-start tw-items-start">
                        <div *ngIf="data?.photos.length == 0"
                            class="tw-rounded-full tw-bg-slate-100 tw-cursor-pointer tw-w-12 tw-h-12 tw-mr-4">
                            <img class="tw-rounded-full tw-w-12 tw-h-12" src="assets/images/users/placeholder.png"
                                alt="avatar">
                        </div>
                        <div *ngIf="data?.photos.length != 0"
                            class="tw-rounded-full tw-cursor-pointer tw-w-12 tw-h-12 tw-mr-4">
                            <img class="tw-rounded-full tw-w-12 tw-h-12" src="data?.photos[0].url" alt="avatar">
                        </div>

                        <div class="tw-flex tw-flex-col tw-content-start tw-items-start">
                            <p class="tw-text-slate-800 tw-text-md tw-m-0">
                                {{ data?.firstname }} {{ data?.lastname }}
                            </p>
                            <span *ngFor="let spec of data?.doctorspecializations"
                                class="tw-mr-2 tw-text-xs tw-text-[#D00000]">
                                {{ spec?.name }}
                            </span>
                            <p class="tw-m-0 tw-text-slate-500 tw-text-xs tw-mt-2">{{
                                data?.coordonate?.globaladressfield }}</p>
                            <p *ngIf="data?.distance" class="tw-m-0"><span
                                    class="tw-mt-1 tw-flex tw-content-start tw-items-center tw-text-xs"><i
                                        class="ri-navigation-fill tw-text-xs tw-mr-1 tw-text-green-300"></i>
                                    {{ data?.distance | number: '1.2-2' }} km</span></p>
                            <div class="tw-flex tw-content-start tw-items-center tw-mt-3">
                                <!-- routerLink="/details-professionnel/{{data?.id}}" -->
                                <a href="javascript:void(0)" routerLink="/details-professionnel/{{data?.id}}"
                                    class="tw-bg-[#D00000] tw-rounded-full tw-py-1 tw-px-3 tw-text-white tw-text-xs hover:tw-bg-blue-800">Voir
                                    les détails</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Slots -->
                <div class="tw-hidden sm:tw-flex sm:tw-content-center sm:tw-items-start sm:tw-w-7/12 lg:tw-w-5/12">
                    <div *ngIf="!data?.appointmentbooking"
                        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-content-center tw-items-center">
                        <div class="d-flex flex-column justify-content-center align-items-center"
                            style="height: 200px;">
                            <p class="py-2 px-2 tw-bg-[#2b3c8b] tw-rounded text-white" style="font-size: 0.75rem;">
                                <i class="ri-information-fill text-white tw-align-bottom mr-1
                                        "></i>Ce professionnel ne prend pas de rendez-vous en ligne.
                            </p>
                        </div>
                    </div>
                    <div *ngIf="data?.appointmentbooking" class="tw-w-full tw-flex">
                        <!-- Left arrow -->
                        <div class="tw-w-1/12">
                            <button *ngIf="!data?.isNavLeftPossible" disabled
                                class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"><i
                                    class="ri-arrow-left-s-line tw-text-3xl tw-text-slate-300"></i></button>
                            <button *ngIf="data?.isNavLeftPossible" class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"
                                (click)="navigateLeft(data)"><i class="ri-arrow-left-s-line tw-text-3xl"></i></button>
                        </div>
                        <!-- Slots -->
                        <div class="tw-w-10/12 tw-flex tw-flex-col tw-items-center">
                            <div class="tw-w-full tw-flex tw-content-center tw-items-start">
                                <div *ngFor="let availability of data?.availabilities"
                                    class="tw-w-1/5 tw-flex tw-flex-col tw-content-center tw-items-center">
                                    <p class="tw-text-xs tw-m-0 tw-text-center" style="font-weight: 700;">{{
                                        availability.day }}</p>
                                    <p class="text-slate-800 tw-text-xs tw-m-0 tw-text-center">{{ availability.date
                                        }}
                                        {{
                                        availability.month }}</p>
                                    <div class="tw-mt-4 tw-flex tw-flex-col tw-content-center tw-items-center">
                                        <ng-container *ngFor="let slot of data?.rowNumber; let i = index">
                                            <div *ngIf="availability?.slots[i]">
                                                <div *ngIf="h.isFree(availability?.slots[i])">
                                                    <div *ngIf="!h.isObsolete(availability?.slots[i])"
                                                        (click)="onSelectSlot(content, booking, data, availability?.slots[i])"
                                                        class="tw-bg-blue-100 tw-px-2 tw-py-3 tw-text-slate-800 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center tw-cursor-pointer hover:tw-bg-yellow-300">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                    <div *ngIf="h.isObsolete(availability?.slots[i])"
                                                        class="tw-bg-slate-100 tw-px-2 tw-py-3 tw-text-slate-300 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                </div>
                                                <div *ngIf="h.isBusy(availability?.slots[i])">
                                                    <div *ngIf="h.isObsolete(availability?.slots[i])"
                                                        class="tw-bg-slate-100 tw-px-2 tw-py-3 tw-text-slate-300 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                    <div *ngIf="!h.isObsolete(availability?.slots[i])"
                                                        ngbTooltip="Créneau réservé" container="body"
                                                        class="tw-bg-[#2b3c8b] tw-px-2 tw-py-3 tw-text-slate-100 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                </div>
                                                <div *ngIf="h.isDisabled(availability?.slots[i])">
                                                    <div
                                                        class="tw-bg-white tw-px-3 tw-py-3 tw-text-4xl tw-text-slate-300 tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        -</div>
                                                </div>
                                            </div>

                                            <div *ngIf="!availability?.slots[i]"
                                                class="tw-bg-white tw-px-3 tw-py-3 tw-text-4xl tw-text-slate-300 tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                -</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <!-- See more -->
                            <div *ngIf="data?.isExpendable" class="tw-flex tw-content-center tw-items-center">
                                <button *ngIf="!data?.isCollapsed"
                                    class="tw-m-0 tw-border-0 tw-rounded tw-bg-slate-200 tw-py-1 tw-px-3 hover:tw-bg-slate-300"
                                    (click)="voirPlus(data)"><span class="tw-text-xs tw-font-bold">Voir plus
                                        d'horaires</span></button>
                                <button *ngIf="data?.isCollapsed"
                                    class="tw-m-0 tw-border-0 tw-rounded tw-bg-slate-200 tw-py-1 tw-px-3 hover:tw-bg-slate-300"
                                    (click)="voirMoins(data)"><span class="tw-text-xs tw-font-bold">Voir moins
                                        d'horaires</span></button>
                            </div>
                        </div>
                        <!-- Right arrow -->
                        <div class="tw-w-1/12 tw-text-right">
                            <button class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white" (click)="navigateRight(data)"><i
                                    class="ri-arrow-right-s-line tw-text-3xl"></i></button>
                        </div>
                    </div>

                </div>
                <!-- 4 [MAP] -->
                <div class="tw-w-6/12 tw-flex tw-content-center tw-items-center tw-bg-slate-100 sm:tw-hidden lg:tw-inline lg:tw-w-6/12 lg:tw-flex lg:tw-content-center lg:tw-items-center lg:tw-bg-slate-100"
                    style="height: 200px; border-radius: 8px; overflow: hidden;">
                    <agm-map [latitude]="data?.coordonate?.latitude" [longitude]="data?.coordonate?.longitude"
                        [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
                        style="width: 100%; height: 100%; border-radius: 8px; overflow: hidden;">

                        <agm-marker [iconUrl]="iconUrl" [latitude]="data?.coordonate?.latitude"
                            [longitude]="data?.coordonate?.longitude" [markerDraggable]="false">
                            <agm-info-window>
                                <span class="fw-bold">{{ data?.name }}</span>
                            </agm-info-window>
                        </agm-marker>

                    </agm-map>
                </div>
            </div>
            <div class="w-100 tw-h-6 tw-bg-gradient-to-r tw-from-black"></div>
        </div>
    </div>

    <!-- Pagination -->
    <div class="w-100 d-flex justify-content-end tw-mb-8">
        <button *ngIf="response?.prev_page_url"
            class="tw-m-0 tw-border-0 tw-bg-white hover:tw-bg-slate-200 tw-py-3 tw-px-8 tw-shadow-1 tw-mr-3"
            (click)="previousPage()"><span class="font-bold text-2xl">Précédent</span></button>
        <button *ngIf="!response?.prev_page_url" class="tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-8 tw-shadow-1"
            disabled><span class="font-bold text-2xl">Précédent</span></button>

        <button *ngIf="response?.next_page_url"
            class="tw-text-right tw-m-0 tw-border-0 tw-bg-white hover:tw-bg-slate-200 tw-py-3 tw-px-8 tw-shadow-1"
            (click)="nextPage()"><span class="font-bold text-2xl">Suivant</span></button>
        <button *ngIf="!response?.next_page_url"
            class="tw-text-right tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-8 tw-shadow-1" disabled><span
                class="font-bold text-2xl">Suivant</span></button>
    </div>

</div>

<!-- Modals -->
<!-- LOGIN/SIGNUP -->
<ng-template #content let-modal>
    <div *ngIf="login">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Connexion</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Form -->
            <form class="form-horizontal w-100" [formGroup]="loginForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="form-group">
                    <ngx-intl-tel-input [cssClass]="'border tw-border-[#ced4da] py-3 w-100 rounded'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true" [maxLength]="15" [separateDialCode]="separateDialCode" name="phone4"
                        formControlName="phone" [searchCountryPlaceholder]="'Recherchez un pays'">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && log.phone.errors" class="invalid-feedback">
                        <div *ngIf="log.phone.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                        <div *ngIf="log.phone.errors.login">{{'SHARED.FIELD_REQUIRED' | translate}}</div>
                    </div>
                </div>

                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon tw-text-[#D00000]" style="color:#d00000;"></i>
                    <label for="password">{{'HEADER.LOGIN.PASS' | translate}}</label>
                    <input type="password" formControlName="password" class="form-control" id="password"
                        [ngClass]="{ 'is-invalid': submitted && log.password.errors }" placeholder="Mot de passe" />
                    <div *ngIf="submitted && log.password.errors" class="invalid-feedback">
                        <div *ngIf="log.password.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                    </div>
                </div>

                <div class="mt-4 text-center">
                    <button class="btn w-md waves-effect waves-light tw-bg-[#D00000] text-white" type="button"
                        (click)="onSubmit()">{{'HEADER.LOGIN.TO_LOGIN' | translate}}</button>
                </div>

                <div class="mt-4 text-center">
                    <a (click)="switchToReset()" class="text-muted tw-cursor-pointer"><i class="mdi mdi-lock mr-1"></i>
                        {{'HEADER.LOGIN.FORGOT' | translate}}</a>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="signup">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Inscription</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="p-2 mt-2">
                <form class="form-horizontal" [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">
                    <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Inscription réussie.
                    </ngb-alert>
                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="prenom"><span class="mr-2 text-danger">*</span> Prénom</label>
                        <input type="text" formControlName="prenom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.prenom.errors }" id="prenom" placeholder="">
                        <div *ngIf="submitted && sig.prenom.errors" class="invalid-feedback">
                            <div *ngIf="sig.prenom.errors.required">Vous devez entrer un prénom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="nom"> <span class="mr-2 text-danger">*</span>Nom</label>
                        <input type="text" formControlName="nom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.nom.errors }" id="nom" placeholder="">
                        <div *ngIf="submitted && sig.nom.errors" class="invalid-feedback">
                            <div *ngIf="sig.nom.errors.required">Vous devez entrer un nom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-mail-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="email"> <span class="mr-2 text-danger">*</span>Email</label>
                        <input type="email" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.email.errors }" id="email" placeholder="">
                        <div *ngIf="submitted && sig.email.errors" class="invalid-feedback">
                            <div *ngIf="sig.email.errors.required">Vous devez entrer un email.</div>
                            <div *ngIf="sig.email.errors.email">L'addresse email doit être valide.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-phone-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="tel"> <span class="mr-2 text-danger">*</span>Téléphone</label>
                        <input type="text" formControlName="tel" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.tel.errors }" id="tel" placeholder="">
                        <div *ngIf="submitted && sig.tel.errors" class="invalid-feedback">
                            <div *ngIf="sig.tel.errors.required">Vous devez entrer un numero de téléphone.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-home-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="addresse"> <span class="mr-2 text-danger">*</span>Addresse</label>
                        <input type="text" formControlName="addresse" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.addresse.errors }" id="addresse" placeholder="">
                        <div *ngIf="submitted && sig.addresse.errors" class="invalid-feedback">
                            <div *ngIf="sig.addresse.errors.required">Vous devez entrer une adresse.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="password"> <span class="mr-2 text-danger">*</span>Mot de passe</label>
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.password.errors }" id="password" placeholder="">
                        <div *ngIf="submitted && sig.password.errors" class="invalid-feedback">
                            <div *ngIf="sig.password.errors.required">Vous devez entrer un mot de passe.</div>
                        </div>
                    </div>

                    <p class="tw-m-0"> <span class="mr-2 text-danger">*</span>Sexe</p>
                    <div class="tw-flex tw-mt-2">
                        <div class="form-group custom-control custom-radio tw-mr-3">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="m" value="M">
                            <label class="custom-control-label" for="m">Masculin</label>
                        </div>
                        <div class="form-group custom-control custom-radio">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="f" value="F">
                            <label class="custom-control-label" for="f">Féminin</label>
                        </div>
                    </div>


                    <div class="text-center">
                        <button class="btn w-md waves-effect waves-light text-white" type="submit"
                            style="background-color:#d00000;">S'inscrire</button>
                    </div>

                    <div class="mt-4 text-center">
                        <p class="mb-0">En vous inscrivant, vous acceptez les <a target="_blank" routerLink="/cgu"
                                style="color:#d00000;">Conditions
                                d'Utilisation</a></p>
                    </div>
                </form>
            </div>

            <div class="mt-5 text-center">
                <p>Vous avez déjà un compte ? <a (click)="signup=!signup" class="font-weight-medium tw-cursor-pointer"
                        style="color:#d00000;">
                        CONNECTEZ-VOUS ICI</a> </p>
            </div>
        </div>
    </div>

    <div *ngIf="reset">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Réinitialisation mot de
                passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="resetForm">
            <p class="m-0">Renseignez votre numéro de téléphone,</p>
            <p class="m-0">vous recevrez le nouveau mot de passe par SMS.</p>
            <!-- <div class="tw-flex">
            <div class="form-group custom-control custom-radio tw-mr-3">
              <input type="radio" formControlName="reset" class="custom-control-input" id="sms" value="SMS">
              <label class="custom-control-label" for="sms">par SMS</label>
            </div>
            <div class="form-group custom-control custom-radio">
              <input type="radio" formControlName="reset" class="custom-control-input" id="email" value="email">
              <label class="custom-control-label" for="email">par Email</label>
            </div>
          </div> -->
            <input class="form-control mt-2" formControlName="tel" type="tel" placeholder="Entrez votre numéro">
            <!-- <input *ngIf="resetForm.value.reset === 'email'" class="form-control mt-2" formControlName="email"
            type="email" placeholder="Entrez votre email"> -->
        </div>
        <div class="modal-footer">
            <button (click)="cancelReset()" type="button" class="btn btn-outline-dark mr-2">Annuler</button>
            <button (click)="resetPwd()" type="button" class="btn text-white"
                style="background-color:#d00000;">Confirmer</button>
        </div>
    </div>

    <div *ngIf="verif">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Vérification compte</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous allez recevoir un code par SMS.</p>
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez le code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="cancelVerif()" type="button" class="btn btn-outline-dark mr-2">Retour</button>
            <button type="button" class="btn text-white" (click)="verify()"
                style="background-color:#d00000;">Vérifier</button>
        </div>
    </div>

    <div *ngIf="firstConnect">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Nouveau mot de passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous devez modifier votre mot de passe.</p>
            <form class="form-horizontal" [formGroup]="pwdForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <!-- Ancien -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="old">Ancien mot de passe</label>
                            <input formControlName="oldPwd" type="password" class="form-control" id="old"
                                [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
                            <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                                    l'ancien mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Nouveau -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="new">Nouveau mot de passe</label>
                            <input formControlName="newPwd" type="password" class="form-control" id="new"
                                [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
                            <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                                    nouveau mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Confirmation -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                                passe</label>
                            <input formControlName="confirmNewPwd" type="password" class="form-control"
                                id="confirmNewPwd" [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
                            <div *ngIf="submitted && p.confirmNewPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                                    mot de passe.
                                </div>
                                <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                                    La confirmation est incorrecte.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="changePassword()" class="btn text-white col-lg-6"
                style="background-color: #2b3c8b;">Enregistrer les
                changements</button>
        </div>
    </div>
</ng-template>

<!-- Verification -->
<ng-template #verification let-modal2>
    <div class="modal-header">
        <h4 class="modal-title">Code de vérification</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous allez recevoir un code par SMS.</p>
        <div class="p-2 mt-2">
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="verify()">Vérifier</button>
    </div>
</ng-template>

<!-- Slot booking -->
<ng-template #booking let-modal2>
    <div class="modal-header bg-light">
        <div class="d-flex flex-column">
            <p class="modal-title">Réserver un rendez-vous avec
                <span *ngIf="selectedProfessional?.title">le&nbsp;</span>
                <span *ngIf="selectedProfessional?.title" style="color:#E69C24">{{ selectedProfessional?.title?.name
                    }}&nbsp;</span>
                <span style="color:#E69C24">{{ selectedProfessional?.firstname }} {{ selectedProfessional?.lastname
                    }}</span>
            </p>
            <p class="tw-m-0 tw-text-xs tw-font-bold tw-text-black tw-underline"> le {{ selectedSlot?.timeslotdate |
                date:'fullDate' }}
                de <span class="tw-text-lg" style="color:#2b3c8b">{{ selectedSlot?.starthour | trimSlot }}</span> à
                <span class="tw-text-lg" style="color:#2b3c8b">{{ selectedSlot?.endhour | trimSlot }}</span>
            </p>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <app-book-slot [professional]="selectedProfessional" [slot]="selectedSlot"></app-book-slot>
    </div>
</ng-template>