import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AutocompleteService } from "src/app/core/services/autocomplete.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  timeout,
} from "rxjs/operators";
import { of, Subject } from "rxjs";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";

@Component({
  selector: "app-accueil",
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"],
})
export class AccueilComponent implements OnInit, AfterViewInit {
  breadCrumbItems: Array<{}>;
  term: any;
  places = [];
  specialities = [];
  professionals = [];
  structures = [];
  pharmacies = [];
  specialityIsSelected = false;
  // Form submit
  placeForm: FormGroup;
  termForm: FormGroup;
  place;
  specialite;
  placeLoading = false;
  termLoading = false;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private aC: AutocompleteService,
    private h: HelpersService,
    private route: ActivatedRoute
  ) {}

  closed$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const place = params.get("place");
      const speciality = params.get("speciality");

      if (place !== null) {
        this.placeForm.patchValue({ name: place });
      }
      if (speciality !== null) {
        this.termForm.patchValue({ term: speciality });
      }
    });
  }

  ngOnInit(): void {
    this.placeForm = this.formBuilder.group({
      name: ["", [Validators.nullValidator]],
    });

    this.termForm = this.formBuilder.group({
      term: ["", [Validators.nullValidator]],
    });

    this.searchPlaceEventListener();
    this.searchTermEventListener();
  }

  goToProfessionals() {
    this.router.navigate(["/professionnels"]);
  }
  goToStructures() {
    this.router.navigate(["/structures"]);
  }
  goToPharmacies() {
    this.router.navigate(["/pharmacies"]);
  }

  searchPlaceEventListener() {
    this.placeForm
      .get("name")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.place !== this.placeForm.value.name) {
          this.placeLoading = true;
          this.aC
            .placeautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.placeLoading = false;
              this.places = res;
            })
            .catch((e) => {
              this.placeLoading = false;
              this.places = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.place = "";
          this.places = [];
        }
      });
  }

  searchTermEventListener() {
    this.termForm
      .get("term")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.specialite !== this.termForm.value.term) {
          this.termLoading = true;
          this.aC
            .termautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.termLoading = false;
              this.specialities = res.specialites;
              this.structures = res.structures;
              this.professionals = res.docteurs;
              this.pharmacies = res.pharmacies;
            })
            .catch((e) => {
              this.termLoading = false;
              this.specialities = [];
              this.structures = [];
              this.professionals = [];
              this.pharmacies = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.specialite = "";
          this.specialities = [];
          this.structures = [];
          this.professionals = [];
          this.pharmacies = [];
        }
      });
  }

  onClickSearchBtn() {
    if (
      !this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      this.router.navigate(["/professionnels"]);
    } else if (
      this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      let place = this.placeForm?.get("name").value as string;
      place = this.h.removeSpaceAndAccent(place);
      this.router.navigate([`/professionnels/pos/${place}`]);
    } else if (
      this.termForm?.get("term").value &&
      !this.placeForm?.get("name").value
    ) {
      let speciality = this.termForm.get("term").value as string;
      speciality = this.h.removeSpaceAndAccent(speciality);
      this.router.navigate([`/professionnels/speciality/${speciality}`]);
    } else if (
      this.termForm?.get("term").value &&
      this.placeForm?.get("name").value
    ) {
      if (this.specialityIsSelected) {
        let place = this.placeForm.get("name").value as string;
        let speciality = this.termForm.get("term").value as string;
        place = this.h.removeSpaceAndAccent(place);
        speciality = this.h.removeSpaceAndAccent(speciality);
        this.router.navigate([`/professionnels/${speciality}/${place}`]);
      }
    }
  }

  onSelectSpecialite(specialite) {
    this.specialityIsSelected = true;
    this.termForm.setValue({ term: specialite });
  }

  onSelectStructure(structure) {
    let id = structure.id;
    this.router.navigate([`/details-structure/${id}`]);
  }
  onSelectProfessional(professional) {
    let id = professional.id;
    this.router.navigate([`/details-professionnel/${id}`]);
  }
  onSelectPharmacy(pharmacy) {
    let id = pharmacy.id;
    this.router.navigate([`/details-pharmacie/${id}`]);
  }

  onSelectPlace(place) {
    this.placeForm.setValue({ name: place });
  }

  handleClosed(event) {
    this.closed$.next();
  }
}
