import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-activite",
  templateUrl: "./activite.component.html",
  styleUrls: ["./activite.component.scss"],
})
export class ActiviteComponent implements OnInit {
  term: any;
  logs = [];

  // paginateData: any[] = [];
  // page$ = 1;
  // pageSize = 10;
  // collectionSize = 0;
  // startIndex = (this.page$ - 1) * this.pageSize + 1;
  // endIndex = (this.page$ - 1) * this.pageSize + this.pageSize;
  // offset = this.page$ - 1;
  // search = "";

  constructor(
    private usr: UserProfileService,
    private loader: NgxUiLoaderService,
    private h: HelpersService
  ) {}

  ngOnInit(): void {
    this.getUserLogs();
    // this._fetchData(this.offset, this.pageSize, this.search);
  }

  getUserLogs() {
    this.loader.start();
    this.usr
      .getUserLogs()
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.logs = r;
        // this.collectionSize = r.totalElements;
      })
      .catch((e) => {
        this.loader.stop();
        if (e.error.errorCode === "EXPIRED_TOKEN") {
          this.loader.start();
          this.h.handleExpiredToken().then((_) => {
            this.usr
              .getUserLogs()
              .pipe(timeout(30000))
              .toPromise()
              .then((r) => {
                this.loader.stop();
                this.logs = r;
              })
              .catch((e) => {
                this.loader.stop();
                this.h.handleRequestError(e);
              });
          });
        } else {
          this.h.handleRequestError(e);
        }
      });
  }
}
