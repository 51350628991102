import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { LayoutComponent } from './layouts/layout/layout.component';
import { PasseportLayoutComponent } from './layouts/passeport-layout/passeportlayout.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./public/pages.module').then(m => m.PagesModule) },

  // Add auth guard: canActivate: [AuthGuard]
  { path: 'passeport', component: PasseportLayoutComponent, loadChildren: () => import('./passeport/passeport.module').then(m => m.PasseportModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
