import { HttpHeaders } from "@angular/common/http";

export const headers = {
  /**
   * Headers with referentiel JWT token.
   * @returns
   */
  jwt: () => {
    let publicToken = sessionStorage.getItem("publicToken");

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${publicToken}`,
      }),
    };
  },

  /**
   * Headers with medical suite oath token.
   * @returns
   */
  passport: () => {
    let passportToken = sessionStorage.getItem("passportToken");

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${passportToken}`,
      }),
    };
  },

  passportWithBody: (body) => {
    let passportToken = sessionStorage.getItem("passportToken");

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${passportToken}`,
      }),
      body: body,
    };
  },

  passportTextResp: () => {
    let passportToken = sessionStorage.getItem("passportToken");

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${passportToken}`,
      }),
      responseType: "application/json;charset=ISO-8859-1" as "json",
    };
  },
};
