<div class="row mb-4" style="background-color: #eef2f6">
    <div style="width: 80%; margin: auto; background: white; padding: 30px; line-height: 40px;">
        <h3 class="text-center">{{'SHARED.TERMS_CONDITIONS' | translate}}</h3>
        <hr style="opacity: 0.4;">
        <p>
            {{'PAGES.CGU.INTRO' | translate}}
        </p>

        <h4>{{'PAGES.CGU.A1_TITLE' | translate}}</h4>
        <p class="mb-0">{{'PAGES.CGU.A1_1' | translate}}</p>
        <p class="mb-0">{{'PAGES.CGU.A1_2' | translate}}</p>
        <p class="mb-0">{{'PAGES.CGU.A1_3' | translate}}</p>
        <p class="mb-0">{{'PAGES.CGU.A1_4' | translate}}</p>
        <p>{{'PAGES.CGU.A1_5' | translate}}</p>

        <h4>{{'PAGES.CGU.A2_TITLE' | translate}}</h4>
        <p class="mb-0">{{'PAGES.CGU.A2_1' | translate}}</p>
        <ul>
            <li>{{'PAGES.CGU.GEOLOCATION' | translate}}</li>
            <li>{{'PAGES.CGU.PAYMENT' | translate}}</li>
            <li>{{'PAGES.CGU.APPT' | translate}}</li>
            <li> {{'PAGES.CGU.SYNCH' | translate}}</li>
        </ul>
        <p>{{'PAGES.CGU.A2_2' | translate}}</p>

        <h3>{{'PAGES.CGU.A3_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A3_1' | translate}}</p>

        <h3>{{'PAGES.CGU.A4_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A4_1' | translate}}</p>

        <h3>{{'PAGES.CGU.A5_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A5_1' | translate}}</p>

        <h3>{{'PAGES.CGU.A6_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A6_1' | translate}}</p>

        <h3>{{'PAGES.CGU.A7_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A7_1' | translate}}</p>

        <h3>{{'PAGES.CGU.A8_TITLE' | translate}}</h3>
        <p>{{'PAGES.CGU.A8_1' | translate}}</p>
    </div>
</div>