<div class="row">
    <div class="col-sm-12 col-md-12">
        <div id="tickets-table_filter" class="dataTables_filter text-md-left"><label
                class="d-inline-flex align-items-center">Rechercher:
                <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
    </div>
</div>
<div class="card tw-h-[50vh] tw-overflow-auto">
    <div class="card-body">
        <div class="table-responsive tw-bg-white">
            <table class="table table-centered">
                <thead class="thead-light">
                    <tr>
                        <th>Date</th>
                        <th>Contenu de l'activité</th>
                        <th>Type d'activité</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="logs.length !== 0">
                    <tr *ngFor="let log of logs|filter:term">
                        <td>{{log?.activityDate}}</td>
                        <td>{{log?.content}}</td>
                        <td>{{log?.type}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="logs.length === 0">
                    <tr>
                        <td colspan="4" class="surface-100 text-center">Aucune donnée disponible.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>