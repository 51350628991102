import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { of, Subject } from "rxjs";
import { AutocompleteService } from "src/app/core/services/autocomplete.service";
import { environment } from "src/environments/environment";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  timeout,
} from "rxjs/operators";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import Swal from "sweetalert2";
import { AuthService } from "src/app/core/services/authentification.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-pharmacies-list",
  templateUrl: "./pharmacies-list.component.html",
  styleUrls: ["./pharmacies-list.component.scss"],
})
export class PharmaciesListComponent implements OnInit, AfterViewInit {
  breadCrumbItems: Array<{}>;
  term: any;
  search: any;
  zoom: number = 15;
  iconUrl: {
    scaledSize: {
      width: 50;
      height: 50;
    };
  };
  user: any;
  response;
  query = {};
  params = {};

  selectedService = "Filtrer par service (effacer le filtre)";
  selectedInsurer = "Filtrer par assurance (effacer le filtre)";
  selectedLang = "Filtrer par langue (effacer le filtre)";

  places: [] = [];
  specialities: [] = [];
  professionals: [] = [];
  structures: [] = [];
  pharmacies: [] = [];
  services: [] = [];
  insurers: [] = [];
  languages: [] = [];

  specialityIsSelected = false;
  positionIsActivated: boolean;

  placeForm: FormGroup;
  termForm: FormGroup;

  closed$ = new Subject<void>();

  initiated = false;

  latitude = "null";
  longitude = "null";
  place;
  specialite;
  placeLoading = false;
  termLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aC: AutocompleteService,
    public formBuilder: FormBuilder,
    public h: HelpersService,
    private loader: NgxUiLoaderService,
    private http: HttpClient,
    public auth: AuthService,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.initiated = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      const place = params.get("place");
      const speciality = params.get("speciality");

      if (place !== null) {
        this.placeForm.patchValue({ name: place });
      }
      if (speciality !== null) {
        this.termForm.patchValue({ term: speciality });
      }
    });
  }

  ngOnInit(): void {
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.getTranslations();
    // });
    // this.getTranslations();
    window.scroll(0, 0);
    let token = sessionStorage.getItem("publicToken");
    if (!token) {
      this.auth
        .getPublicJwtToken("francis.ngom@eyone.net", "passe")
        .then((_) => this.ngOnInit());
    } else {
      this.breadCrumbItems = [
        { label: environment.appName },
        { label: "Pharmacies", active: true },
      ];
      this.placeForm = this.formBuilder.group({
        name: ["", [Validators.nullValidator]],
      });
      this.termForm = this.formBuilder.group({
        term: ["", [Validators.nullValidator]],
      });

      this.loader.start();
      this.route.queryParams.subscribe(async (params) => {
        const page = params["page"];
        const service = params["service"];
        const assurance = params["assurance"];
        const langue = params["langue"];

        if (page !== undefined) {
          let token = sessionStorage.getItem("publicToken");
          let header = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          if (sessionStorage.getItem("position") === "true") {
            if (this.initiated) this.loader.start();
            navigator.geolocation.getCurrentPosition((pos) => {
              this.latitude = pos.coords.latitude.toString();
              this.longitude = pos.coords.longitude.toString();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.queryPharmacies +
                    `?latitude=${this.latitude}&longitude=${this.longitude}&page=${page}`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((res) => {
                  this.response = res;
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }),
              (e) => console.error(e);
          } else {
            if (this.initiated) this.loader.start();
            this.http
              .get<any>(
                environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=null&longitude=null&page=${page}`,
                { headers: header }
              )
              .pipe(timeout(30000))
              .toPromise()
              .then((res) => {
                this.response = res;
                this.loader.stop();
              })
              .catch((e) => {
                this.h.handleRefRequestError(e);
                this.loader.stop();
              });
          }
        } else if (service || assurance || langue) {
          let token = sessionStorage.getItem("publicToken");
          let header = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          let path;
          if (sessionStorage.getItem("position") === "true") {
            if (this.initiated) this.loader.start();
            navigator.geolocation.getCurrentPosition((pos) => {
              this.latitude = pos.coords.latitude.toString();
              this.longitude = pos.coords.longitude.toString();
              if (!service && !langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}`;
              }
              if (service && !langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}`;
              }
              if (!service && langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&langue=${langue}`;
              }
              if (!service && !langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&assurance=${assurance}`;
              }
              if (service && langue && !assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}&langue=${langue}`;
              }
              if (service && !langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&service=${service}&assurance=${assurance}`;
              }
              if (!service && langue && assurance) {
                path =
                  environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=${this.latitude}&longitude=${this.longitude}&langue=${langue}&assurance=${assurance}`;
              }
              this.http
                .get<any>(path, { headers: header })
                .pipe(timeout(30000))
                .toPromise()
                .then((r) => {
                  this.response = r;
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }),
              (e) => console.error(e);
          } else {
            let path = this.h.pharmaciesSalQueryPath(
              service,
              assurance,
              langue
            );
            if (this.initiated) this.loader.start();
            this.http
              .get<any>(path, { headers: header })
              .pipe(timeout(30000))
              .toPromise()
              .then((res) => {
                this.response = res;
                this.loader.stop();
              })
              .catch((e) => {
                this.h.handleRefRequestError(e);
                this.loader.stop();
              });
          }
        } else {
          let token = sessionStorage.getItem("publicToken");
          let header = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          if (sessionStorage.getItem("position") === "true") {
            if (this.initiated) this.loader.start();
            navigator.geolocation.getCurrentPosition((pos) => {
              this.latitude = pos.coords.latitude.toString();
              this.longitude = pos.coords.longitude.toString();
              this.http
                .get<any>(
                  environment.referentiel +
                    environment.queryPharmacies +
                    `?latitude=${this.latitude}&longitude=${this.longitude}`,
                  { headers: header }
                )
                .pipe(timeout(30000))
                .toPromise()
                .then((res) => {
                  this.response = res;
                  this.loader.stop();
                })
                .catch((e) => {
                  this.h.handleRefRequestError(e);
                  this.loader.stop();
                });
            }),
              (e) => console.error(e);
          } else {
            if (this.initiated) this.loader.start();
            this.http
              .get<any>(
                environment.referentiel +
                  environment.queryPharmacies +
                  `?latitude=null&longitude=null`,
                { headers: header }
              )
              .pipe(timeout(30000))
              .toPromise()
              .then((res) => {
                this.response = res;
                this.loader.stop();
              })
              .catch((e) => {
                this.h.handleRefRequestError(e);
                this.loader.stop();
              });
          }
        }
      });

      this.getAllServices();
      this.getAllInsurers();
      this.getAllLanguages();
    }

    this.searchPlaceEventListener();
    this.searchTermEventListener();
  }

  getTranslations() {
    this.translate.get("SHARED.FILTER_BY_SERVICE").subscribe((text: string) => {
      this.selectedService = text;
    });
    this.translate.get("SHARED.FILTER_BY_INSURER").subscribe((text: string) => {
      this.selectedInsurer = text;
    });
    this.translate.get("SHARED.FILTER_BY_LANG").subscribe((text: string) => {
      this.selectedLang = text;
    });
  }

  searchPlaceEventListener() {
    this.placeForm
      .get("name")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.place !== this.placeForm.value.name) {
          this.placeLoading = true;
          this.aC
            .placeautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.placeLoading = false;
              this.places = res;
            })
            .catch((e) => {
              this.placeLoading = false;
              this.places = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.place = "";
          this.places = [];
        }
      });
  }

  searchTermEventListener() {
    this.termForm
      .get("term")
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (term && this.specialite !== this.termForm.value.term) {
          this.termLoading = true;
          this.aC
            .termautocomplete(term)
            .pipe(timeout(30000))
            .toPromise()
            .then((res) => {
              this.termLoading = false;
              this.specialities = res.specialites;
              this.structures = res.structures;
              this.professionals = res.docteurs;
              this.pharmacies = res.pharmacies;
            })
            .catch((e) => {
              this.termLoading = false;
              this.specialities = [];
              this.structures = [];
              this.professionals = [];
              this.pharmacies = [];
              this.h.handleRequestError(e);
            });
        } else if (!term) {
          this.specialite = "";
          this.specialities = [];
          this.structures = [];
          this.professionals = [];
          this.pharmacies = [];
        }
      });
  }

  previousPage() {
    let path;
    if (this.response.current_page == 2) {
      this.router.navigate(["/pharmacies"]);

      path = environment.referentiel + environment.searchPharmacies;
    } else {
      this.router.navigate(["/pharmacies"], {
        queryParams: { page: this.response.current_page - 1 },
      });

      path = this.response.next_page_url;
    }
    window.scroll(0, 0);
  }

  nextPage() {
    let path;
    this.router.navigate(["/pharmacies"], {
      queryParams: { page: this.response.current_page + 1 },
    });
    window.scroll(0, 0);
  }

  onClickSearchBtn() {
    if (
      !this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      this.router.navigate(["/professionnels"]);
    } else if (
      this.placeForm?.get("name").value &&
      !this.termForm?.get("term").value
    ) {
      let place = this.placeForm?.get("name").value as string;
      place = this.h.removeSpaceAndAccent(place);
      this.router.navigate([`/professionnels/pos/${place}`]);
    } else if (
      this.termForm?.get("term").value &&
      !this.placeForm?.get("name").value
    ) {
      let speciality = this.termForm.get("term").value as string;
      speciality = this.h.removeSpaceAndAccent(speciality);
      this.router.navigate([`/professionnels/speciality/${speciality}`]);
    } else if (
      this.termForm?.get("term").value &&
      this.placeForm?.get("name").value
    ) {
      if (this.specialityIsSelected) {
        let place = this.placeForm.get("name").value as string;
        let speciality = this.termForm.get("term").value as string;
        place = this.h.removeSpaceAndAccent(place);
        speciality = this.h.removeSpaceAndAccent(speciality);
        this.router.navigate([`/professionnels/${speciality}/${place}`]);
      }
    }
  }

  getAllServices() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}services`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.services = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }
  getAllInsurers() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}insurers`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.insurers = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }
  getAllLanguages() {
    let token = sessionStorage.getItem("publicToken");
    let header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (this.initiated) this.loader.start();
    this.http
      .get<any>(`${environment.referentiel}languages`, { headers: header })
      .pipe(timeout(30000))
      .toPromise()
      .then((res) => {
        if (this.initiated) this.loader.stop();
        this.languages = res;
      })
      .catch((e) => {
        if (this.initiated) this.loader.stop();
        this.h.handleRefRequestError(e);
      });
  }

  onSelectSpecialite(specialite) {
    this.specialityIsSelected = true;
    this.termForm.setValue({ term: specialite });
  }

  onSelectStructure(structure) {
    let id = structure.id;
    this.router.navigate([`/details-structure/${id}`]);
  }
  onSelectProfessional(professional) {
    let id = professional.id;
    this.router.navigate([`/details-professionnel/${id}`]);
  }
  onSelectPharmacy(pharmacy) {
    let id = pharmacy.id;
    this.router.navigate([`/details-pharmacie/${id}`]);
  }

  onSelectPlace(place) {
    this.placeForm.setValue({ name: place });
  }

  handleClosed(event) {
    this.closed$.next();
  }

  removeQuery(from) {
    delete this.params[from];
    this.router.navigate(["/pharmacies"], { queryParams: this.params });
  }

  addQuery(to, query) {
    query = this.h.removeSpaceAndAccent(query);
    this.params[to] = query;
    this.query[to] = query;
    this.router.navigate(["/pharmacies"], { queryParams: this.params });
  }

  onServiceChange(val) {
    switch (val) {
      case "Filtrer par service (effacer le filtre)":
        this.removeQuery("service");
        break;
      case "Filter by service (clear filter)":
        this.removeQuery("service");
        break;
      default:
        this.addQuery("service", val);
        break;
    }
  }

  onInsurerChange(val) {
    switch (val) {
      case "Filtrer par assurance (effacer le filtre)":
        this.removeQuery("assurance");
        break;
      case "Filter by insurer (clear filter)":
        this.removeQuery("assurance");
        break;
      default:
        this.addQuery("assurance", val);
        break;
    }
  }

  onLangChange(val) {
    switch (val) {
      case "Filtrer par langue (effacer le filtre)":
        this.removeQuery("langue");
        break;
      case "Filter by language (clear filter)":
        this.removeQuery("langue");
        break;
      default:
        this.addQuery("langue", val);
        break;
    }
  }

  activatePosition() {
    this.h.getPosition();
  }
}
