<footer>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex">
      <div class="tw-bg-green-600 tw-w-6 tw-h-1"></div>
      <div class="tw-bg-yellow-300 tw-w-6 tw-h-1"></div>
      <div class="tw-bg-[#D00000] tw-w-6 tw-h-1"></div>
    </div>
    <div class="bg-white w-100 tw-h-[45px] d-flex justify-content-center align-items-center">
      <span class="font-size-13 tw-text-slate-500 mr-1">2023 ©</span>
      <span class="font-size-13 tw-text-slate-500 mr-1">Dossier Patient Partagé par Eyone,</span>
      <span class="font-size-13">Conditions Générales d'Utilisation</span>
    </div>
    <div class="tw-bg-[#D00000] w-100 tw-h-[45px] d-flex justify-content-center align-items-center">

      <img src="assets/images/fb.svg" height="20">
      <img class="mx-2" src="assets/images/linkedin.svg" height="20">
      <img src="assets/images/twitter.svg" height="20">

    </div>
  </div>
</footer>