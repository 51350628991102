import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

import { AuthService } from "../../../core/services/authentification.service";
import { LanguageService } from "../../../core/services/language.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserProfileService } from "src/app/core/services/user.service";
import { timeout } from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { ConfirmLoginValidator } from "src/app/core/services/confirm-login.validator";
import { environment } from "src/environments/environment";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { COUNTRIES } from "src/app/shared/countries";

@Component({
  selector: "app-horizontaltopbar",
  templateUrl: "./horizontaltopbar.component.html",
  styleUrls: ["./horizontaltopbar.component.scss"],
})
export class HorizontaltopbarComponent implements OnInit {
  user: any;

  configData: any;

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;

  error = "";

  submitted = false;
  successmsg = false;

  login = true;
  signup = false;
  reset = false;
  verif = false;
  firstConnect = false;

  imageAvailable: boolean = false;

  loginForm: FormGroup;
  signupForm: FormGroup;
  activationForm: FormGroup;
  resetForm: FormGroup;
  pwdForm: FormGroup;

  listLang = [
    { text: "Français", flag: "assets/images/flags/french.jpg", lang: "fr" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
  ];
  patientExternalId: any;
  connectedUser: any;
  token: any;
  firstName: any;
  lastName: any;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Senegal,
    CountryISO.CôteDIvoire,
    CountryISO.Cameroon,
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private auth: AuthService,
    public languageService: LanguageService,
    public cookiesService: CookieService,
    private modal: NgbModal,
    public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private h: HelpersService,
    public domsanitizer: DomSanitizer,
    private usr: UserProfileService
  ) {}

  ngOnInit() {
    this.patientExternalId = sessionStorage.getItem("patientExternalId");
    this.firstName = sessionStorage.getItem("firstName");
    this.lastName = sessionStorage.getItem("lastName");

    this.loginForm = this.formBuilder.group({
      // login: ["", [Validators.required]],
      password: ["", [Validators.required]],
      phone: ["", Validators.nullValidator],
    });
    this.signupForm = this.formBuilder.group({
      prenom: ["", Validators.required],
      nom: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      tel: ["", Validators.required],
      addresse: ["", Validators.required],
      password: ["", Validators.required],
      sexe: ["", Validators.required],
    });
    this.activationForm = this.formBuilder.group({
      code: ["", [Validators.nullValidator]],
    });
    this.resetForm = this.formBuilder.group({
      reset: ["", [Validators.nullValidator]],
      email: ["", [Validators.nullValidator]],
      tel: ["", [Validators.nullValidator]],
    });
    this.pwdForm = this.formBuilder.group(
      {
        oldPwd: ["", [Validators.required]],
        newPwd: ["", [Validators.required]],
        confirmNewPwd: ["", [Validators.required]],
      },
      {
        validator: ConfirmLoginValidator("newPwd", "confirmNewPwd"),
      }
    );

    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    this.cookieValue = this.cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/french.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  get log() {
    return this.loginForm.controls;
  }
  get sig() {
    return this.signupForm.controls;
  }
  get v() {
    return this.activationForm.controls;
  }
  get p() {
    return this.pwdForm.controls;
  }

  open(content) {
    this.error = "";
    this.modal.open(content, {
      size: "md",
    });
  }

  onSubmit() {
    this.error = "";
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      let num = this.log.phone.value.e164Number as string;
      num = num.replace("+", "00");
      this.loader.start();
      this.auth
        .login(num, this.log.password.value)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.usr
            .getPatientByUsername(r.userName)
            .pipe(timeout(30000))
            .toPromise()
            .then((p) => {
              this.connectedUser = p;
              this.token = r["access_token"];
              sessionStorage.setItem("passportToken", r["access_token"]);
              sessionStorage.setItem(
                "refreshPassportToken",
                r["refresh_token"]
              );
              if (p.passeportCreationStatusLabel == "A valider par l'usager") {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.verif = true;
                this.submitted = false;
                this.connectedUser = p;
              } else if (
                p.passeportCreationStatusLabel ==
                "A valider par l'usager après création par un tier"
              ) {
                this.loader.stop();
                this.error = "";
                this.login = false;
                this.submitted = false;
                this.firstConnect = true;
                this.connectedUser = p;
              } else if (p.passeportCreationStatusLabel == "Actif") {
                sessionStorage.setItem("login", p["login"]);
                sessionStorage.setItem("firstName", p["firstName"]);
                sessionStorage.setItem("lastName", p["lastName"]);
                sessionStorage.setItem(
                  "passportType",
                  p["passeportManagementType"]
                );
                sessionStorage.setItem(
                  "patientExternalId",
                  p["eyoneExternalId"]
                );
                this.usr
                  .getPassportUserRelatives(p.eyoneExternalId)
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((_) => {
                    this.loader.stop();
                    this.modal.dismissAll();
                    this.router.navigate(["/passeport"]);
                  })
                  .catch((e) => {
                    this.loader.stop();
                    this.h.handleRequestError(e);
                  });
              }
            })
            .catch((e) => {
              this.loader.stop();
              this.h.handleRequestError(e);
            });
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRequestError(e);
        });
    }
  }
  onSubmitRegister() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    } else {
      this.loader.start();
      let user = {
        password: this.signupForm.value.password,
        firstName: this.signupForm.value.prenom,
        lastName: this.signupForm.value.nom,
        sex: this.signupForm.value.sexe,
        mobileNumber: "00221" + this.signupForm.value.tel,
        mail: this.signupForm.value.email,
        birthday: null,
        countryCode: "SN",
        regionCode: "DKR",
        adress: this.signupForm.value.addresse,
        fixeNumber: null,
        base64Photo: null,
        photoExtension: null,
        passeportCreationSource: "PASSEPORT_WEB",
        passeportCreationStatus: "TO_BE_ACTIVATED",
        passeportManagementType: "MAIN",
        passeportOrganismCreator: "Postman",
        passeportUserCreator: "Henri",
        creationValidationMode: "SMS",
        passeportCreationDate: null,
        creationValidationDate: null,
        mainEyoneExternalId: null,
      };
      this.userService
        .register(user)
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.successmsg = true;
          this.login = true;
        })
        .catch((e) => {
          this.loader.stop();
          this.h.handleRequestError(e);
        });
    }
  }

  switchToReset() {
    this.error = "";
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelReset() {
    this.error = "";
    this.resetForm.reset();
    this.submitted = false;
    this.login = !this.login;
    this.reset = !this.reset;
  }
  cancelVerif() {
    this.error = "";
    this.submitted = false;
    this.loginForm.reset();
    this.login = !this.login;
    this.verif = !this.verif;
  }
  doItLater() {
    this.modal.dismissAll();
  }
  async resetPwd() {
    let body = {
      phoneNumber: "00221" + this.resetForm.value.tel,
      sendingChannel: "SMS",
      username: "00221" + this.resetForm.value.tel,
    };
    this.loader.start();
    await this.http
      .post<any>(
        `${environment.base}v1/registration/reInitPatientPassword`,
        body,
        {
          responseType: "text/plain;charset=ISO-8859-1" as "json",
        }
      )
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.modal.dismissAll();
        Swal.fire({
          title: "Succès",
          icon: "success",
          text:
            r + " Un SMS vous a été transmis sur votre numéro de téléphone.",
          showConfirmButton: false,
          timer: 6000,
        });
      })
      .catch((e) => {
        this.loader.stop();
        this.modal.dismissAll();
        this.h.handleRequestError(e);
      });
  }

  openVerification(verification) {
    this.modal.open(verification, { size: "sm" });
  }

  async verify() {
    this.submitted = true;
    if (this.activationForm.invalid) {
      return;
    } else {
      this.loader.start();
      let body = {
        codeIdentifier: this.connectedUser.mobileNumber,
        codeIdentifierType: "PHONE_NUMBER",
        codeType: "ACTIVATE_MAIN_ACCOUNT",
        inputCode: this.activationForm.value.code,
        // managedExternalId: this.connectedUser.eyoneExternalId,
      };
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", `Bearer ${this.token}`);
      await this.http
        .post<any>(`${environment.base}v1/codes-validation`, body, {
          headers: headers,
        })
        .pipe(timeout(30000))
        .toPromise()
        .then((_) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("login", this.connectedUser["login"]);
          sessionStorage.setItem("firstName", this.connectedUser["firstName"]);
          sessionStorage.setItem("lastName", this.connectedUser["lastName"]);
          sessionStorage.setItem(
            "passportToken",
            this.connectedUser["access_token"]
          );
          sessionStorage.setItem(
            "refreshPassportToken",
            this.connectedUser["refresh_token"]
          );
          sessionStorage.setItem(
            "patientExternalId",
            this.connectedUser["eyoneExternalId"]
          );
          sessionStorage.setItem(
            "passportType",
            this.connectedUser["passeportManagementType"]
          );
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  updatePassword() {
    this.submitted = true;
    let userName = sessionStorage.getItem("login");
    let patientExternalId = sessionStorage.getItem("patientExternalId");
    if (patientExternalId !== undefined) {
    }
    if (this.pwdForm.invalid) {
      return;
    } else {
      let body = {
        userName: userName,
        eyoneExternalId: patientExternalId,
        newConfirmedPassWord: this.pwdForm.value.confirmNewPwd,
        newPassword: this.pwdForm.value.newPwd,
        oldPassword: this.pwdForm.value.oldPwd,
      };
      this.loader.start();
      this.usr
        .changePassword(body)
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          Swal.fire({
            icon: "success",
            text: "Mot de passe modifié avec succès.",
            showConfirmButton: false,
            timer: 2000,
          }).then((_) => this.auth.logout());
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  async changePassword() {
    this.submitted = true;
    if (this.pwdForm.invalid) {
      return;
    } else {
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Authorization", "Bearer " + this.token);
      let body = {
        eyoneExternalId: this.connectedUser.eyoneExternalId,
        password: this.pwdForm.value.newPwd,
      };
      this.loader.start();
      await this.http
        .put<any>(
          `${environment.base}api/rest/v1/patients/activation-by-password`,
          body,
          { headers: headers }
        )
        .pipe(timeout(30000))
        .toPromise()
        .then((r) => {
          this.loader.stop();
          this.modal.dismissAll();
          sessionStorage.setItem("passportToken", this.token);
          sessionStorage.setItem("refreshPassportToken", r["refresh_token"]);
          sessionStorage.setItem("login", r["login"]);
          sessionStorage.setItem("firstName", r["firstName"]);
          sessionStorage.setItem("lastName", r["lastName"]);
          sessionStorage.setItem("passportType", r["passeportManagementType"]);
          sessionStorage.setItem("patientExternalId", r["eyoneExternalId"]);
          Swal.fire({
            title: "Succès",
            icon: "success",
            text: "Votre compte a été activé avec succès!",
            showConfirmButton: false,
            timer: 3000,
          }).then((_) =>
            this.router
              .navigate(["/passeport"])
              .then(() => window.location.reload())
          );
        })
        .catch((e) => {
          this.loader.stop();
          this.modal.dismissAll();
          this.h.handleRequestError(e);
        });
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("show");
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }

  createImageFromBlob(image: Blob, element: any) {
    const reader = new FileReader();
    reader.onload = () => {
      element.content = reader.result;
    };
    if (image) {
      reader.readAsDataURL(image);
      this.imageAvailable = true;
    }
  }

  /**
   * Connect to passeport
   */
  goToPasseport(content) {
    this.error = "";
    this.submitted = false;
    this.reset = false;
    this.verif = false;
    this.login = true;
    this.firstConnect = false;
    this.pwdForm.reset();
    this.loginForm.reset();
    this.resetForm.reset();
    this.signupForm.reset();
    this.activationForm.reset();
    this.modal.open(content, {
      size: "md",
    });
  }

  goToContactUs() {
    this.router.navigate(["/contactez-nous"]);
  }

  /**
   * Logout the user
   */
  logout() {
    this.auth.logout();
  }
}
