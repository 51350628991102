import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'Portail public',
    //     link: '/'
    // },
    {
        id: 1,
        label: 'Accueil',
        // icon: '',
        link: '/passeport'
    },
    {
        id: 1,
        label: 'Rendez-vous',
        // icon: '',
        link: '/passeport/rendez-vous'
    },
    {
        id: 1,
        label: 'Assurances',
        // icon: '',
        link: '/passeport/contrats'
    },
    // {
    //     id: 1,
    //     label: 'Mes proches',
    //     // icon: '',
    //     link: 'javascript:void(0)'
    // },
    // {
    //     id: 1,
    //     label: 'Messagerie',
    //     // icon: '',
    //     link: 'javascript:void(0)'
    // },
];

