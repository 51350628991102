import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { ProfileComponent } from "../passeport/profile/profile.component";

import { AccueilComponent } from "./accueil/accueil.component";
import { BookSlotComponent } from "./book-slot/book-slot.component";
import { CguComponent } from "./cgu/cgu.component";
import { PharmaciesListComponent } from "./pharmacies/pharmacies-list/pharmacies-list.component";
import { PharmacyDetailsComponent } from "./pharmacies/pharmacy-details/pharmacy-details.component";
import { DetailsProfessionnelComponent } from "./professionnels/details/details-professionnel.component";
import { ListeComponent } from "./professionnels/liste/liste.component";
import { SearchDoctorsComponent } from "./search-doctors/search-doctors.component";
import { StructureDetailsComponent } from "./structures/structure-details/structure-details.component";
import { StructuresListComponent } from "./structures/structures-list/structures-list.component";
import { TempComponent } from "./temp/temp.component";
import { InscriptionComponent } from "./inscription/inscription.component";

const routes: Routes = [
  { path: "", component: AccueilComponent },
  { path: "recherche", component: SearchDoctorsComponent },

  // Professionnels
  {
    path: "professionnels",
    component: ListeComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "professionnels/pos/:place",
    component: ListeComponent,
  },
  {
    path: "professionnels/speciality/:speciality",
    component: ListeComponent,
  },
  {
    path: "professionnels/:speciality/:place",
    component: ListeComponent,
  },
  {
    path: "details-professionnel/:id",
    component: DetailsProfessionnelComponent,
  },
  {
    path: "inscription",
    component: InscriptionComponent,
  },

  //   Structures
  { path: "structures", component: StructuresListComponent },
  { path: "details-structure/:id", component: StructureDetailsComponent },

  //   Pharmacies
  { path: "pharmacies", component: PharmaciesListComponent },
  { path: "details-pharmacie/:id", component: PharmacyDetailsComponent },

  { path: "reservation", component: BookSlotComponent },
  { path: "profil", component: ProfileComponent, canActivate: [AuthGuard] },
  { path: "cgu", component: CguComponent },
  // { path: 'passeport', loadChildren: () => import('./passport-module/ecommerce.module').then(m => m.EcommerceModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
