<app-dpp-sn-topbar></app-dpp-sn-topbar>

<div *ngIf="!structure" class="container-fluid tw-px-6">
    <p>{{'PAGES.FACILITIES.DETAILS.NO_FAC' | translate}} <span class="font-weight-bold text-dark text-lg">{{ id
            }}</span>.</p>
</div>

<section *ngIf="structure" class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h6 class="mb-0 text-white text-uppercase" style="font-family: Poppins;">
                    Dossier Patient Partagé
                </h6>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Portail Public</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);"
                            routerLink="/structures">
                            Structures de santé</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Détails</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div *ngIf="structure" class="container-fluid">
    <div class="row mx-0">
        <div class="col-12 tw-relative d-flex flex-column justify-content-center align-items-center">
            <div class="tw-absolute tw-h-40 p-4 bg-white w-100" style="top: -35px;">
                <div class="tw-w-full tw-flex tw-content-start tw-items-center">
                    <!-- Avatar -->
                    <div *ngIf="structure?.photos.length == 0"
                        class="tw-h-28 tw-w-28 tw-cursor-pointer tw-mr-4 tw-bg-slate-300 tw-rounded tw-flex tw-content-center tw-items-center">
                        <img class="tw-rounded tw-h-28 tw-w-28" src="assets/images/users/placeholder.png" alt="avatar">
                    </div>
                    <div *ngIf="structure?.photos.length != 0"
                        class="tw-h-28 tw-w-28 tw-cursor-pointer tw-mr-4 tw-bg-slate-300 tw-rounded tw-flex tw-content-center tw-items-center">
                        <img class="tw-rounded tw-h-28 tw-w-28" src="{{ structure?.photos[0]?.url }}" alt="avatar">
                    </div>
                    <!-- Personal infos -->
                    <div class="w-100 tw-flex tw-flex-col tw-content-between tw-items-start">
                        <!-- Name -->
                        <p class="tw-m-0 tw-text-slate-800 tw-text-md tw-font-bold">{{ structure?.name }} </p>
                        <p class="tw-m-0 tw-text-xs tw-text-[#D00000]"> {{ structure?.doctorspecializations[0]?.name }}
                        </p>

                        <!-- Other details -->
                        <div class="w-100 tw-flex tw-flex-col tw-content-start tw-items-start tw-mt-2">
                            <!-- Languages and mail -->
                            <div class="tw-w-3/12 tw-flex tw-flex-col tw-content-start tw-items-start">
                                <div *ngIf="structure?.languages.length != 0"
                                    class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-slate-800 tw-text-md tw-font-bold">{{'SHARED.LANGUAGES'
                                        |
                                        translate}}: </span>
                                    <div *ngFor="let lang of structure?.languages"> <span class="tw-mr-3 tw-text-xs">{{
                                            lang?.label
                                            }}</span></div>
                                </div>
                                <div *ngIf="structure?.coordonate?.email"
                                    class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-xs"><i class="ri-mail-line"></i></span>
                                    <div> <span class="tw-mr-2 tw-text-xs">{{ structure?.coordonate?.email
                                            }}</span></div>
                                </div>
                            </div>
                            <!-- Phone numbers -->
                            <div *ngIf="structure?.coordonate?.mobilenumber || structure?.coordonate?.fixnumber"
                                class="tw-w-3/12">
                                <div class="tw-flex tw-content-start tw-items-center">
                                    <span class="tw-mr-2 tw-text-xs"><i class="ri-phone-line"></i></span>
                                    <div> <span class="tw-mr-4 tw-text-xs">{{ structure?.coordonate?.mobilenumber
                                            }}</span> <span class="tw-text-xs">{{
                                            structure?.coordonate?.fixnumber
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Details section -->
<div *ngIf="!structure" class="tw-h-screen"></div>
<section *ngIf="structure" class="tw-mt-[10rem]">
    <div class="container-fluid mb-5">
        <div
            class="tw-flex tw-content-start tw-items-start tw-flex-col lg:tw-flex-row lg:tw-content-start lg:tw-items-start">
            <div class="tw-w-12/12 sm:tw-w-10/12 lg:tw-w-8/12 lg:tw-pr-8">
                <!-- First block of infos -->
                <div class="tw-flex tw-flex-col tw-bg-white tw-rounded-t tw-py-2">
                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-refund-2-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'PAGES.PROFESSIONALS.DETAILS.ACCEPTED_INSURERS' | translate}}</p>
                        </div>
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-money-dollar-box-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'PAGES.PROFESSIONALS.DETAILS.ACCEPTED_PAYMENTS' | translate}}</p>
                        </div>
                    </div>

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="structure?.insurers?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">{{'SHARED.NA' | translate}}</span>
                                </div>
                                <div *ngIf="structure?.insurers?.length != 0">
                                    <div *ngFor="let ass of structure?.insurers"> <span class="tw-mr-6 tw-text-xs">{{
                                            ass?.name
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="structure?.payments?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">{{'SHARED.NA' | translate}}</span>
                                </div>
                                <div *ngIf="structure?.payments?.length != 0">
                                    <div *ngFor="let payment of structure?.payments"> <span
                                            class="tw-mr-6 tw-text-xs">{{
                                            payment?.name
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="tw-my-2">

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-stethoscope-fill tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'PAGES.PROFESSIONALS.DETAILS.MED_ACTS_PROVIDED' | translate}}</p>
                        </div>
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-pulse-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'PAGES.PROFESSIONALS.DETAILS.APPT_REASONS' | translate}}</p>
                        </div>
                    </div>

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">

                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="structure?.medicalacts?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">{{'SHARED.NA' | translate}}</span>
                                </div>
                                <div *ngIf="structure?.medicalacts?.length != 0">
                                    <div *ngFor="let acte of structure?.medicalacts"> <span
                                            class="tw-mr-6 tw-text-xs">{{
                                            acte?.name
                                            }}</span></div>
                                </div>
                            </div>
                        </div>

                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngIf="structure?.reasons?.length == 0">
                                    <span class="tw-mr-6 tw-text-xs">{{'SHARED.NA' | translate}}</span>
                                </div>
                                <div *ngIf="structure?.reasons?.length != 0">
                                    <div *ngFor="let reason of structure?.reasons"> <span class="tw-mr-6 tw-text-xs">{{
                                            reason?.label
                                            }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Professionnels -->
                <div class="tw-flex tw-flex-col tw-bg-white tw-mt-4 tw-pb-5">
                    <!-- HEAD -->
                    <div class="tw-flex tw-content-start tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-2/12 ri-user-2-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-10/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'SHARED.DOCTORS' | translate}}</p>
                        </div>
                        <!-- <div class="tw-w-6/12"></div> -->
                    </div>

                    <div class="tw-flex tw-content-start tw-items-start tw-px-5">
                        <div *ngIf="structure?.doctors.length === 0"
                            class="tw-w-6/12 tw-flex tw-content-start tw-items-center">
                            <div class="tw-w-2/12"></div>
                            <span class="tw-w-10/12 tw-mr-6 tw-text-xs">{{'SHARED.NO_PRO' | translate}}</span>
                        </div>
                        <div *ngIf="structure?.doctors.length !== 0"
                            class="tw-w-12/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="mt-2 tw-w-10/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div *ngFor="let doctor of structure?.doctors">
                                    <button (click)="onClickDoctor(doctor)"
                                        class="tw-flex tw-flex-col tw-content-center tw-items-center tw-mb-3 tw-mr-3 tw-pb-2 tw-rounded tw-bg-slate-100 hover:tw-bg-slate-200">
                                        <p class="tw-m-0">
                                            <span class="tw-text-md">
                                                {{ doctor?.title?.name }} {{ doctor?.firstname }} {{
                                                doctor?.lastname }}
                                            </span>
                                        </p>
                                        <p class="tw-m-0">
                                            <span *ngFor="let spec of doctor?.doctorspecializations"
                                                class="tw-flex tw-gap-2 tw-bg-blue-900 tw-px-2 tw-py-1 tw-m-1 tw-rounded tw-text-white tw-text-xs">
                                                {{ spec?.name }}
                                            </span>
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Second block of infos -->
                <div class="tw-flex tw-flex-col tw-bg-white tw-mt-4 tw-pb-5">

                    <div class="tw-flex tw-content-between tw-items-center tw-px-5">
                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <i class="tw-w-3/12 ri-refund-2-line tw-text-xl tw-text-slate-500 lg:tw-w-2/12"></i>
                            <p class="tw-m-0 tw-w-9/12 tw-text-[#D00000] tw-font-medium lg:tw-w-10/12">
                                {{'PAGES.PROFESSIONALS.DETAILS.ADDRESS_LOCATION' | translate}}</p>
                        </div>
                        <div class="tw-w-6/12"></div>
                    </div>

                    <div class="tw-flex tw-content-between tw-items-start tw-px-5">

                        <div class="tw-w-6/12 tw-flex tw-content-start tw-items-center tw-m-0">
                            <div class="tw-w-3/12 lg:tw-w-2/12"></div>
                            <div class="tw-w-9/12 tw-flex tw-flex-wrap lg:tw-w-10/12">
                                <div> <span class="tw-mr-4 tw-text-xs">{{
                                        structure?.coordonate.globaladressfield }}</span></div>
                            </div>
                        </div>

                        <div class="tw-w-6/12 tw-flex tw-content-center tw-items-center tw-bg-slate-100 sm:tw-hidden lg:tw-inline lg:tw-w-6/12 lg:tw-flex lg:tw-content-center lg:tw-items-center lg:tw-bg-slate-100"
                            style="height: 200px; border-radius: 8px; overflow: hidden;">
                            <agm-map [latitude]="structure?.coordonate?.latitude"
                                [longitude]="structure?.coordonate?.longitude" [zoom]="zoom" [disableDefaultUI]="false"
                                [zoomControl]="false"
                                style="width: 100%; height: 100%; border-radius: 8px; overflow: hidden;">
                                <agm-marker [iconUrl]="iconUrl" [latitude]="structure?.coordonate?.latitude"
                                    [longitude]="structure?.coordonate?.longitude" [markerDraggable]="false">
                                    <agm-info-window>
                                        <span class="fw-bold">{{ structure?.name }}</span>
                                    </agm-info-window>
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="!selectedDoctor"
                class="tw-bg-white tw-rounded tw-py-3 tw-w-full tw-order-first sm:tw-w-10/12 lg:tw-w-4/12 lg:tw-order-none">
                <p class="tw-m-0 tw-text-center"><span><i
                            class="ri-information-fill tw-mr-1 tw-align-bottom"></i></span>
                    {{'PAGES.FACILITIES.DETAILS.CLICK' | translate}}</p>
            </div>
            <!-- Availabilities -->
            <div *ngIf="selectedDoctor"
                class="tw-bg-white tw-rounded tw-py-3 tw-mb-3 tw-w-full tw-order-first sm:tw-w-10/12 lg:tw-w-4/12 lg:tw-order-none">
                <div class="tw-flex tw-flex-col tw-content-center tw-items-center">
                    <div *ngIf="!selectedDoctor?.appointmentbooking"
                        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-content-center tw-items-center">
                        <div class="d-flex flex-column justify-content-center align-items-center"
                            style="height: 200px;">
                            <p class="py-2 px-2 tw-bg-[#D00000] tw-rounded text-white" style="font-size: 0.75rem;"> <i
                                    class="ri-information-fill text-white tw-align-bottom mr-1
                        "></i> {{'SHARED.NO_ONLINE_APPT' | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="selectedDoctor?.appointmentbooking" class="w-100">
                        <div class="d-flex justify-content-center px-3">
                            <p class="mb-3 text-uppercase font-weight-bold tw-text-lg text-truncate">
                                <span *ngIf="selectedDoctor?.title" style="color:#E69C24">{{ selectedDoctor?.title?.name
                                    }}</span>&nbsp;
                                <span style="color:#E69C24">{{ selectedDoctor?.firstname }} {{ selectedDoctor?.lastname
                                    }}</span>
                            </p>
                        </div>
                        <div class="tw-flex tw-content-center tw-items-start tw-w-full sm:tw-w-8/12 lg:tw-w-full">
                            <div *ngIf="selectedDoctor" class="tw-w-1/12">
                                <button *ngIf="!selectedDoctor?.isNavLeftPossible" disabled
                                    class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"><i
                                        class="ri-arrow-left-s-line tw-text-3xl tw-text-slate-300"></i></button>
                                <button *ngIf="selectedDoctor?.isNavLeftPossible"
                                    class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white" (click)="navigateLeft()"><i
                                        class="ri-arrow-left-s-line tw-text-3xl"></i></button>
                            </div>

                            <div *ngIf="selectedDoctor" class="tw-w-10/12 tw-flex tw-content-between tw-items-start">
                                <div *ngFor="let availability of selectedDoctor?.availabilities"
                                    class="tw-w-1/5 tw-flex tw-flex-col tw-content-center tw-items-center">
                                    <p class="tw-text-xs tw-m-0 tw-text-center" style="font-weight: 700;">{{
                                        availability.day }}</p>
                                    <p class="text-slate-800 tw-text-xs tw-m-0 tw-text-center">{{ availability.date }}
                                        {{
                                        availability.month }}</p>
                                    <div class="tw-mt-4 tw-flex tw-flex-col tw-content-center tw-items-center">
                                        <ng-container *ngFor="let slot of selectedDoctor?.rowNumber; let i = index">
                                            <div *ngIf="availability?.slots[i]">
                                                <div *ngIf="h.isFree(availability?.slots[i])">
                                                    <div *ngIf="!h.isObsolete(availability?.slots[i])"
                                                        (click)="onSelectSlot(content, booking, availability?.slots[i])"
                                                        class="tw-bg-blue-100 tw-px-2 tw-py-3 tw-text-slate-800 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center tw-cursor-pointer hover:tw-bg-yellow-300">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                    <div *ngIf="h.isObsolete(availability?.slots[i])"
                                                        class="tw-bg-slate-100 tw-px-2 tw-py-3 tw-text-slate-300 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                </div>
                                                <div *ngIf="h.isBusy(availability?.slots[i])">
                                                    <div *ngIf="h.isObsolete(availability?.slots[i])"
                                                        class="tw-bg-slate-100 tw-px-2 tw-py-3 tw-text-slate-300 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                    <div *ngIf="!h.isObsolete(availability?.slots[i])"
                                                        ngbTooltip="Créneau réservé" container="body"
                                                        class="tw-bg-[#2b3c8b] tw-px-2 tw-py-3 tw-text-slate-100 tw-text-xs tw-font-bold tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        {{ availability?.slots[i]?.starthour | trimSlot }}
                                                    </div>
                                                </div>
                                                <div *ngIf="h.isDisabled(availability?.slots[i])">
                                                    <div
                                                        class="tw-bg-white tw-px-3 tw-py-3 tw-text-4xl tw-text-slate-300 tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                        -</div>
                                                </div>
                                            </div>

                                            <div *ngIf="!availability?.slots[i]"
                                                class="tw-bg-white tw-px-3 tw-py-3 tw-text-4xl tw-text-slate-300 tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                                -</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedDoctor" class="tw-w-1/12 tw-text-right">
                                <button class="tw-bg-transparent tw-border-0 tw-m-0 tw-p-0" (click)="navigateRight()"><i
                                        class="ri-arrow-right-s-line tw-text-3xl"></i></button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedDoctor?.isExpendable">
                        <button *ngIf="!selectedDoctor?.isCollapsed"
                            class="tw-m-0 tw-border-0 tw-rounded tw-bg-slate-300 tw-py-1 tw-px-3"
                            (click)="voirPlus(selectedDoctor)"><span class="tw-text-xs">{{'SHARED.SEE_MORE' |
                                translate}}</span></button>
                        <button *ngIf="selectedDoctor?.isCollapsed"
                            class="tw-m-0 tw-border-0 tw-rounded tw-bg-slate-300 tw-py-1 tw-px-3"
                            (click)="voirMoins(selectedDoctor)"><span class="tw-text-xs">{{'SHARED.SEE_LESS' |
                                translate}}</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div *ngIf="login">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Connexion</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Form -->
            <form class="form-horizontal w-100" [formGroup]="loginForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="form-group">
                    <ngx-intl-tel-input [cssClass]="'border tw-border-[#ced4da] py-3 w-100 rounded'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true" [maxLength]="15" [separateDialCode]="separateDialCode" name="phone5"
                        formControlName="phone" [searchCountryPlaceholder]="'Recherchez un pays'">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && log.phone.errors" class="invalid-feedback">
                        <div *ngIf="log.phone.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                        <div *ngIf="log.phone.errors.login">{{'SHARED.FIELD_REQUIRED' | translate}}</div>
                    </div>
                </div>

                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon tw-text-[#D00000]" style="color:#d00000;"></i>
                    <label for="password">{{'HEADER.LOGIN.PASS' | translate}}</label>
                    <input type="password" formControlName="password" class="form-control" id="password"
                        [ngClass]="{ 'is-invalid': submitted && log.password.errors }" placeholder="Mot de passe" />
                    <div *ngIf="submitted && log.password.errors" class="invalid-feedback">
                        <div *ngIf="log.password.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                    </div>
                </div>

                <div class="mt-4 text-center">
                    <button class="btn w-md waves-effect waves-light tw-bg-[#D00000] text-white" type="button"
                        (click)="onSubmit()">{{'HEADER.LOGIN.TO_LOGIN' | translate}}</button>
                </div>

                <div class="mt-4 text-center">
                    <a (click)="switchToReset()" class="text-muted tw-cursor-pointer"><i class="mdi mdi-lock mr-1"></i>
                        {{'HEADER.LOGIN.FORGOT' | translate}}</a>
                </div>
            </form>

            <!-- <div class="mt-5 text-center">
                <p>Vous n'avez pas de compte ? <a (click)="login=!login" class="font-weight-medium tw-cursor-pointer"
                        style="color:#d00000;">
                        INSCRIVEZ-VOUS ICI </a> </p>
            </div> -->
        </div>
    </div>

    <div *ngIf="signup">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Inscription</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="p-2 mt-2">
                <form class="form-horizontal" [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">
                    <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Inscription réussie.
                    </ngb-alert>
                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="prenom"><span class="mr-2 text-danger">*</span> Prénom</label>
                        <input type="text" formControlName="prenom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.prenom.errors }" id="prenom" placeholder="">
                        <div *ngIf="submitted && sig.prenom.errors" class="invalid-feedback">
                            <div *ngIf="sig.prenom.errors.required">Vous devez entrer un prénom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="nom"> <span class="mr-2 text-danger">*</span>Nom</label>
                        <input type="text" formControlName="nom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.nom.errors }" id="nom" placeholder="">
                        <div *ngIf="submitted && sig.nom.errors" class="invalid-feedback">
                            <div *ngIf="sig.nom.errors.required">Vous devez entrer un nom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-mail-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="email"> <span class="mr-2 text-danger">*</span>Email</label>
                        <input type="email" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.email.errors }" id="email" placeholder="">
                        <div *ngIf="submitted && sig.email.errors" class="invalid-feedback">
                            <div *ngIf="sig.email.errors.required">Vous devez entrer un email.</div>
                            <div *ngIf="sig.email.errors.email">L'addresse email doit être valide.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-phone-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="tel"> <span class="mr-2 text-danger">*</span>Téléphone</label>
                        <input type="text" formControlName="tel" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.tel.errors }" id="tel" placeholder="">
                        <div *ngIf="submitted && sig.tel.errors" class="invalid-feedback">
                            <div *ngIf="sig.tel.errors.required">Vous devez entrer un numero de téléphone.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-home-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="addresse"> <span class="mr-2 text-danger">*</span>Addresse</label>
                        <input type="text" formControlName="addresse" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.addresse.errors }" id="addresse" placeholder="">
                        <div *ngIf="submitted && sig.addresse.errors" class="invalid-feedback">
                            <div *ngIf="sig.addresse.errors.required">Vous devez entrer une adresse.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="password"> <span class="mr-2 text-danger">*</span>Mot de passe</label>
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.password.errors }" id="password" placeholder="">
                        <div *ngIf="submitted && sig.password.errors" class="invalid-feedback">
                            <div *ngIf="sig.password.errors.required">Vous devez entrer un mot de passe.</div>
                        </div>
                    </div>

                    <p class="tw-m-0"> <span class="mr-2 text-danger">*</span>Sexe</p>
                    <div class="tw-flex tw-mt-2">
                        <div class="form-group custom-control custom-radio tw-mr-3">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="m" value="M">
                            <label class="custom-control-label" for="m">Masculin</label>
                        </div>
                        <div class="form-group custom-control custom-radio">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="f" value="F">
                            <label class="custom-control-label" for="f">Féminin</label>
                        </div>
                    </div>


                    <div class="text-center">
                        <button class="btn w-md waves-effect waves-light text-white" type="submit"
                            style="background-color:#d00000;">S'inscrire</button>
                    </div>

                    <div class="mt-4 text-center">
                        <p class="mb-0">En vous inscrivant, vous acceptez les <a target="_blank" routerLink="/cgu"
                                style="color:#d00000;">Conditions
                                d'Utilisation</a></p>
                    </div>
                </form>
            </div>

            <div class="mt-5 text-center">
                <p>Vous avez déjà un compte ? <a (click)="signup=!signup" class="font-weight-medium tw-cursor-pointer"
                        style="color:#d00000;">
                        CONNECTEZ-VOUS ICI</a> </p>
            </div>
        </div>
    </div>

    <div *ngIf="reset">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Réinitialisation mot de
                passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="resetForm">
            <p class="m-0">Renseignez votre numéro de téléphone,</p>
            <p class="m-0">vous recevrez le nouveau mot de passe par SMS.</p>
            <!-- <div class="tw-flex">
            <div class="form-group custom-control custom-radio tw-mr-3">
              <input type="radio" formControlName="reset" class="custom-control-input" id="sms" value="SMS">
              <label class="custom-control-label" for="sms">par SMS</label>
            </div>
            <div class="form-group custom-control custom-radio">
              <input type="radio" formControlName="reset" class="custom-control-input" id="email" value="email">
              <label class="custom-control-label" for="email">par Email</label>
            </div>
          </div> -->
            <input class="form-control mt-2" formControlName="tel" type="tel" placeholder="Entrez votre numéro">
            <!-- <input *ngIf="resetForm.value.reset === 'email'" class="form-control mt-2" formControlName="email"
            type="email" placeholder="Entrez votre email"> -->
        </div>
        <div class="modal-footer">
            <button (click)="cancelReset()" type="button" class="btn btn-outline-dark mr-2">Annuler</button>
            <button (click)="resetPwd()" type="button" class="btn text-white"
                style="background-color:#d00000;">Confirmer</button>
        </div>
    </div>

    <div *ngIf="verif">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Vérification compte</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous allez recevoir un code par SMS.</p>
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez le code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="cancelVerif()" type="button" class="btn btn-outline-dark mr-2">Retour</button>
            <button type="button" class="btn text-white" (click)="verify()"
                style="background-color:#d00000;">Vérifier</button>
        </div>
    </div>

    <div *ngIf="firstConnect">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Nouveau mot de passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous devez modifier votre mot de passe.</p>
            <form class="form-horizontal" [formGroup]="pwdForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <!-- Ancien -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="old">Ancien mot de passe</label>
                            <input formControlName="oldPwd" type="password" class="form-control" id="old"
                                [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
                            <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                                    l'ancien mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Nouveau -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="new">Nouveau mot de passe</label>
                            <input formControlName="newPwd" type="password" class="form-control" id="new"
                                [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
                            <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                                    nouveau mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Confirmation -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                                passe</label>
                            <input formControlName="confirmNewPwd" type="password" class="form-control"
                                id="confirmNewPwd" [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
                            <div *ngIf="submitted && p.confirmNewPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                                    mot de passe.
                                </div>
                                <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                                    La confirmation est incorrecte.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="changePassword()" class="btn text-white col-lg-6"
                style="background-color: #2b3c8b;">Enregistrer les
                changements</button>
        </div>
    </div>
</ng-template>

<ng-template #verification let-modal2>
    <div class="modal-header">
        <h4 class="modal-title">Code de vérification</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous allez recevoir un code par SMS.</p>
        <div class="p-2 mt-2">
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="verify()">Vérifier</button>
    </div>
</ng-template>

<!-- Slot booking -->
<ng-template #booking let-modal2>
    <div class="modal-header bg-light">
        <div class="d-flex flex-column">
            <p class="modal-title">Réserver un rendez-vous avec
                <span *ngIf="selectedDoctor?.title">le&nbsp;</span>
                <span *ngIf="selectedDoctor?.title" style="color:#E69C24">{{ selectedDoctor?.title?.name
                    }}</span>
                <span style="color:#E69C24">{{ selectedDoctor?.firstname }} {{ selectedDoctor?.lastname
                    }}</span>
            </p>
            <p class="tw-m-0 tw-text-xs tw-font-bold tw-text-black tw-underline"> le {{ selectedSlot?.timeslotdate |
                date:'fullDate' }}
                de <span class="tw-text-lg" style="color:#2b3c8b">{{ selectedSlot?.starthour | trimSlot }}</span> à
                <span class="tw-text-lg" style="color:#2b3c8b">{{ selectedSlot?.endhour | trimSlot }}</span>
            </p>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <app-book-slot [professional]="selectedDoctor" [slot]="selectedSlot"></app-book-slot>
    </div>
</ng-template>