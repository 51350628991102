<app-dpp-sn-topbar></app-dpp-sn-topbar>

<section class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h5 class="mb-0 text-white text-right text-uppercase" style="font-family: Poppins;">
                    Carte Sanitaire
                </h5>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Dossier Patient Partagé</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Détails</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<!-- <div class="tw-h-screen"></div> -->
<div class="container-fluid">
    <div class="row mx-0">
        <div class="col-md-4 d-flex justify-content-center align-items-center">

        </div>
    </div>
</div>