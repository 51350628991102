let latitude;
let longitude;

const DAYS = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."];
const JOURS = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

const MONTHS = [
  "jan.",
  "feb.",
  "mar.",
  "apr.",
  "may",
  "jun.",
  "jul.",
  "aug.",
  "sep.",
  "oct.",
  "nov.",
  "dec.",
];
const MOIS = [
  "janv.",
  "févr.",
  "mars",
  "avril",
  "mai",
  "juin",
  "juil.",
  "aout",
  "sept.",
  "oct.",
  "nov.",
  "déc.",
];

const NOW = new Date(Date.now());
const TODAY = `${NOW.getFullYear()}-${(NOW.getMonth() + 1)
  .toString()
  .padStart(2, "0")}-${NOW.getDate().toString().padStart(2, "0")}`;

const FIVEDAYSBEFORE = new Date(
  new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 5)
);
const FIVEDAYSBEFORESTR = `${FIVEDAYSBEFORE.getFullYear()}-${(
  FIVEDAYSBEFORE.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}-${FIVEDAYSBEFORE.getDate().toString().padStart(2, "0")}`;

export { NOW, DAYS, JOURS, MONTHS, MOIS, TODAY, FIVEDAYSBEFORESTR };
