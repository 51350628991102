import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { timeout } from "rxjs/operators";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";
import { UserProfileService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-comptes-geres",
  templateUrl: "./comptes-geres.component.html",
  styleUrls: ["./comptes-geres.component.scss"],
})
export class ComptesGeresComponent implements OnInit {
  term: any;
  managedAccounts = [];
  patientExternalId: string;

  // paginateData: any[] = [];
  // page$ = 1;
  // pageSize = 10;
  // collectionSize = 0;
  // startIndex = (this.page$ - 1) * this.pageSize + 1;
  // endIndex = (this.page$ - 1) * this.pageSize + this.pageSize;
  // offset = this.page$ - 1;
  // search = "";

  constructor(
    private usr: UserProfileService,
    private loader: NgxUiLoaderService,
    private h: HelpersService
  ) {}

  ngOnInit(): void {
    this.patientExternalId = sessionStorage.getItem("patientExternalId");
    this.getManagedAccounts(this.patientExternalId);
  }

  getManagedAccounts(patientExternalId) {
    this.loader.start();
    this.usr
      .getPassportUserRelatives(patientExternalId)
      .pipe(timeout(30000))
      .toPromise()
      .then((r) => {
        this.loader.stop();
        this.managedAccounts = r;
        // this.collectionSize = r.totalElements;
      })
      .catch((e) => {
        this.loader.stop();
        if (e.error.errorCode === "EXPIRED_TOKEN") {
          this.loader.start();
          this.h.handleExpiredToken().then((_) => {
            this.usr
              .getPassportUserRelatives(patientExternalId)
              .pipe(timeout(30000))
              .toPromise()
              .then((r) => {
                this.loader.stop();
                this.managedAccounts = r;
              })
              .catch((e) => {
                this.loader.stop();
                this.h.handleRequestError(e);
              });
          });
        } else {
          this.h.handleRequestError(e);
        }
      });
  }

  upgrade(managedAccount) {
    Swal.fire({
      title: `Mise à niveau compte de ${managedAccount.firstName}`,
      text: "Renseignez le numéro de téléphone (sans indicatif) qui sera rattaché au passeport.",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonColor: "#2b3c8b",
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      preConfirm: (_) => {
        if (!Swal.getInput().value) {
          Swal.showValidationMessage(
            "Veuillez saisir le numéro de téléphone du candidat."
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          mainEyoneExternalId: this.patientExternalId,
          eyoneExternalId: managedAccount.eyoneExternalId,
          creationValidationMode: "SMS",
          mobileNumber: `00221${Swal.getInput().value}`,
        };
        this.loader.start();
        this.usr
          .upgrade(body)
          .pipe(timeout(30000))
          .toPromise()
          .then((_) => {
            this.loader.stop();
            Swal.fire({
              icon: "success",
              text: `Le compte de ${managedAccount.firstName} a été mis niveau avec succès.`,
              showConfirmButton: false,
              timer: 2000,
            }).then((_) => this.getManagedAccounts(this.patientExternalId));
          })
          .catch((e) => {
            this.loader.stop();
            if (e.error.errorCode === "EXPIRED_TOKEN") {
              this.loader.start();
              this.h.handleExpiredToken().then((_) => {
                this.usr
                  .upgrade(body)
                  .pipe(timeout(30000))
                  .toPromise()
                  .then((r) => {
                    this.loader.stop();
                    Swal.fire({
                      icon: "success",
                      text: `Le compte de ${managedAccount.firstName} a été mis niveau avec succès.`,
                      showConfirmButton: false,
                      timer: 2000,
                    }).then((_) =>
                      this.getManagedAccounts(this.patientExternalId)
                    );
                  })
                  .catch((e) => {
                    this.loader.stop();
                    this.h.handleRequestError(e);
                  });
              });
            } else {
              this.h.handleRequestError(e);
            }
          });
      } else {
        Swal.close();
      }
    });
  }
}
