<app-dpp-sn-topbar></app-dpp-sn-topbar>

<section class="pro-banner">
    <div class="h-100 container-fluid tw-relative d-flex flex-column justify-content-center">
        <div class="row justify-content-center mx-0">
            <div class="col-md-4 px-0">
                <h6 class="mb-0 text-white text-uppercase" style="font-family: Poppins;">
                    Dossier Patient Partagé
                </h6>
            </div>
            <div class="col-md-8 px-0">
                <ol class="brdcrmb float-right m-0">
                    <li class="breadcrumb-item"><a class="text-white" href="javascript: void(0);" routerLink="/">
                            Portail Public</a></li>
                    <li class="breadcrumb-item tw-text-slate-300 active">Création Dossier Patient</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid my-5">
    <div class="row">
        <div class="col-12 d-flex align-items-center">
            <div class="d-flex mr-5">
                <span class="font-size-18">Sexe</span>&nbsp;
                <span class="text-danger">*</span>
            </div>
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="d-flex mr-4">
                        <input class="mr-2" type="radio" value="M" id="sexeM"
                            style="accent-color: #D00000; cursor: pointer; width: 1.5rem;" [(ngModel)]="sexe"
                            (ngModelChange)="onCheck()" />
                        <label class="mb-0" for="sexeM">Masculin</label>
                    </div>
                    <div class="d-flex">
                        <input class="mr-2" type="radio" value="F" id="sexeF"
                            style="accent-color: #D00000; cursor: pointer; width: 1.5rem;" [(ngModel)]="sexe"
                            (ngModelChange)="onCheck()" />
                        <label class="mb-0" for="sexeF">Féminin</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <small *ngIf="submitted && (!feminin && !masculin)" class="text-danger text-right mt-2">** Vous devez
                choisir
                un
                sexe</small>
        </div>
    </div>
    <hr class="w-100 bg-danger my-4">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Prénom(s)</span>&nbsp;
                        <span class="text-danger">*</span>
                    </div>
                    <input type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                    <small *ngIf="submitted && f.firstName.errors" class="text-danger mt-2">** Ce champ est
                        obligatoire</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Nom</span>&nbsp;
                        <span class="text-danger">*</span>
                    </div>
                    <input type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                    <small *ngIf="submitted && f.lastName.errors" class="text-danger mt-2">** Ce champ est
                        obligatoire</small>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Mobile </span>&nbsp;
                        <span class="text-danger">*</span>&nbsp;
                        <span class="font-size-18">(identifiant)</span>
                    </div>
                    <ngx-intl-tel-input [cssClass]="'border tw-border-[#ced4da] py-2 w-100 rounded'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true" [maxLength]="15" [separateDialCode]="separateDialCode" name="phone1"
                        [searchCountryPlaceholder]="'Recherchez un pays'" formControlName="phone">
                    </ngx-intl-tel-input>
                    <small *ngIf="submitted && f.phone.errors" class="text-danger mt-2">** Ce champ est
                        obligatoire</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Adresse</span>
                    </div>
                    <input type="text" class="form-control" formControlName="adress">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Date de naissance</span>
                    </div>
                    <div class="input-group px-0">
                        <input class="form-control" formControlName="birthday" placeholder="JJ/MM/AAAA" name="dn"
                            ngbDatepicker #dn="ngbDatepicker" [minDate]="{year: 1900, month:1, day: 1}">
                        <button class="btn btn-outline-secondary calendar" (click)="dn.toggle()" type="button"></button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Email</span>&nbsp;
                    </div>
                    <input type="text" class="form-control" formControlName="mail">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Mot de passe</span>&nbsp;
                        <span class="text-danger">*</span>
                    </div>
                    <input type="text" class="form-control" formControlName="password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <small *ngIf="submitted && f.password.errors" class="text-danger mt-2">** Ce champ est
                        obligatoire</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <span class="font-size-18">Confirmer le mot de passe</span>&nbsp;
                        <span class="text-danger">*</span>
                    </div>
                    <input type="text" class="form-control" formControlName="confirmedPassword">
                    <small *ngIf="submitted && f.password.value !== f.confirmedPassword.value"
                        class="text-danger mt-2">Les 2
                        mots de passe doivent être identiques.</small>
                </div>
            </div>
        </div>
    </form>

    <div class="row mt-3">
        <div class="col-12">
            <p class="mb-0">Les champs suivis d'une étoile <span class="text-danger">*</span> sont obligatoires.</p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <h3 class="mb-0" style="font-family: Poppins;">Souhaitez vous disposer d’une carte santé ?</h3>
        </div>
    </div>

    <div class="row mx-0 mt-3">
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="d-flex mr-5">
                    <input class="mr-2" type="radio" value="Y" id="requestedCardY"
                        style="accent-color: #D00000; cursor: pointer; width: 1.5rem;" [(ngModel)]="requestedCard"
                        (ngModelChange)="onCheckReq()" />
                    <label class="mb-0" for="requestedCardY">OUI</label>
                </div>
                <div class="d-flex">
                    <input class="mr-2" type="radio" value="N" id="requestedCardN"
                        style="accent-color: #D00000; cursor: pointer; width: 1.5rem;" [(ngModel)]="requestedCard"
                        (ngModelChange)="onCheckReq()" />
                    <label class="mb-0" for="requestedCardN">NON</label>
                </div>
            </div>
        </div>
        <div class="col-12">
            <small *ngIf="submitted && (!feminin && !masculin)" class="text-danger text-right mt-2">** Vous devez
                choisir
                une
                option</small>
        </div>
    </div>

    <hr class="w-100 bg-danger my-4">

    <div class="row">
        <div class="col-md-3">
            <button (click)="submit(content)" class="w-100 btn tw-bg-[#d00000] btn-rounded py-2">
                <h6 class="mb-0 text-white">Créer le compte</h6>
            </button>
        </div>
    </div>
</div>

<!-- Modals -->
<!-- LOGIN/SIGNUP -->
<ng-template #content let-modal>
    <div *ngIf="login">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Connexion</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ngb-alert class="py-2 px-3" [dismissible]="false" type="success">
                La création de votre Dossier Patient a été faite avec succès. Veuillez vous connecter pour y accéder.
            </ngb-alert>
            <!-- Form -->
            <form class="form-horizontal w-100" [formGroup]="loginForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="form-group">
                    <ngx-intl-tel-input [cssClass]="'border tw-border-[#ced4da] py-3 w-100 rounded'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true" [maxLength]="15" [separateDialCode]="separateDialCode" name="phone2"
                        formControlName="phone" [searchCountryPlaceholder]="'Recherchez un pays'">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && log.phone.errors" class="invalid-feedback">
                        <div *ngIf="log.phone.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                        <div *ngIf="log.phone.errors.login">{{'SHARED.FIELD_REQUIRED' | translate}}</div>
                    </div>
                </div>

                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon tw-text-[#D00000]" style="color:#d00000;"></i>
                    <label for="password">{{'HEADER.LOGIN.PASS' | translate}}</label>
                    <input type="password" formControlName="password" class="form-control" id="password"
                        [ngClass]="{ 'is-invalid': submitted && log.password.errors }" placeholder="Mot de passe" />
                    <div *ngIf="submitted && log.password.errors" class="invalid-feedback">
                        <div *ngIf="log.password.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                    </div>
                </div>

                <div class="mt-4 text-center">
                    <button class="btn w-md waves-effect waves-light tw-bg-[#D00000] text-white" type="button"
                        (click)="onSubmit()">{{'HEADER.LOGIN.TO_LOGIN' | translate}}</button>
                </div>

                <div class="mt-4 text-center">
                    <a (click)="switchToReset()" class="text-muted tw-cursor-pointer"><i class="mdi mdi-lock mr-1"></i>
                        {{'HEADER.LOGIN.FORGOT' | translate}}</a>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="reset">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Réinitialisation mot de
                passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="resetForm">
            <p class="m-0">Renseignez votre numéro de téléphone,</p>
            <p class="m-0">vous recevrez le nouveau mot de passe par SMS.</p>
            <input class="form-control mt-2" formControlName="tel" type="tel" placeholder="Entrez votre numéro">
        </div>
        <div class="modal-footer">
            <button (click)="cancelReset()" type="button" class="btn btn-outline-dark mr-2">Annuler</button>
            <button (click)="resetPwd()" type="button" class="btn text-white"
                style="background-color:#d00000;">Confirmer</button>
        </div>
    </div>

    <div *ngIf="verif">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Vérification compte</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous allez recevoir un code par SMS.</p>
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez le code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="cancelVerif()" type="button" class="btn btn-outline-dark mr-2">Retour</button>
            <button type="button" class="btn text-white" (click)="verify()"
                style="background-color:#d00000;">Vérifier</button>
        </div>
    </div>

    <div *ngIf="firstConnect">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Nouveau mot de passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous devez modifier votre mot de passe.</p>
            <form class="form-horizontal" [formGroup]="pwdForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <!-- Ancien -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="old">Ancien mot de passe</label>
                            <input formControlName="oldPwd" type="password" class="form-control" id="old"
                                [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
                            <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                                    l'ancien mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Nouveau -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="new">Nouveau mot de passe</label>
                            <input formControlName="newPwd" type="password" class="form-control" id="new"
                                [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
                            <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                                    nouveau mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Confirmation -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                                passe</label>
                            <input formControlName="confirmNewPwd" type="password" class="form-control"
                                id="confirmNewPwd" [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
                            <div *ngIf="submitted && p.confirmNewPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                                    mot de passe.
                                </div>
                                <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                                    La confirmation est incorrecte.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="changePassword()" class="btn text-white col-lg-6"
                style="background-color: #d00000;">Enregistrer les
                changements</button>
        </div>
    </div>
</ng-template>
<!-- Verification -->
<ng-template #verification let-modal2>
    <div class="modal-header">
        <h4 class="modal-title">Code de vérification</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous allez recevoir un code par SMS.</p>
        <div class="p-2 mt-2">
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-link float-right text-danger">Renvoyer le code</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="verify()">Vérifier</button>
    </div>
</ng-template>