import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { User } from "../models/auth.models";
import { headers } from "src/app/headerUtils";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class UserProfileService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`/api/login`);
  }

  getPatientByUsername(username, token?) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    headers = headers.append("Authorization", `Bearer ${token}`);

    return this.http.get<any>(
      `${environment.base}api/rest/patientByUsername/${username}`,
      { headers }
    );
  }

  getPassportUserRelatives(patientExternalId) {
    return this.http.get<any>(
      `${environment.base}api/rest/v1/patients/manage-accounts/${patientExternalId}`,
      headers.passport()
    );
  }

  register(user) {
    return this.http.post(`${environment.base}v1/registration`, user);
  }

  resendCode(data) {
    return this.http.post(
      `${environment.base}v3/request`,
      data,
      headers.passport()
    );
  }

  updatePatientAccount(patient) {
    return this.http.post<any>(
      `${environment.base}api/rest/modifyPatientAccount`,
      patient,
      headers.passport()
    );
  }

  updateUsername(identifiant) {
    return this.http.post<any>(
      `${environment.base}api/rest/v1/patients/mobile-modification`,
      identifiant,
      headers.passport()
    );
  }

  changePassword(body) {
    return this.http.post<any>(
      `${environment.base}api/rest/v3/changePatientPassword/`,
      body,
      headers.passport()
    );
  }

  verifyUser(body) {
    return this.http.post<any>(
      `${environment.base}v1/codes-validation/request`,
      body,
      headers.passport()
    );
  }

  activateAccount(body) {
    return this.http.put<any>(
      `${environment.base}v1/patients/activation-by-password`,
      body,
      headers.passport()
    );
  }

  getUserLogs() {
    return this.http.get<any>(
      `${environment.base}api/rest/v1/activities`,
      headers.passport()
    );
  }

  upgrade(body) {
    return this.http.post<any>(
      `${environment.base}api/rest/v1/patients/managed-to-main`,
      body,
      headers.passport()
    );
  }
}
