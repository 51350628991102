import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { AuthService } from "../../../core/services/authentification.service";
import { LanguageService } from "../../../core/services/language.service";
import { HelpersService } from "src/app/core/services/helpers/helpers.service";

@Component({
  selector: "app-passeporthorizontaltopbar",
  templateUrl: "./passeporthorizontaltopbar.component.html",
  styleUrls: ["./passeporthorizontaltopbar.component.scss"],
})
export class PasseportHorizontaltopbarComponent implements OnInit {
  configData: any;

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  patientExternalId: string;
  firstName: string;
  lastName: string;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authFackservice: AuthService,
    public languageService: LanguageService,
    public cookiesService: CookieService
  ) {}

  ngOnInit(): void {
    this.patientExternalId = sessionStorage.getItem("patientExternalId");
    this.firstName = sessionStorage.getItem("firstName");
    this.lastName = sessionStorage.getItem("lastName");

    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("show");
  }

  goToPublic() {
    this.router.navigate(["/"]);
  }

  /**
   * Logout the user
   */
  logout() {
    this.authFackservice.logout();
  }
}
