<app-pagetitle title="Docteurs" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<section id="search" class="container-fluid tw-sticky tw-top-0 tw-px-6 sm:tw-hidden">
    <!-- Search field -->
    <div class="tw-flex tw-justify-center tw-items-center">
        <div class="tw-flex tw-justify-center tw-items-center tw-bg-white tw-w-11/12 tw-h-11 tw-rounded-l-lg">Input
            fields</div>
        <div
            class="tw-flex tw-items-center tw-border-0 tw-bg-white tw-w-1/12 tw-h-11 tw-rounded-r-lg">
            <i class="ri-search-line tw-w-full tw-text-center tw-text-slate-400 tw-text-xl"></i>
        </div>
    </div>
</section>

<section id="results" class="tw-block tw-py-6 sm:tw-hidden">
    <div class="container-fluid tw-px-6">

        <!-- DOCTORS W/ SLOTS top padding of 3 -->
        <div *ngIf="doctors?.data.length != 0">

            <div *ngFor="let data of doctors?.data"
                class="tw-border-2 tw-border-indigo-600 tw-w-full tw-px-3 tw-py-3 tw-mb-6 tw-bg-white tw-flex tw-content-start tw-items-start"
                style="border-radius: 8px;">
                <!-- Personal infos -->
                <div class="tw-w-6/12 tw-flex tw-content-between tw-items-center"
                    style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;">
                    <div class="tw-flex tw-content-start tw-items-start">
                        <div *ngIf="data?.photos.length == 0"
                            class="tw-rounded-full tw-cursor-pointer tw-w-12 tw-h-12 tw-mr-4">
                            <img class="tw-rounded-full tw-w-12 tw-h-12" src="assets/images/users/henri.png"
                                alt="avatar">
                        </div>

                        <div class="tw-flex tw-flex-col tw-content-start tw-items-start">
                            <p class="tw-text-slate-800 tw-text-md tw-m-0">
                                {{ data?.firstname }} {{ data?.lastname }}
                            </p>
                            <span *ngFor="let spec of data?.doctorspecializations"
                                class="tw-mr-2 tw-text-xs tw-text-blue-600">
                                {{ spec?.name }}
                            </span>
                            <p class="tw-m-0 tw-text-slate-500 tw-text-xs tw-mt-2">{{
                                data?.coordonate?.globaladressfield }}</p>
                            <p class="tw-m-0"><span
                                    class="tw-mt-1 tw-flex tw-content-start tw-items-center tw-text-xs"><i
                                        class="ri-navigation-fill tw-text-xs tw-mr-1 tw-text-green-300"></i>
                                    {{ data?.distance | number: '1.2-2' }} km</span></p>
                            <div class="tw-flex tw-content-start tw-items-center tw-mt-3">
                                <a href="javascript:void(0)"
                                    class="tw-bg-blue-900 tw-rounded-full tw-py-1 tw-px-3 tw-text-white tw-text-xs hover:tw-bg-blue-800">Voir
                                    les détails</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Slots -->
                <!-- <div class="tw-w-5/12 tw-flex tw-w-full tw-content-center tw-items-start sm:tw-w-7/12">
                    <div class="tw-w-1/12">
                        <button *ngIf="!data?.isNavLeftPossible" disabled
                            class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"><i
                                class="ri-arrow-left-s-line tw-text-3xl tw-text-slate-300"></i></button>
                        <button *ngIf="data?.isNavLeftPossible" class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"><i
                                class="ri-arrow-left-s-line tw-text-3xl"></i></button>
                    </div>
                    <div class="tw-w-10/12 tw-w-full tw-flex tw-content-center tw-items-start">
                        <div *ngFor="let availability of data?.availabilities"
                            class="tw-w-1/5 tw-flex tw-flex-col tw-content-center tw-items-center">
                            <p class="tw-text-xs tw-m-0 tw-text-center" style="font-weight: 700;">{{
                                availability.day }}</p>
                            <p class="text-slate-800 tw-text-xs tw-m-0 tw-text-center">{{ availability.date }}
                                {{
                                availability.month }}</p>
                            <div class="tw-mt-4 tw-flex tw-flex-col tw-content-center tw-items-center">
                                <ng-container *ngFor="let slot of data?.rowNumber; let i = index">
                                    <div *ngIf="availability?.slots[i]"
                                        class="tw-bg-blue-100 tw-px-3 tw-py-3 tw-text-xs tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center tw-cursor-pointer hover:tw-bg-yellow-300">
                                        {{ availability?.slots[i]?.starttime }}
                                    </div>
                                    <div *ngIf="!availability?.slots[i]"
                                        class="tw-bg-white tw-px-3 tw-py-3 tw-text-4xl tw-rounded-full tw-h-3 tw-mb-3 tw-flex tw-content-center tw-items-center">
                                        -</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="tw-w-1/12 tw-text-right">
                        <button class="tw-border-0 tw-m-0 tw-p-0 tw-bg-white"><i
                                class="ri-arrow-right-s-line tw-text-3xl"></i></button>
                    </div>
                    <div *ngIf="data?.isExpendable" class="tw-flex tw-content-center tw-items-center">
                        <button *ngIf="!data?.isCollapsed"
                            class="tw-m-0 tw-border-0 tw-rounded-full tw-bg-slate-300 tw-py-2 tw-px-3"><span
                                class="tw-text-xl">Voir
                                plus d'horaires</span></button>
                    </div>
                </div> -->
                <!-- 4 [MAP] -->
                <div class="tw-w-6/12 tw-flex tw-content-center tw-items-center tw-bg-slate-100 sm:hidden"
                    style="height: 200px; border-radius: 8px; overflow: hidden;">
                    <!-- <agm-map [latitude]="data?.coordonate?.latitude" [longitude]="data?.coordonate?.longitude"
                        [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
                        style="width: 100%; height: 100%; border-radius: 8px; overflow: hidden;">

                        <agm-marker *ngFor="let structure of data?.structures; let i = index"
                            [latitude]="structure?.coordonate?.latitude"
                            [longitude]="structure?.coordonate?.longitude">

                            <agm-info-window>
                                <span class="text-xl font-bold">{{ structure?.name }}</span>
                            </agm-info-window>
                        </agm-marker>

                    </agm-map> -->
                </div>
            </div>

        </div>

        <!-- Pagination -->
        <div class="tw-w-full tw-flex tw-content-between tw-items-center tw-my-4">
            <button *ngIf="doctors?.prev_page_url"
                class="tw-m-0 tw-border-0 tw-bg-white tw-py-3 tw-px-5 tw-rounded-lg tw-shadow-1"><span
                    class="font-bold text-2xl">Précédent</span></button>
            <button *ngIf="!doctors?.prev_page_url"
                class="tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-5 tw-rounded-lg tw-shadow-1" disabled><span
                    class="font-bold text-2xl">Précédent</span></button>

            <button *ngIf="doctors?.next_page_url"
                class="tw-text-right tw-m-0 tw-border-0 tw-bg-white tw-py-3 tw-px-5 tw-rounded-lg tw-shadow-1"><span
                    class="font-bold text-2xl">Suivant</span></button>
            <button *ngIf="!doctors?.next_page_url"
                class="tw-text-right tw-m-0 tw-border-0 tw-bg-slate-100 tw-py-3 tw-px-5 tw-rounded-lg tw-shadow-1"
                disabled><span class="font-bold text-2xl">Suivant</span></button>
        </div>

    </div>
</section>