<div class="bg-white tw-h-[80px] d-flex align-items-center">
    <div class="container-fluid tw-relative d-flex align-items-center justify-content-between">

        <div role="button" (click)="goToPublic()"
            class="bg-white d-flex align-items-center justify-content-center tw-h-36 tw-w-28 tw-absolute tw-z-[1]"
            style="top: -4px;">
            <img src="assets/images/logo.svg" alt="" height="100">
        </div>

        <div *ngIf="private()" class="d-flex align-items-center justify-content-start tw-ml-[9rem]">
            <a [ngClass]="{ 'border-bottom tw-border-[#D00000] tw-border-b-2': url === '/passeport' }"
                class="mx-3 py-1 text-dark tw-hover:tw-text-green-300" href="javascript: void(0);"
                routerLink="/passeport" id="topnav-components" role="button">
                <h6 class="mb-0 font-size-13">Accueil</h6>
            </a>
        </div>
        <div *ngIf="!private()" class="d-flex align-items-center justify-content-center mx-auto">
            <a [ngClass]="{ 'border-bottom tw-border-[#D00000] tw-border-b-2': url === '/' }"
                class="mx-3 py-1 text-dark tw-hover:tw-text-green-300" href="javascript: void(0);" routerLink="/"
                id="topnav-components" role="button">
                <h6 class="mb-0 font-size-13">Accueil</h6>
            </a>

            <a [ngClass]="{ 'border-bottom tw-border-[#D00000] tw-border-b-2': url === '/professionnels' }"
                class="mx-3 py-1 text-dark" routerLink="/professionnels" href="javascript: void(0);"
                id="topnav-components" role="button">
                <h6 class="mb-0 font-size-13">Médecins</h6>
            </a>
            <a [ngClass]="{ 'border-bottom tw-border-[#D00000] tw-border-b-2': url === '/structures' }"
                class="mx-3 py-1 text-dark" routerLink="/structures" href="javascript: void(0);" id="topnav-components"
                role="button">
                <h6 class="mb-0 font-size-13">Structures de santé</h6>
            </a>
            <a [ngClass]="{ 'border-bottom tw-border-[#D00000] tw-border-b-2': url === '/pharmacies' }"
                class="mx-3 py-1 text-dark" routerLink="/pharmacies" href="javascript: void(0);" id="topnav-components"
                role="button">
                <h6 class="mb-0 font-size-13">Pharmacies</h6>
            </a>
        </div>

        <div class="d-flex align-items-center">
            <a class="d-flex align-items-center mr-3" href="javascript: void(0);" routerLink="#" id="topnav-components"
                role="button">
                <h6 class="tw-text-[#D00000] mb-0 font-size-13 mr-2">Besoin d'Aide</h6>
                <img src="assets/icons/help.svg" width="16">
            </a>

            <button *ngIf="private()" class="btn tw-bg-[#D00000] tw-text-white hover:tw-bg-[#c70202]"
                (click)="goToPublic()"><span class="tw-font-bold">{{'HEADER.GOTOPUBLIC' | translate}}</span></button>

            <div *ngIf="patientExternalId" class="dropdown d-inline-block user-dropdown" ngbDropdown>
                <button type="button" ngbDropdownToggle class="btn header-item waves-effect"
                    id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img *ngIf="!imageAvailable" class="rounded-circle header-profile-user"
                        src="assets/images/users/placeholder.png" alt="Header Avatar">
                    <h6 class="d-none d-xl-inline-block tw-text-black mb-0 ml-1">{{ firstName }} {{ lastName }}</h6>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block tw-text-[#D00000]"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <a *ngIf="url !== '/passeport'" class="dropdown-item" href="javascript: void(0);"
                        routerLink="/passeport">
                        Mon Dossier Patient</a>
                    <a class="dropdown-item" routerLink="/profil" href="javascript: void(0);">
                        {{'HEADER.LOGIN.MY_PROFILE' | translate}}</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
                            class="ri-shut-down-line align-bottom tw-text-[#D00000] mr-1"></i>
                        {{'HEADER.LOGIN.LOGOUT' | translate}}</a>
                </div>
            </div>

            <button *ngIf="!patientExternalId" (click)="goToPasseport(content)" class="btn text-white px-4"
                style="background-color: #2b3c8b;">Se
                connecter</button>
        </div>

    </div>
</div>

<ng-template #content let-modal>
    <div *ngIf="login">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">{{'HEADER.LOGIN.LOGIN' |
                translate}}</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Form -->
            <form class="form-horizontal w-100" [formGroup]="loginForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="form-group">
                    <ngx-intl-tel-input [cssClass]="'border tw-border-[#ced4da] py-3 w-100 rounded'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true" [maxLength]="15" [separateDialCode]="separateDialCode" name="phone"
                        formControlName="phone" [searchCountryPlaceholder]="'Recherchez un pays'">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && log.phone.errors" class="invalid-feedback">
                        <div *ngIf="log.phone.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                        <div *ngIf="log.phone.errors.login">{{'SHARED.FIELD_REQUIRED' | translate}}</div>
                    </div>
                </div>

                <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon tw-text-[#D00000]" style="color:#d00000;"></i>
                    <label for="password">{{'HEADER.LOGIN.PASS' | translate}}</label>
                    <input type="password" formControlName="password" class="form-control" id="password"
                        [ngClass]="{ 'is-invalid': submitted && log.password.errors }" placeholder="Mot de passe" />
                    <div *ngIf="submitted && log.password.errors" class="invalid-feedback">
                        <div *ngIf="log.password.errors.required">{{'SHARED.ENTER_FIELD' | translate}}</div>
                    </div>
                </div>

                <div class="mt-4 text-center">
                    <button class="btn w-md waves-effect waves-light tw-bg-[#D00000] text-white" type="button"
                        (click)="onSubmit()">{{'HEADER.LOGIN.TO_LOGIN' | translate}}</button>
                </div>

                <div class="mt-4 text-center">
                    <a (click)="switchToReset()" class="text-muted tw-cursor-pointer"><i class="mdi mdi-lock mr-1"></i>
                        {{'HEADER.LOGIN.FORGOT' | translate}}</a>
                </div>
            </form>

            <!-- <div class="mt-5 text-center">
          <p>Vous n'avez pas de compte ? <a (click)="login=!login" class="font-weight-medium tw-cursor-pointer"
              style="color:#d00000;">
              INSCRIVEZ-VOUS ICI </a> </p>
        </div> -->
        </div>
    </div>

    <div *ngIf="signup">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Inscription</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="p-2 mt-2">
                <form class="form-horizontal" [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">
                    <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Inscription réussie.
                    </ngb-alert>
                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="prenom"><span class="mr-2 text-danger">*</span> Prénom</label>
                        <input type="text" formControlName="prenom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.prenom.errors }" id="prenom" placeholder="">
                        <div *ngIf="submitted && sig.prenom.errors" class="invalid-feedback">
                            <div *ngIf="sig.prenom.errors.required">Vous devez entrer un prénom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-user-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="nom"> <span class="mr-2 text-danger">*</span>Nom</label>
                        <input type="text" formControlName="nom" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.nom.errors }" id="nom" placeholder="">
                        <div *ngIf="submitted && sig.nom.errors" class="invalid-feedback">
                            <div *ngIf="sig.nom.errors.required">Vous devez entrer un nom.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-mail-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="email"> <span class="mr-2 text-danger">*</span>Email</label>
                        <input type="email" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.email.errors }" id="email" placeholder="">
                        <div *ngIf="submitted && sig.email.errors" class="invalid-feedback">
                            <div *ngIf="sig.email.errors.required">Vous devez entrer un email.</div>
                            <div *ngIf="sig.email.errors.email">L'addresse email doit être valide.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-phone-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="tel"> <span class="mr-2 text-danger">*</span>Téléphone</label>
                        <input type="text" formControlName="tel" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.tel.errors }" id="tel" placeholder="">
                        <div *ngIf="submitted && sig.tel.errors" class="invalid-feedback">
                            <div *ngIf="sig.tel.errors.required">Vous devez entrer un numero de téléphone.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-home-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="addresse"> <span class="mr-2 text-danger">*</span>Addresse</label>
                        <input type="text" formControlName="addresse" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.addresse.errors }" id="addresse" placeholder="">
                        <div *ngIf="submitted && sig.addresse.errors" class="invalid-feedback">
                            <div *ngIf="sig.addresse.errors.required">Vous devez entrer une adresse.</div>
                        </div>
                    </div>

                    <div class="form-group auth-form-group-custom mb-3">
                        <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                        <label for="password"> <span class="mr-2 text-danger">*</span>Mot de passe</label>
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sig.password.errors }" id="password" placeholder="">
                        <div *ngIf="submitted && sig.password.errors" class="invalid-feedback">
                            <div *ngIf="sig.password.errors.required">Vous devez entrer un mot de passe.</div>
                        </div>
                    </div>

                    <p class="tw-m-0"> <span class="mr-2 text-danger">*</span>Sexe</p>
                    <div class="tw-flex tw-mt-2">
                        <div class="form-group custom-control custom-radio tw-mr-3">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="m" value="M">
                            <label class="custom-control-label" for="m">Masculin</label>
                        </div>
                        <div class="form-group custom-control custom-radio">
                            <input type="radio" formControlName="sexe" class="custom-control-input" id="f" value="F">
                            <label class="custom-control-label" for="f">Féminin</label>
                        </div>
                    </div>


                    <div class="text-center">
                        <button class="btn w-md waves-effect waves-light text-white" type="submit"
                            style="background-color:#d00000;">S'inscrire</button>
                    </div>

                    <div class="mt-4 text-center">
                        <p class="mb-0">En vous inscrivant, vous acceptez les <a target="_blank" routerLink="/cgu"
                                style="color:#d00000;">Conditions
                                d'Utilisation</a></p>
                    </div>
                </form>
            </div>

            <div class="mt-5 text-center">
                <p>Vous avez déjà un compte ? <a (click)="signup=!signup" class="font-weight-medium tw-cursor-pointer"
                        style="color:#d00000;">
                        CONNECTEZ-VOUS ICI</a> </p>
            </div>
        </div>
    </div>

    <div *ngIf="reset">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">
                {{'HEADER.LOGIN.RESET_PASS'
                | translate}}</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="resetForm">
            <p class="m-0">{{'HEADER.LOGIN.TYPE_PHONE' | translate}}</p>
            <p class="m-0">{{'HEADER.LOGIN.TYPE_PHONE2' | translate}}</p>
            <!-- <div class="tw-flex">
              <div class="form-group custom-control custom-radio tw-mr-3">
                <input type="radio" formControlName="reset" class="custom-control-input" id="sms" value="SMS">
                <label class="custom-control-label" for="sms">par SMS</label>
              </div>
              <div class="form-group custom-control custom-radio">
                <input type="radio" formControlName="reset" class="custom-control-input" id="email" value="email">
                <label class="custom-control-label" for="email">par Email</label>
              </div>
            </div> -->
            <input class="form-control mt-2" formControlName="tel" type="tel"
                placeholder="{{'HEADER.LOGIN.ENTER_PHONE' | translate}}">
            <!-- <input *ngIf="resetForm.value.reset === 'email'" class="form-control mt-2" formControlName="email"
              type="email" placeholder="Entrez votre email"> -->
        </div>
        <div class="modal-footer">
            <button (click)="cancelReset()" type="button" class="btn btn-outline-dark mr-2">{{'SHARED.CANCEL' |
                translate}}</button>
            <button (click)="resetPwd()" type="button" class="btn text-white"
                style="background-color:#d00000;">{{'SHARED.CONFIRM' | translate}}</button>
        </div>
    </div>

    <div *ngIf="verif">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Vérification compte</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous allez recevoir un code par SMS.</p>
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-lock-2-line auti-custom-input-icon" style="color:#d00000;"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez le code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-12">
                    <button (click)="resendCode()" class="btn btn-link float-right text-danger">Renvoyer le
                        code</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="cancelVerif()" type="button" class="btn btn-outline-dark mr-2">Retour</button>
            <button type="button" class="btn text-white" (click)="verify()"
                style="background-color:#d00000;">Vérifier</button>
        </div>
    </div>

    <div *ngIf="firstConnect">
        <div class="modal-header">
            <p class="modal-title tw-font-bold tw-text-md tw-uppercase" id="modal-basic-title">Nouveau mot de passe</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Vous devez modifier votre mot de passe.</p>
            <form class="form-horizontal" [formGroup]="pwdForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <!-- Ancien -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="old">Ancien mot de passe</label>
                            <input formControlName="oldPwd" type="password" class="form-control" id="old"
                                [ngClass]="{ 'is-invalid': submitted && p.oldPwd.errors }">
                            <div *ngIf="submitted && p.oldPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.oldPwd.errors.required">Vous devez entrer
                                    l'ancien mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Nouveau -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="new">Nouveau mot de passe</label>
                            <input formControlName="newPwd" type="password" class="form-control" id="new"
                                [ngClass]="{ 'is-invalid': submitted && p.newPwd.errors }">
                            <div *ngIf="submitted && p.newPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.newPwd.errors.required">Vous devez entrer un
                                    nouveau mot de passe.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Confirmation -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="m-0" for="confirmation">Confirmer le nouveau mot de
                                passe</label>
                            <input formControlName="confirmNewPwd" type="password" class="form-control"
                                id="confirmNewPwd" [ngClass]="{ 'is-invalid': submitted && p.confirmNewPwd.errors }">
                            <div *ngIf="submitted && p.confirmNewPwd.errors" class="invalid-feedback">
                                <div *ngIf="p.confirmNewPwd.errors.required">Confirmez le
                                    mot de passe.
                                </div>
                                <div *ngIf="pwdForm.value.newPwd !== pwdForm.value.confirmNewPwd">
                                    La confirmation est incorrecte.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="changePassword()" class="btn text-white col-lg-6"
                style="background-color: #2b3c8b;">Enregistrer
                les
                changements</button>
        </div>
    </div>
</ng-template>

<!-- Verification -->
<ng-template #verification let-modal2>
    <div class="modal-header">
        <h4 class="modal-title">Code de vérification</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous allez recevoir un code par SMS.</p>
        <div class="p-2 mt-2">
            <form class="form-horizontal" [formGroup]="activationForm">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="code">Code</label>
                    <input formControlName="code" class="form-control" id="code" placeholder="Entrez code"
                        [ngClass]="{ 'is-invalid': submitted && v.code.errors }">
                    <div *ngIf="submitted && v.code.errors" class="invalid-feedback">
                        <div *ngIf="v.code.errors.required">Vous devez entrer le code</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="verify()">Vérifier</button>
    </div>
</ng-template>