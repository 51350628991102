import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    icon: "ri-dashboard-line",
    badge: {
      variant: "success",
      text: "MENUITEMS.DASHBOARDS.BADGE",
    },
    link: "/",
  },
  {
    id: 16,
    isLayout: true,
  },
  {
    id: 32,
    label: "MENUITEMS.MODULES.TEXT",
    isTitle: true,
  },
  {
    id: 5,
    label: "MENUITEMS.ECOMMERCE.TEXT",
    icon: "ri-store-2-line",
    subItems: [
      {
        id: 6,
        label: "MENUITEMS.ECOMMERCE.LIST.PRODUCTS",
        link: "/ecommerce/products",
        parentId: 5,
      },
    ],
  },
];
